<mat-select [formControl]="selectControl" [multiple]="multiple" [placeholder]="placeholderSelect" (openedChange)="onOpenChange($event)" (selectionChange)="updateTriggerValues()" [ngClass]="{'inline': inline}">
  <mat-select-trigger class="select-trigger">
    <span *ngFor="let val of triggerValues" class="trigger-value">
      <img aria-hidden class="option-icon" [src]="val.iconSource" type="image/svg+xml"/>
      {{val.text}}
    </span>
  </mat-select-trigger>

  <div class="effectory-select-search" *ngIf="searchable">
    <input type="search" class="effectory-select-search-input" [placeholder]="placeholderSearch" [(ngModel)]="searchText" (search)="updateFilteredOptions()" (keyup)="updateFilteredOptions()" (keydown)="stopPropagation($event)" />
  </div>
  <div class="option-container">
    <mat-optgroup *ngFor="let group of optionGroups" [ngStyle]="(group.hidden === true || group.options.length <= 0) && {display: 'none'}" [ngClass]="{'no-label': group.label === undefined}">
      <div class="option-group-label text-small text-w500 text-subdued">{{group.label}}</div>
      <mat-option #invalidOption *ngFor="let option of group.options; trackBy: trackByFn" [value]="option.value" [ngClass]="{'invalid-value': option.invalid === true, 'multiline': option.description !== undefined && option.description !== null, 'with-icon': true}" [disabled]="option.disabled || (option.invalid && !invalidOption.selected)"
        (onSelectionChange)="onSelectionChange($event)">
        <img aria-hidden class="option-icon" [src]="getIconSource(option.value)" type="image/svg+xml"/>
        <span>
          <span class="option-text" [ngClass]="{'text-w500': option.description !== undefined && option.description !== null}">{{option.text}}</span>
          <span class="option-description text-small text-subdued" *ngIf="option.description">{{option.description}}</span>
        </span>
      </mat-option>
    </mat-optgroup>
  </div>
  <div class="loading-wrapper" *ngIf="isLoading">
    <eff-loader class="loader"></eff-loader>
  </div>
</mat-select>
<span *ngIf="selectControl.errors?.invalidValue" class="invalid-value-error" role="alert">{{invalidValueError}}</span>
