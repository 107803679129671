import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MobileSidebarService } from "../mobile-sidebar/mobile-sidebar.service";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent {
  public appRouter: any;

  constructor(public mobileSidebarService: MobileSidebarService, private route: ActivatedRoute) {}

  public get children() {
    return this.route.routeConfig.children;
  };
}
