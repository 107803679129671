<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Inputs</h2>
  </div>
  <div class="card-content-container">
    <h3 class="text-l5">Search</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;input type="search" [(ngModel)]="searchInput" placeholder="Search for something" />
&lt;input type="search" disabled placeholder="Search for something" />
</code>
</pre>
    </details>
    <p>
      <input type="search" [(ngModel)]="searchInput" placeholder="Search for something" /><br><br>
      <input type="search" disabled placeholder="Search for something" />
    </p>
    <br>

    <h3 class="text-l5">SearchBox</h3>
    <p>
      The searchbox component consists of the input box, the current index, the total results and the next, previous and clear search buttons. <br />The search logic must be implemented.<br />
    </p>
    <p>Available properties:</p>
    <ul>
      <li>[placeholder] - string - Receives the placeholder value</li>
      <li>[totalItems] - number - Receives the number of total results</li>
      <li>(searchTerm) - string - Returns the entered search term </li>
      <li>(index) - number - Returns the current index value</li>
    </ul>
    <br>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-search-box
[placeholder]="placeholder"
[totalItems]="searchResultCount"
(searchTerm)="getSearchTerm($event)"
(index)="getIndex($event)"
&lt;/eff-search-box>
</code>
  </pre>
      </details>
      <eff-search-box
      [placeholder]="placeholder"
      [totalItems]="searchResultCount"
      (searchTerm)="getSearchTerm($event)"
      (index)="getIndex($event)"
    ></eff-search-box>
    <br>
    <label>Index value: {{displayIndex}}</label>
    <br>

    <h3 class="text-l5">Text</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;input type="text" placeholder="Please type here" />
&lt;input type="text" disabled placeholder="Text here" />
&lt;input type="text" class="error" placeholder="Something wrong" />
</code>
</pre>
    </details>
    <p>
      <input type="text" placeholder="Please type here" /><br><br>
      <input type="text" disabled placeholder="Text here" /><br><br>
      <input type="text" class="error" placeholder="Something wrong" />
    </p>
    <br>

    <h3 class="text-l5">Number</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;input type="number" placeholder="123456" />
&lt;input type="number" disabled placeholder="123456" />
&lt;input type="number" class="error" placeholder="1a2345" />
</code>
</pre>
    </details>
    <p>
      <input type="number" placeholder="123456" /><br><br>
      <input type="number" disabled placeholder="123456" /><br><br>
      <input type="number" class="error" placeholder="1a2345" />
    </p>
    <br>

    <h3 class="text-l5">Textarea</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;textarea placeholder="Please type here" >&lt;/textarea>
&lt;textarea disabled placeholder="Text here" >&lt;/textarea>
&lt;textarea class="error" placeholder="Something wrong" >&lt;/textarea>
</code>
</pre>
    </details>
    <p>
      <textarea placeholder="Please type here"></textarea><br><br>
      <textarea disabled placeholder="Text here"></textarea><br><br>
      <textarea class="error" placeholder="Something wrong"></textarea>
    </p>
  </div>
</div>
<br><br>
<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Filter chips</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;div class="filter-chip-container"> &lt;-- Add this wrapper -->
  &lt;input type="text" placeholder="Type something" />
  &lt;div class="filter-chip">
    &lt;span>Validation errors&lt;/span>
    &lt;button class="close-button">
      &lt;eff-svg-cross class="icon">&lt;/eff-svg-cross>
    &lt;/button>
  &lt;/div>
&lt;/div>

Additional filter-chip classes include:
 - search | When you use a search input it increases the margin
 - blue   | To make the filter chip blue (default red)
 - orange | To make the filter chip orange (default red)
</code>
</pre>
    </details>
    <div class="filter-chip-container">
      <input type="text" placeholder="Type something" />
      <div class="filter-chip">
        <span>Validation errors</span>
        <button class="close-button">
          <eff-svg-cross class="icon"></eff-svg-cross>
        </button>
      </div>
    </div>
    <br>
    <div class="filter-chip-container">
      <input type="search" [(ngModel)]="searchInput" placeholder="Search" />
      <div class="filter-chip search blue">
        <span>Validation errors</span>
        <button class="close-button">
          <eff-svg-cross class="icon"></eff-svg-cross>
        </button>
      </div>
    </div>
    <br>
    <div class="filter-chip-container">
      <input type="search" [(ngModel)]="searchInput" placeholder="Search" />
      <div class="filter-chip search orange">
        <span>Validation errors</span>
        <button class="close-button">
          <eff-svg-cross class="icon"></eff-svg-cross>
        </button>
      </div>
    </div>

  </div>
</div>
