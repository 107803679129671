import { CoreLibModule } from "@effectory/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LanguageSelectorComponent } from "./language-selector.component";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SvgModule } from "@effectory/branding-angular/shared";
import { TableModule } from "../../table/table.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [
        LanguageSelectorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        SvgModule,
        TableModule,
        MatDialogModule,
        CoreLibModule
    ]
})
export class LanguageSelectorModule { }
