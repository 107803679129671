<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - XS</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
      &lt;button class="action-button primary" (click)="dialogRef.close()">Upload&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openCardDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = "dialog-xs";
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('xs')">Open dialog</button>
  </div>
</div>

<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - S</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
      &lt;button class="action-button primary" (click)="dialogRef.close()">Upload&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openCardDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = "dialog-s";
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('s')">Open dialog</button>
  </div>
</div>

<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - M</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
      &lt;button class="action-button primary" (click)="dialogRef.close()">Upload&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openCardDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = "dialog-m";
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('m')">Open dialog</button>
  </div>
</div>
<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - L</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
      &lt;button class="action-button primary" (click)="dialogRef.close()">Upload&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openCardDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = "dialog-l";
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('l')">Open dialog</button>
  </div>
</div>
<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - With shadow on footer</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container with-shadow">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openDialogWithCenteredActionButtons() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0,
    addShadow: true
  &#125;;
  config.panelClass = ["dialog-m", "center-action-btns"];
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('s', true)">Open dialog</button>
  </div>
</div>
<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - Centered action button</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">
        Example dialog {{data.number}}
      &lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openDialogWithCenteredActionButtons() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = ["dialog-m", "center-action-btns"];
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialogWithCenteredActionButtons()">Open dialog</button>
  </div>
</div>
<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - Fullscreen card</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code ngNonBindable>
&lt;!-- in HTML -->
&lt;ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  &lt;div class="card">
    &lt;div class="card-title-container">
      &lt;h3 class="text-l5 card-title">Example dialog {{data.number}}&lt;/h3>
      &lt;button class="close-button" (click)="dialogRef.close()">
        &lt;eff-svg-cross>&lt;/eff-svg-cross>
      &lt;/button>
    &lt;/div>
    &lt;div class="card-content-container">
      Please enter some text to upload:
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
    &lt;div class="action-container">
      &lt;button class="action-button secondary" (click)="dialogRef.close()">Cancel&lt;/button>
      &lt;button class="action-button primary" (click)="dialogRef.close()">Upload&lt;/button>
    &lt;/div>
  &lt;/div>
&lt;/ng-template>

// In CSS
@import 'sass/card';

// In TypeScript
@ViewChild("cardDialog") public cardDialog: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openCardDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = "dialog-fullscreen-card";
  config.backdropClass = "dialog-backdrop";
  this.matDialog.open(this.cardDialog, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openDialog('fullscreen-card')">Open dialog</button>
  </div>
</div>

<div class="card background-card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Dialog - Fullscreen</h2>
  </div>
  <div class="card-content-container">
    <p>You can combine these styles. You can for example have both a header and a footer, just follow both instructions when making the template.</p>
    <details>
      <summary>Code (with header)</summary>
      <pre>
<code ngNonBindable>
&lt;ng-template #fullscreenDialogWithHeader let-dialogRef="dialogRef" let-data>
  &lt;div class="mat-dialog-header">
    &lt;h3 class="text-l5">Example dialog {{data.number}}&lt;/h3>
  &lt;/div>
  &lt;mat-dialog-content>
    With header, without footer, without progress bar
    &lt;mat-dialog-actions>
      &lt;button class="primary" (click)="dialogRef.close()">yes&lt;/button>
    &lt;/mat-dialog-actions>
  &lt;/mat-dialog-content>
  &lt;button class="close-button" (click)="dialogRef.close()">
    &lt;eff-svg-cross>&lt;/eff-svg-cross>
  &lt;/button>
&lt;/ng-template>

// In TypeScript
@ViewChild("fullscreenDialogWithHeader") public fullscreenDialogWithHeader: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openFullscreenDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = ["dialog-fullscreen", "with-header"]; // If you add a header, make sure to add a &lt;div class="mat-dialog-header"> element in the template.
  this.matDialog.open(this.fullscreenDialogWithHeader, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openFullscreenDialogWithHeader()">Open dialog with header</button><br><br>

    <details>
      <summary>Code (with footer)</summary>
      <pre>
<code ngNonBindable>
&lt;ng-template #fullscreenDialogWithFooter let-dialogRef="dialogRef" let-data>
  &lt;mat-dialog-content>
    &lt;h3 class="text-l2">Example dialog {{data.number}}&lt;/h3>
    Without header, with footer, without progress bar
  &lt;/mat-dialog-content>
  &lt;mat-dialog-actions>
    &lt;button class="primary action-button" (click)="dialogRef.close()">yes&lt;/button>
    &lt;button class="secondary action-button" (click)="dialogRef.close()">no&lt;/button>
  &lt;/mat-dialog-actions>
  &lt;button class="close-button" (click)="dialogRef.close()">
    &lt;eff-svg-cross>&lt;/eff-svg-cross>
  &lt;/button>
&lt;/ng-template>

// In TypeScript
@ViewChild("fullscreenDialogWithFooter") public fullscreenDialogWithFooter: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openFullscreenDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = ["dialog-fullscreen", "with-footer"]; // If you add a footer, make sure to add a &lt;mat-dialog-actions> element in the template.
  this.matDialog.open(this.fullscreenDialogWithFooter, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openFullscreenDialogWithFooter()">Open dialog with footer</button><br><br>

    <details>
      <summary>Code (with progress bar)</summary>
      <pre>
<code ngNonBindable>
&lt;ng-template #fullscreenDialogWithProgressBar let-dialogRef="dialogRef" let-data>
  &lt;div class="mat-dialog-header">
    &lt;h3 class="text-l5">Example dialog {{data.number}}&lt;/h3>
  &lt;/div>
  &lt;mat-progress-bar mode="determinate" value="40">&lt;/mat-progress-bar>
  &lt;mat-dialog-content>
    With header, without footer, with progress bar
    &lt;mat-dialog-actions>
      &lt;button class="primary" (click)="dialogRef.close()">yes&lt;/button>
    &lt;/mat-dialog-actions>
  &lt;/mat-dialog-content>
  &lt;button class="close-button" (click)="dialogRef.close()">
    &lt;eff-svg-cross>&lt;/eff-svg-cross>
  &lt;/button>
&lt;/ng-template>

// In TypeScript
@ViewChild("fullscreenDialogWithProgressBar") public fullscreenDialogWithProgressBar: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openFullscreenDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  // If you add a progress bar, make sure to add a &lt;div class="mat-dialog-header"> element and a &lt;mat-progress-bar> afterwards in the template.
  // This style extends the "with-header" style, so it also adds a header.
  config.panelClass = ["dialog-fullscreen", "with-progress-bar"];
  this.matDialog.open(this.fullscreenDialogWithProgressBar, config);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="openFullscreenDialogWithProgressBar()">Open dialog with progress bar</button>
    <p>You can also add the <code>"small"</code> class to make the dialog 936px wide.</p>
    <details>
      <summary>Code (small)</summary>
      <pre>
        <code ngNonBindable>
&lt;ng-template #fullscreenDialogWithFooter let-dialogRef="dialogRef" let-data>
  &lt;mat-dialog-content>
    &lt;h3 class="text-l2">Example dialog {{data.number}}&lt;/h3>
    Without header, with footer, without progress bar
  &lt;/mat-dialog-content>
  &lt;mat-dialog-actions>
    &lt;button class="primary action-button" (click)="dialogRef.close()">yes&lt;/button>
    &lt;button class="secondary action-button" (click)="dialogRef.close()">no&lt;/button>
  &lt;/mat-dialog-actions>
  &lt;button class="close-button" (click)="dialogRef.close()">
    &lt;eff-svg-cross>&lt;/eff-svg-cross>
  &lt;/button>
&lt;/ng-template>

// In TypeScript
@ViewChild("fullscreenDialogWithFooter") public fullscreenDialogWithFooter: TemplateRef&lt;any>;

constructor(public matDialog: MatDialog) &#123; &#125;

public openFullscreenDialog() &#123;
  const config = new MatDialogConfig();
  config.data = &#123;
    number: 0
  &#125;;
  config.panelClass = ["dialog-fullscreen", "small", "with-footer"];
  this.matDialog.open(this.fullscreenDialogWithFooter, config);
&#125;
        </code>
      </pre>
    </details>
    <button class="primary" (click)="openSmallFullscreenDialog()">Open small dialog</button>
  </div>
</div>

<ng-template #fullscreenDialogWithHeader let-dialogRef="dialogRef" let-data>
  <div class="mat-dialog-header">
    <h3 class="text-l5">Example dialog {{data.number}}</h3>
  </div>
  <mat-dialog-content>
    With header, without footer, without progress bar
    <mat-dialog-actions>
      <button class="primary" (click)="dialogRef.close()">yes</button>
    </mat-dialog-actions>
  </mat-dialog-content>
  <button class="close-button" (click)="dialogRef.close()">
    <eff-svg-cross></eff-svg-cross>
  </button>
</ng-template>

<ng-template #fullscreenDialogWithProgressBar let-dialogRef="dialogRef" let-data>
  <div class="mat-dialog-header">
    <h3 class="text-l5">Example dialog {{data.number}}</h3>
  </div>
  <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
  <mat-dialog-content>
    With header, without footer, with progress bar
    <mat-dialog-actions>
      <button class="primary" (click)="dialogRef.close()">yes</button>
    </mat-dialog-actions>
  </mat-dialog-content>
  <button class="close-button" (click)="dialogRef.close()">
    <eff-svg-cross></eff-svg-cross>
  </button>
</ng-template>

<ng-template #fullscreenDialogWithFooter let-dialogRef="dialogRef" let-data>
  <mat-dialog-content>
    <h3 class="text-l2">Example dialog {{data.number}}</h3>
    Without header, with footer, without progress bar
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="primary action-button" (click)="dialogRef.close()">yes</button>
    <button class="secondary action-button" (click)="dialogRef.close()">no</button>
  </mat-dialog-actions>
  <button class="close-button" (click)="dialogRef.close()">
    <eff-svg-cross></eff-svg-cross>
  </button>
</ng-template>

<ng-template #cardDialog let-dialogRef="dialogRef" let-data>
  <div class="card fullscreen">
    <div class="card-title-container">
      <h3 class="text-l5 card-title">Example dialog {{data.number}}</h3>
      <button class="close-button" (click)="dialogRef.close()">
        <eff-svg-cross></eff-svg-cross>
      </button>
    </div>
    <div class="card-content-container">
      Please enter some text to upload:
      <input type="text" placeholder="Please type here" />
    </div>
    <div class="action-container" [class.with-shadow]="data.addShadow">
      <button *ngIf="!data.hideUploadButton" class="action-button primary" (click)="dialogRef.close()">Upload</button>
      <button class="action-button secondary" (click)="dialogRef.close()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #fullscreenDialog let-dialogRef="dialogRef" let-data>
  <mat-dialog-content>
    <h3 class="text-l2">Example dialog {{data.number}}</h3>
    Are you sure you want to close this?
    <mat-dialog-actions>
      <button class="primary" (click)="dialogRef.close()">yes</button>
    </mat-dialog-actions>
  </mat-dialog-content>
  <button class="close-button" (click)="dialogRef.close()">
    <eff-svg-cross></eff-svg-cross>
  </button>
</ng-template>
