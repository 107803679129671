<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Design preview</h2>
  </div>
  <div class="card-content-container">
    <p>
      The designs used in the questionnaire system can be showcased using this
      generic component. The inputs for logoUrl, backgroundImageUrl and backgroundPatternUrl can be hosted or in memory.
    </p>
    <details>
      <summary>Code with hosted assets</summary>
      <pre>
<code>
  &lt;eff-questionnaire-design-preview
      [logoUrl]="'https://questionnairedesign.blob.core.windows.net/questionnairedesign-images....'"
      [backgroundImageUrl]="'https://questionnairedesign.blob.core.windows.net/questionnairedesign-images....'"
      [backgroundPatternUrl]="'https://questionnairedesign.blob.core.windows.net/questionnairedesign-images....'"
      [primaryColor]="30B3AF"
      [backgroundColor]="30B3AF">
  &lt;/eff-questionnaire-design-preview>
</code>
</pre>
    </details>

    <details>
      <summary>Code with in memory assets</summary>
      <pre>
<code>
  &lt;eff-questionnaire-design-preview
      [logoUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [backgroundImageUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [backgroundPatternUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [primaryColor]="30B3AF"
      [desktopPreviewInColumnMode]="false"
      [backgroundColor]="30B3AF">
  &lt;/eff-questionnaire-design-preview>

  Input for BackgroundPattern can for example also be an in memory svg:
  [backgroundPatternUrl]="`(`data:image/svg+xml;utf8,{{'${'}}xml{{'}'}}`).replace("#", "%23")`"
  [desktopPreviewInColumnMode] - if true it shows the preview in a column mode

</code>
</pre>
    </details>
    <eff-questionnaire-design-preview
      [logoUrl]="logoUrl"
      [backgroundImageUrl]="backgroundUrl"
      [backgroundPatternUrl]="patternUrl"
      [previewDesktopInColumnMode]="false"
      [primaryColor]="primaryColor"
      [backgroundColor]="backgroundColor"
    >
    </eff-questionnaire-design-preview>
    <details>
      <summary>Code with mobile preview - It's not triggered by media queries</summary>
      <pre>
<code>
  &lt;eff-questionnaire-design-preview
      [logoUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [backgroundImageUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [backgroundPatternUrl]="`data:{{'${'}}mimeType{{'}'}};base64,{{'${'}}byteArray{{'}'}}`"
      [primaryColor]="30B3AF"
      [backgroundColor]="30B3AF"
      [mobileVersion]=true
      [showRepositionBtn]=true
      [mobileImageXOffset]="2298"
      [imageHeight]="3543"
      (imageRepositioned)="yourMethod()">
  &lt;/eff-questionnaire-design-preview>

  Reposition button EventEmitter is available

</code>
</pre>
    </details>
    <eff-questionnaire-design-preview
      [logoUrl]="logoUrl"
      [backgroundImageUrl]="backgroundUrl"
      [backgroundPatternUrl]="patternUrl"
      [primaryColor]="primaryColor"
      [backgroundColor]="backgroundColor"
      [mobileVersion]=true
      [showRepositionBtn]=true
      [mobileImageXOffset]="2584"
      [imageHeight]="3543">
    </eff-questionnaire-design-preview>

    <details>
      <summary>Mailing design - Desktop/Mobile</summary>
      <pre>
<code>
  &lt;eff-mailing-design-preview
      [mobileVersion]=false
      [internetSpiegel]="false"
      [showTag]="true"
      [logoUrl]="logoUrl"
      [backgroundImageUrl]="backgroundMailUrl"
      [primaryColor]="primaryColor">
  &lt;/eff-mailing-design-preview>
</code>
      </pre>
    </details>
    <div class="mailing-container">
      <eff-mailing-design-preview
        [mobileVersion]=true
        [internetSpiegel]="false"
        [showTag]="true"
        [logoUrl]="logoUrl"
        [backgroundImageUrl]="backgroundMailUrl"
        [primaryColor]="primaryColor">
      </eff-mailing-design-preview>
    </div>

    <details>
      <summary>Mailing content - Desktop</summary>
      <pre>
<code>
  &lt;eff-mailing-content-preview
      [privacyHeader]="'string | innerHTML'"
      titleText="string | innerHTML"
      subtitle=""
      [showIntroductionContent]="true"
      introductionContent=""
      body1Content=""
      body2Content=""
      buttonText=""
      buttonLink=""
      [showTag]="true"
      [logoUrl]="logoUrl"
      [backgroundImageUrl]="backgroundMailUrl"
      [primaryColor]="primaryColor">
  &lt;/eff-mailing-design-preview>

</code>
</pre>
    </details>
    <div class="mailing-container">
      <eff-mailing-content-preview
        privacyHeader="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id arcu sed tellus porta facilisis sit amet at tortor."
        titleText="Hello Pepper,"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id arcu sed tellus porta facilisis sit amet at tortor."
        body1Content="Vivamus elit magna, elementum maximus sodales eu, pulvinar sit amet quam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
        body2Content="Suspendisse quis metus gravida, ultrices mi non, ultricies odio. Proin eu massa exilius. Primis in faucibus orci luctus et ultrices posuere cubilia curae"
        [showIntroductionContent]=true
        buttonText="Share your thoughts"
        buttonLink="https://login.effectory.com"
        [showTag]="true"
        [logoUrl]="logoUrl"
        [backgroundImageUrl]="backgroundMailUrl"
        footer='<p><strong>Vestibulum ante ipsum primis</strong></p><p><a href="#" rel=\"noopener noreferrer\" target=\"_blank\">Effectory</a> roin bibendum lacus et eros elementum ultricies. Vivamus sem lorem, rutrum id neque dignissim, consequat semper magna. Sed tristique orci eu vestibulum auctor <a href="#" rel=\"noopener noreferrer\" target=\"_blank\">ipsum lobortis</a>.</p><p>(c) Effectory - All rights reserved</p>'
        [primaryColor]="primaryColor">
      </eff-mailing-content-preview>
    </div>
  </div>
</div>
