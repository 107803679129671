import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: "app-dialogs",
  templateUrl: "./dialogs.component.html",
  styleUrls: ["./dialogs.component.scss"]
})
export class DialogsComponent implements OnInit {

  @ViewChild("cardDialog") public cardDialog: TemplateRef<any>;
  @ViewChild("fullscreenDialog") public fullscreenDialog: TemplateRef<any>;
  @ViewChild("fullscreenDialogWithHeader") public fullscreenDialogWithHeader: TemplateRef<any>;
  @ViewChild("fullscreenDialogWithFooter") public fullscreenDialogWithFooter: TemplateRef<any>;
  @ViewChild("fullscreenDialogWithProgressBar") public fullscreenDialogWithProgressBar: TemplateRef<any>;

  constructor(public matDialog: MatDialog) { }

  public ngOnInit() {
  }

  /* eslint-disable id-blacklist */
  public openDialog(size: string, addShadow: boolean = false) {
    const config = new MatDialogConfig();
    config.data = {
      number: 0,
      addShadow
    };
    config.panelClass = "dialog-" + size;
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.cardDialog, config);
  }

  public openDialogWithCenteredActionButtons() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0,
      hideUploadButton: true
    };
    config.panelClass = ["dialog-m", "center-action-btns"];
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.cardDialog, config);
  }

  public openFullscreenDialog() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0
    };
    config.panelClass = "dialog-fullscreen";
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.fullscreenDialog, config);
  }

  public openFullscreenDialogWithHeader() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0
    };
    config.panelClass = ["dialog-fullscreen", "with-header"];
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.fullscreenDialogWithHeader, config);
  }

  public openFullscreenDialogWithProgressBar() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0
    };
    config.panelClass = ["dialog-fullscreen", "with-progress-bar"];
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.fullscreenDialogWithProgressBar, config);
  }

  public openFullscreenDialogWithFooter() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0
    };
    config.panelClass = ["dialog-fullscreen", "with-footer"];
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.fullscreenDialogWithFooter, config);
  }

  public openSmallFullscreenDialog() {
    const config = new MatDialogConfig();
    config.data = {
      number: 0
    };
    config.panelClass = ["dialog-fullscreen", "small", "with-footer"];
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.fullscreenDialogWithFooter, config);
  }
  /* eslint-enable: id-blacklist */
}
