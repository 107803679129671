import { ContentChild, Directive, ElementRef, HostListener, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";

// Source: https://stackoverflow.com/questions/46867548/structural-directives-position-tooltip
@Directive({ selector: "[effTooltipDirective]" })
export class TooltipDirective {

  /**
   * To use this tooltip, add an <ng-template #tooltipTemplate> with the tooltip inside your target element.
   * For example:
   * <ng-template #tooltipTemplate>
   *   <div class="tooltip">
   *     This is a tooltip
   *   </div>
   * </ng-template>
   */
  @ContentChild("tooltipTemplate") public tooltipTemplateRef: TemplateRef<any>;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef) { }

  @HostListener("mouseenter")
  @HostListener("focus")
  public onMouseEnter(): void {
    if (this.viewContainerRef && this.tooltipTemplateRef) {
      const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
      view.rootNodes.forEach((node) =>
        this.renderer.appendChild(this.elementRef.nativeElement, node));
    }
  }

  @HostListener("mouseleave")
  @HostListener("blur")
  public onMouseLeave(): void {
    if (this.viewContainerRef && this.tooltipTemplateRef) {
      this.viewContainerRef.clear();
    }
  }
}
