import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { EffectoryOption, EffectoryOptionGroup } from "./interfaces/effectory-option";

@Component({
  selector: "eff-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectComponent,
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() public inline = false;
  @Input() public multiple = false;
  @Input() public searchable = false;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public disableLocalSearch = false;
  @Input() public searchInDescription = false;
  @Input() public isLoading = false;
  @Input() public previewData = false;

  @Input() public placeholderSelect: string;
  @Input() public placeholderSearch: string;
  @Input() public validValueLabel: string;
  @Input() public invalidValueLabel: string;
  @Input() public invalidValueError: string;
  @Input() public options: EffectoryOption[];

  @Output() public selectionChange = new EventEmitter<MatSelectChange>();
  @Output() public openedChange = new EventEmitter<boolean>();
  @Output() public closedWithChange = new EventEmitter();
  @Output() public searchTermChange = new EventEmitter<string>();

  @ViewChild(MatSelect) public matSelect: MatSelect;

  public selectedValues: string[] | string;

  public optionGroups: EffectoryOptionGroup[];

  public searchText: string;

  public selectControl = new UntypedFormControl({ value: "select", disabled: this.disabled });

  private invalidOptions: string[];
  private filteredValidOptions: EffectoryOption[];
  private filteredInvalidOptions: EffectoryOption[];
  private hiddenOptions: EffectoryOption[];

  private oldValues: string[] | string;

  constructor() { }

  public get triggerValue(): string {
    if (this.selectedValues === null || this.selectedValues === undefined) {
      return undefined;
    }
    if (Array.isArray(this.selectedValues)) {
      return this.options.filter(x => this.selectedValues.includes(x.value)).map(x => x.text).join(", ");
    }
    return this.options.find(x => x.value === this.selectedValues).text;
  }

  // Implement ControlValueAccessor
  public writeValue(values: string[] | string): void {
    this.selectedValues = values;
    this.selectControl.setValue(this.selectedValues);
  }

  // Implement ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Implement ControlValueAccessor
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // Implement ControlValueAccessor
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  // Implement Validator
  public validate(control: AbstractControl): ValidationErrors {
    const invalidValueValidator = this.invalidValueValidatorFactory(this.invalidOptions);
    return invalidValueValidator(control);
  }

  // Implement OnChanges
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.updateFilteredOptions();
      this.updateValidator();
    }
  }

  public updateFilteredOptions(): void {
    const searchString = this.searchText?.trim()?.toLowerCase();
    this.searchTermChange.emit(searchString);
    if (this.disableLocalSearch) {
      return;
    }
    if (!this.searchInDescription) {
      this.filteredValidOptions = this.options?.filter(o => !o.invalid && (!searchString || o.text.toLowerCase().indexOf(searchString) >= 0));
      this.filteredInvalidOptions = this.options?.filter(o => o.invalid && (!searchString || o.text.toLowerCase().indexOf(searchString) >= 0));
      this.hiddenOptions = this.options?.filter(o => o.text.toLowerCase().indexOf(searchString) < 0);
    } else {
      this.filteredValidOptions = this.options?.filter(o => !o.invalid && (!searchString || o.text.toLowerCase().indexOf(searchString) >= 0 || o.description?.toLowerCase().indexOf(searchString) >= 0));
      this.filteredInvalidOptions = this.options?.filter(o => o.invalid && (!searchString || o.text.toLowerCase().indexOf(searchString) >= 0 || o.description?.toLowerCase().indexOf(searchString) >= 0));
      this.hiddenOptions = this.options?.filter(o => o.text.toLowerCase().indexOf(searchString) < 0);
    }

    // Do not instantiate optionGroups every time we update the options
    // This impacts performance when the options are passed to the select component a lot of times by change detection
    if (!this.optionGroups) {
      this.optionGroups = [
        { label: this.invalidValueLabel, options: this.filteredInvalidOptions },
        { label: this.validValueLabel, options: this.filteredValidOptions },
        { options: this.hiddenOptions, hidden: true }
      ];
    } else {
      this.optionGroups[0].options = this.filteredInvalidOptions;
      this.optionGroups[1].options = this.filteredValidOptions;
      this.optionGroups[2].options = this.hiddenOptions;
    }
  }

  public trackByFn(_: number, option: EffectoryOption): string {
    return option.value;
  }

  public stopPropagation(event: KeyboardEvent): void {
    // To prevent Ctrl+A from selecting everything in the dropdown
    event.stopPropagation();
  }

  public open(): void {
    this.matSelect.open();
  }

  public close(): void {
    this.matSelect.close();
  }

  public toggle(): void {
    this.matSelect.toggle();
  }

  public onSelectionChange(event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }

    const value = event.source.value;
    if (this.multiple) {
      // Spread to new array to be able to do simple equality comparison with this.oldValues
      this.selectedValues = this.selectedValues ? [...this.selectedValues] : [];

      const idx = this.selectedValues.indexOf(value);
      if (idx > -1 && !event.source.selected) {
        (this.selectedValues as string[]).splice(idx, 1);
      } else if (event.source.selected) {
        (this.selectedValues as string[]).push(value);
      }
    } else {
      this.selectedValues = value;
    }

    this.onChange(this.selectedValues);

    this.selectionChange.emit(new MatSelectChange(this.matSelect, this.selectedValues));
  }

  public onOpenChange(opened: boolean): void {
    if (opened) {
      this.onOpened();
    } else {
      this.onClosed();
    }

    this.openedChange.emit(opened);
  }

  protected onOpened(): void {
    this.oldValues = this.selectedValues;
  }

  private onClosed(): void {
    if (this.selectedValues !== this.oldValues) {
      if (!Array.isArray(this.selectedValues)) {
        this.options = this.options.filter(o => !o.invalid || this.selectedValues === o.value);
      } else {
        this.options = this.options.filter(o => !o.invalid || this.selectedValues.indexOf(o.value) >= 0);
      }

      this.updateValidator();

      this.closedWithChange.emit();
    }

    this.searchText = "";
    this.updateFilteredOptions();

    this.onTouch();
  }

  private onChange = (_: any) => { };
  private onTouch = () => { };

  private updateValidator(): void {
    this.invalidOptions = this.options?.filter(o => o.invalid)?.map(o => o.value);
    this.selectControl.setValidators([this.required ? Validators.required : () => null, this.invalidValueValidatorFactory(this.invalidOptions)]);
    this.selectControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private invalidValueValidatorFactory(invalidOptions: string[]): ValidatorFn {
    // Invalid options are not available unless they are already selected, then they need to be marked as invalid
    return (control: AbstractControl): ValidationErrors => {
      if (!invalidOptions || !control.value) {
        return null;
      }

      // Multiple is false
      if (!Array.isArray(control.value) && invalidOptions.indexOf(control.value) >= 0) {
        return { invalidValue: control.value.value };
      }

      // Multiple is true
      if (Array.isArray(control.value)) {
        const invalidValues = control.value.filter((v: string) => invalidOptions.indexOf(v) >= 0);

        if (invalidValues.length > 0) {
          return { invalidValue: invalidValues.join(", ") };
        }
      }

      return null;
    };
  }
}
