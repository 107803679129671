import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "eff-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent implements OnInit {
  @Input() public checked: boolean;
  @Input() public disabled: boolean;
  @Input() public intermediate: boolean;
  @Input() public labelText: string;
  @Input() public ariaLabel: string;
  @Output() public checkedChange = new EventEmitter<boolean>();
  public uniqueId = `checkbox_${Math.random().toString(36).substr(2, 9)}`;

  public ngOnInit(): void { }

  public changeState(event: Event): void {
    if (!this.disabled) {
      this.checked = this.intermediate ? false : !this.checked;
      this.checkedChange.emit(this.checked);
    }

    event.preventDefault();
    event.stopPropagation();
  }
}
