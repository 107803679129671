import { Component } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent {
  public checkeds = [false, false];

  public get isAllChecked(): boolean {
    return this.checkeds.every(c => c);
  }

  public get areSomeChecked(): boolean {
    return !this.checkeds.every(c => !c);
  }

  public selectAll(event: boolean): void {
    for (let i = 0; i < this.checkeds.length; i++) {
      this.checkeds[i] = event;
    }
  }
}
