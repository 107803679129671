<div class="card">
  <div class="card-title-container">
    <h3 class="text-l5 card-title">Mat Table</h3>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
    <code ngNonBindable>
// CSS

@import 'sass/tables';

// HTML
&lt;table mat-table [dataSource]="rows" >
  &lt;ng-container matColumnDef="name">
    &lt;th mat-header-cell *matHeaderCellDef>Name&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[0]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="function">
    &lt;th mat-header-cell *matHeaderCellDef>Function&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[1]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="location">
    &lt;th mat-header-cell *matHeaderCellDef>Location&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[2]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="gender">
    &lt;th mat-header-cell *matHeaderCellDef>Gender&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[3]}}&lt;/td>
  &lt;/ng-container>

  &lt;tr mat-header-row *matHeaderRowDef="displayedColumns">&lt;/tr>
  &lt;tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;">&lt;/tr>
&lt;/table>
    </code>
  </pre>
    </details>
    <table mat-table [dataSource]="rows">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{row.properties[0]}}</td>
      </ng-container>

      <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef>Function</th>
        <td mat-cell *matCellDef="let row">{{row.properties[1]}}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{row.properties[2]}}</td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>Gender</th>
        <td mat-cell *matCellDef="let row">{{row.properties[3]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
  </div>
</div>
<br>

<div class="card">
  <div class="card-title-container">
    <h3 class="text-l5 card-title">Mat Table (gray header)</h3>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
    <code ngNonBindable>
// CSS

@import 'sass/tables';

// HTML
&lt;table mat-table [dataSource]="rows" class="gray-header"> &lt;!-- Add this class -->
  &lt;ng-container matColumnDef="name">
    &lt;th mat-header-cell *matHeaderCellDef>Name&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[0]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="function">
    &lt;th mat-header-cell *matHeaderCellDef>Function&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[1]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="location">
    &lt;th mat-header-cell *matHeaderCellDef>Location&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[2]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="gender">
    &lt;th mat-header-cell *matHeaderCellDef>Gender&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[3]}}&lt;/td>
  &lt;/ng-container>

  &lt;tr mat-header-row *matHeaderRowDef="displayedColumns">&lt;/tr>
  &lt;tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;">&lt;/tr>
&lt;/table>
    </code>
  </pre>
    </details>
    <table mat-table [dataSource]="rows" class="gray-header">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{row.properties[0]}}</td>
      </ng-container>

      <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef>Function</th>
        <td mat-cell *matCellDef="let row">{{row.properties[1]}}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{row.properties[2]}}</td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>Gender</th>
        <td mat-cell *matCellDef="let row">{{row.properties[3]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
  </div>
</div>

<br>

<div class="card">
  <div class="card-title-container">
    <h3 class="text-l5 card-title">Mat Table Hover Effect On Rows</h3>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
    <code ngNonBindable>
// CSS

@import 'sass/tables';

// HTML
&lt;table mat-table [dataSource]="rows" class="hover-rows" > &lt;!-- Add this class -->
  &lt;ng-container matColumnDef="name">
    &lt;th mat-header-cell *matHeaderCellDef>Name&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[0]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="function">
    &lt;th mat-header-cell *matHeaderCellDef>Function&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[1]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="location">
    &lt;th mat-header-cell *matHeaderCellDef>Location&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[2]}}&lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="gender">
    &lt;th mat-header-cell *matHeaderCellDef>Gender&lt;/th>
    &lt;td mat-cell *matCellDef="let row">{{row.properties[3]}}&lt;/td>
  &lt;/ng-container>

  &lt;tr mat-header-row *matHeaderRowDef="displayedColumns">&lt;/tr>
  &lt;tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;">&lt;/tr>
&lt;/table>
    </code>
  </pre>
    </details>
    <table mat-table [dataSource]="rows" class="hover-rows">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{row.properties[0]}}</td>
      </ng-container>

      <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef>Function</th>
        <td mat-cell *matCellDef="let row">{{row.properties[1]}}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{row.properties[2]}}</td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>Gender</th>
        <td mat-cell *matCellDef="let row">{{row.properties[3]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
  </div>
</div>

<br>

<div class="card">
  <div class="card-title-container">
    <h3 class="text-l5 card-title">Mat Table Loading</h3>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
        <code ngNonBindable>
// CSS

@import 'sass/tables';

// HTML
&lt;table mat-table [dataSource]="[1, 2, 3]" >
  &lt;ng-container matColumnDef="name">
    &lt;th mat-header-cell *matHeaderCellDef>Name&lt;/th>
    &lt;td mat-cell *matCellDef="let row">
      &lt;div class="loading-bar">&lt;/div>
    &lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="function">
    &lt;th mat-header-cell *matHeaderCellDef>Function&lt;/th>
    &lt;td mat-cell *matCellDef="let row">
      &lt;div class="loading-bar">&lt;/div>
    &lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="location">
    &lt;th mat-header-cell *matHeaderCellDef>Location&lt;/th>
    &lt;td mat-cell *matCellDef="let row">
      &lt;div class="loading-bar">&lt;/div>
    &lt;/td>
  &lt;/ng-container>

  &lt;ng-container matColumnDef="gender">
    &lt;th mat-header-cell *matHeaderCellDef>Gender&lt;/th>
    &lt;td mat-cell *matCellDef="let row">
      &lt;div class="loading-bar">&lt;/div>
    &lt;/td>
  &lt;/ng-container>

  &lt;tr mat-header-row *matHeaderRowDef="displayedColumns">&lt;/tr>
  &lt;tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;">&lt;/tr>
&lt;/table>
        </code>
      </pre>
    </details>
    <table mat-table [dataSource]="[1, 2, 3]">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">
          <div class="loading-bar"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef>Function</th>
        <td mat-cell *matCellDef="let row">
          <div class="loading-bar"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">
          <div class="loading-bar"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>Gender</th>
        <td mat-cell *matCellDef="let row">
          <div class="loading-bar"></div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
  </div>
</div>
