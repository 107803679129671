import { Component, Input } from "@angular/core";

@Component({
  selector: "eff-mailing-design-preview",
  templateUrl: "./mailing-design-preview.component.html",
  styleUrls: ["./mailing-design-preview.component.scss"]
})
export class MailingDesignPreviewComponent {
  @Input() public mobileVersion: boolean = false;
  @Input() public internetSpiegel: boolean = false;
  @Input() public logoUrl: string;
  @Input() public backgroundImageUrl: string;
  @Input() public primaryColor: string;
  @Input() public showTag: boolean = false;

  public getPrimaryColor(): { [property: string]: string } {
    if(this.primaryColor == null){
      return;
    }

    const primaryColor = this.addHashWhenNeeded(this.primaryColor);

    return {
      "background-color": primaryColor
    };
  }

  private addHashWhenNeeded(input: string): string {
    if (input?.indexOf("#") === -1) {
      input = `#${input}`;
    }

    return input;
  }
}
