import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})
export class LandingPageComponent {
  constructor(private route: ActivatedRoute) { }

  public get isHome() {
    return this.route.children.length === 0;
  };
}
