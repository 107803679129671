export enum DialogAndPointerPositions {
  None = "",
  Right = "right",
  Left = "left",
  Center = "center"
}

export enum TooltipAlignments {
  None = "",
  Start = "start",
  Center = "center",
  End = "end"
}

export enum TooltipPositions {
  Above = "above",
  Below = "below",
  Before = "before",
  After= "after"
}

