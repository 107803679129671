import { QuestionnaireDesignPreviewComponent } from "./../components/questionnaire-design-preview/questionnaire-design-preview.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SvgModule } from "@effectory/branding-angular/shared";
import { MailingDesignPreviewComponent } from "../components/mailing-design-preview/mailing-design-preview.component";
import { MailingContentPreviewComponent } from "../components/mailing-content-preview/mailing-content-preview.component";

@NgModule({
  declarations: [
    QuestionnaireDesignPreviewComponent,
    MailingDesignPreviewComponent,
    MailingContentPreviewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SvgModule
  ],
  exports: [
    QuestionnaireDesignPreviewComponent,
    MailingDesignPreviewComponent,
    MailingContentPreviewComponent
  ]
})
export class DesignPreviewModule { }
