<h1 class="text-l2">Illustrations</h1>

<div class="illustrations-container">
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Empty state</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-empty-state>&lt;/eff-svg-empty-state></p>
      <eff-svg-empty-state></eff-svg-empty-state>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Lightbulb</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-lightbulb-illustration>&lt;/eff-svg-lightbulb-illustration></p>
      <eff-svg-lightbulb-illustration></eff-svg-lightbulb-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Error page</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-error-illustration>&lt;/eff-svg-error-illustration></p>
      <eff-svg-error-illustration></eff-svg-error-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Search - No Results</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-search-no-results-illustration>&lt;/eff-svg-search-no-results-illustration></p>
      <eff-svg-search-no-results-illustration></eff-svg-search-no-results-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Todo</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-todo-illustration>&lt;/eff-svg-todo-illustration></p>
      <eff-svg-todo-illustration></eff-svg-todo-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Settings confirmed</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-settings-confirmed-illustration>&lt;/eff-svg-settings-confirmed-illustration></p>
      <eff-svg-settings-confirmed-illustration></eff-svg-settings-confirmed-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Mail Invitation</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-mail-invitation-illustration>&lt;/eff-svg-mail-invitation-illustration</p>
      <eff-svg-mail-invitation-illustration></eff-svg-mail-invitation-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Invitation sent</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-invitation-sent-illustration>&lt;/eff-svg-invitation-sent-illustration</p>
      <eff-svg-invitation-sent-illustration></eff-svg-invitation-sent-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Placeholder for other illustrations</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-placeholder-illustration>&lt;/eff-svg-placeholder-illustration></p>
      <eff-svg-placeholder-illustration></eff-svg-placeholder-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Data illustration</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-data-illustration>&lt;/eff-svg-data-illustration></p>
      <eff-svg-data-illustration></eff-svg-data-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Column illustration</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-column-illustration>&lt;/eff-svg-column-illustration></p>
      <eff-svg-column-illustration></eff-svg-column-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Issue illustration</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-issue-illustration>&lt;/eff-svg-issue-illustration></p>
      <eff-svg-issue-illustration></eff-svg-issue-illustration>
    </div>
  </div>
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">File illustration</h2>
    </div>
    <div class="card-content-container">
      <p>&lt;eff-svg-file-illustration>&lt;/eff-svg-file-illustration></p>
      <eff-svg-file-illustration></eff-svg-file-illustration>
    </div>
  </div>
</div>
