import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Moment } from "moment";

@Component({
  selector: "eff-datepicker",
  templateUrl: "./datepicker.component.html"
})
export class DatepickerComponent {
  @ViewChild("datePicker", { static: true }) public datePicker: MatDatepicker<Moment | Date>;
  @Input() public placeholder: string;
  @Input() public minDate: Moment | Date;
  @Input() public maxDate: Moment | Date;
  @Input() public disabled = false;
  @Input() public selectedDate: Moment | Date;
  @Output() public datepickerChanged: EventEmitter<MatDatepickerInputEvent<Moment>> = new EventEmitter<MatDatepickerInputEvent<Moment>>();

  public openDatepicker(): void {
    this.datePicker.open();
  }

  public dateChanged(event: MatDatepickerInputEvent<Moment>): void {
    this.selectedDate = event.value;
    this.datepickerChanged.next(event);
  }
}
