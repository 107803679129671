<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Card title</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
      <code ngNonBindable>
&lt;div class="card">
  &lt;div class="card-title-container">
    &lt;h2 class="card-title text-l5">Card title&lt;/h2>
  &lt;/div>
  &lt;div class="card-content-container">
    &lt;eff-svg-feedback-large>&lt;/eff-svg-feedback-large>
  &lt;/div>
&lt;/div>
      </code>
    </pre>
    </details>
    <details>
      <summary>Code to use on white background</summary>
      <pre>
      <code ngNonBindable>
&lt;div class="card with-border">
  &lt;div class="card-title-container">
    &lt;h2 class="card-title text-l5">Card title&lt;/h2>
  &lt;/div>
  &lt;div class="card-content-container">
    &lt;eff-svg-feedback-large>&lt;/eff-svg-feedback-large>
  &lt;/div>
&lt;/div>
      </code>
    </pre>
    </details>
    <eff-svg-feedback-large></eff-svg-feedback-large>
  </div>
</div>

<br><br>