<div class="pagination">
  <button [disabled]="previousButtonDisabled || isLoading" class="previous" (click)="goToPreviousPage()">
    <eff-svg-chevron-right *ngIf="!previousButtonLoading"></eff-svg-chevron-right>
    <div class="loadingspinner" *ngIf="previousButtonLoading"></div>
  </button>
  <button [disabled]="nextButtonDisabled || isLoading" class="next" (click)="goToNextPage()">
    <eff-svg-chevron-right *ngIf="!nextButtonLoading"></eff-svg-chevron-right>
    <div class="loadingspinner" *ngIf="nextButtonLoading"></div>
  </button>
</div>
