<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Tags</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
    <code>
&lt;!-- in HTML -->
&lt;div class="tag">
  &lt;span>draft&lt;/span>
&lt;/div>

&lt;div class="tag uppercase">
  &lt;span>planned&lt;/span>
&lt;/div>

&lt;eff-beta-tag>&lt;/eff-beta-tag>
&lt;eff-beta-tag>test&lt;/eff-beta-tag>
&lt;eff-addon-tag>&lt;/eff-addon-tag>
&lt;eff-addon-tag [enabled]="true" [showTooltip]="true" [tooltipText]="'I am a tooltip'">&lt;/eff-addon-tag>
// In CSS
.tag &#123;
  background-color: $primary-2;
  color: $white;
&#125;
</code>
</pre>
    </details>
    <div class="tag">
      <span>draft</span>
    </div>
    <div class="tag">
      <span class="uppercase">planned</span>
    </div>
    <eff-beta-tag></eff-beta-tag>
    <eff-beta-tag>test</eff-beta-tag>
    <eff-addon-tag></eff-addon-tag>
    <eff-addon-tag [enabled]="true" [showTooltip]="true" [tooltipText]="'I am a tooltip'"></eff-addon-tag>
  </div>
</div>
