<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Block loader</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-loader>&lt;/eff-loader>
</code>
</pre>
    </details>
    <eff-loader></eff-loader>
  </div>
</div>
<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Inline loader</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;div class="loader-inline">&lt;/div>

.loader-inline &#123;
  animation: loadingspin .8s ease infinite;
  border: .2rem solid transparent;
  border-color: $neutral;
  border-radius: 50%;
  border-top-color: transparent;
  height: 1rem;
  pointer-events: none;
  width: 1rem;

  @keyframes loadingspin &#123;
    100% &#123;
      transform: rotate(360deg);
    &#125;
  &#125;
&#125;
</code>
</pre>
    </details>
    <div class="loader-inline"></div>
  </div>
</div>