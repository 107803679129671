import { Component } from "@angular/core";

@Component({
  selector: "app-shadows",
  templateUrl: "./shadows.component.html",
  styleUrls: ["./shadows.component.scss"]
})
export class ShadowsComponent {

}
