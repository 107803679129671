import { Component, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import * as moment from "moment";
import { Moment } from "moment";
import { EffectoryOption } from "projects/branding-angular/select/interfaces/effectory-option";
import { BehaviorSubject } from "rxjs";
import { languageSettings } from "@effectory/core";

@Component({
  selector: "app-selects",
  templateUrl: "./selects.component.html",
  styleUrls: ["./selects.component.scss"]
})
export class SelectsComponent {
  // Search select
  @ViewChild("selectWithButton") select: MatSelect;
  public values = [0];
  public value: number = undefined;
  public value1: number = undefined;

  public virtualValues = [0];
  public virtualValue: number = undefined;
  public virtualValue1: number = undefined;

  public languageValues = ["en-US"];
  public languageValue2 = [];

  public searchable = true;
  public selectDisabled = false;
  public disableLocalSearch = false;
  public showPreviewData = false;
  public searchInDescription = false;

  public culture: string;
  public cultureCodes: EffectoryOption[];

  // Checkbox
  public isLoading = false;
  public inline = false;

  // Datepicker
  public selectedDate: Moment = moment();
  public placeholder: string = "Select a date";
  public minDate: Moment = moment().subtract(3, "days");
  public maxDate: Moment = moment().add(15, "days");
  public disabled: boolean = true;

  // Timepicker
  public hour: BehaviorSubject<number> = new BehaviorSubject(8);
  public minute: BehaviorSubject<number> = new BehaviorSubject(30);

  constructor() {
    this.cultureCodes = languageSettings.default.cultures.map((code: string) => ({
        value: code,
        text: ""
      }));
      this.culture = "en-GB";
  }

  public alert(): void {
    return;
  }

  public dateChanged($event: any) {
    return;
  }

  public buttonClicked(): void {
    this.select.close();
  }
}
