<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">General guidelines</h2>
  </div>
  <div class="card-content-container">
    <p>General guidelines regarding accessibility. Always keep these in mind when developing an application.</p>
    <ul>
      <li>
        Always use the correct semantic HTML elements. That means using:
        <ul class="inline-code">
          <li>
            <pre><code>&lt;div></code></pre> for layout
          </li>
          <li>
            <pre><code>&lt;p></code></pre> or
            <pre><code>&lt;span></code></pre> for text
          </li>
          <li>
            <pre><code>&lt;h1></code></pre> etc. for headers
          </li>
          <li>
            <pre><code>&lt;button></code></pre> for buttons that act on the page
          </li>
          <li>
            <pre><code>&lt;a></code></pre> for links that take you off the page
          </li>
          <li>
            <pre><code>&lt;ol></code></pre>,
            <pre><code>&lt;ul></code></pre> for lists
          </li>
          <li>
            <pre><code>&lt;table></code></pre> for tables
          </li>
          <li>Etc.</li>
        </ul>
      </li>
      <li>Make sure the DOM order is the same order you read the page in</li>
    </ul>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">A guidelines</h2>
  </div>
  <div class="card-content-container">
    <p>Guidelines necessary for Level A</p>
    <ul>
      <li>
        Make sure all buttons, inputs, images etc. have a descriptive (alternate) text. This will make sure a description will be shown when the f.e. an image cannot be displayed.
      </li>
      <li>
        All video- or audio presentations must be accompanied by an alternative way of obtaining the information conveyed.
      </li>
      <li>
        All page functionality should be available by using the keyboard only.
      </li>
      <li>
        On focus or input, the page should not change significantly to not confuse the user. Significant changes include: spawning a pop-up window, changing the keyboard focus.
      </li>
      <li>
        Form validation errors should be efficient, intuitive, and accessible.
      </li>
      <li>
        Use aria-labels to properly describe an element in case the semantic HTML is not sufficient.
      </li>
    </ul>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">AA guidelines</h2>
  </div>
  <div class="card-content-container">
    <p>Guidelines necessary for Level AA</p>
    <ul>
      <li>
        Input types that gather specific types of user information have an appropriate autocomplete attribute attached.
      </li>
      <li>
        (Design) No loss of content or functionality occurs, and horizontal scrolling is avoided when content is presented at a width of 320 pixels. Large datasets with horizontal scrolling, such as tables and maps, are exempt.
      </li>
      <li>
        (Design) Text contrast must be at least 4,5:1, and non-text contrast (like graphs, icons) must be at least 3:1.
      </li>
      <li>
        User must be able to scale up font size, word/paragraph/letter spacing, without loss of functionality.
      </li>
      <li>
        The page is readable when zoomed 200%.
      </li>
      <li>
        It must be visually clear what element is focused on.
      </li>
      <li>
        Elements that have the same functionality must always be identified in the same way.
      </li>
      <li>
        If an error is detected, there must be a suggestion to fix that error presented accessibly.
      </li>
      <li>
        Error and status messages that are not focused, must be presented to a screen reader with aria attributes.
      </li>
    </ul>
  </div>
</div>