import { Component, OnInit } from "@angular/core";
import { NotificationService } from "projects/branding-angular/src/public-api";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit {

  public showNotification = true;
  public showBannerNotification = true;
  public showDismissOnlyNotification = true;

  constructor(private notificationService: NotificationService) { }

  public ngOnInit() {
  }

  public sendSuccessNotification() {
    this.notificationService.success("Success message!", "Close", () => { }, 2000);
  }

  public sendWarningNotification() {
    this.notificationService.warn("Warning message!");
  }

  public sendErrorNotification() {
    this.notificationService.error("Error message!", "Close", () => { });
  }

  public sendInfoNotification() {
    this.notificationService.info("Info message!", "Close", () => { });
  }

  public sendToastNotification() {
    this.notificationService.toast("Link copied to clipboard", null, null, 4000);
  }

  public sendToastNotificationWithAction() {
    this.notificationService.toast("Good one! It's saved to your action plan", "View Action Items", () => { });
  }
}
