import { animate, group, keyframes, query, style, transition, trigger } from "@angular/animations";

export const FadeInAnimation = trigger("routeAnimations", [
  transition("* => *", [
    query(":enter, :leave",
      style({ display: "block" }),
      { optional: true }),
    group([
      query(":enter", [
        animate("0.3s ease-in-out", keyframes([
          style({ transform: "translateY(2rem)", opacity: 0 }),
          style({ transform: "translateY(0)", opacity: 1 })
        ]))
      ], { optional: true }),
      query(":leave", [
        animate("0.3s ease-in-out", keyframes([
          style({ transform: "translateY(0)", opacity: 1 }),
          style({ transform: "translateY(-2rem)", opacity: 0 })
        ]))
      ], { optional: true })
    ])
  ])
]);
