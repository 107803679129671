<div class="notification inline" [ngClass]="messageType" [class.dismiss-only]="dismissOnly" [class.one-liner]="isOneLiner" [class.closable]="showCloseButton" role="alert">
  <button (click)="closeNotification.emit()" class="close-button" *ngIf="showCloseButton">
    <eff-svg-cross></eff-svg-cross>
  </button>
  <div class="icon-wrapper">
    <eff-svg-check class="icon success"></eff-svg-check>
    <eff-svg-info class="icon info inverted"></eff-svg-info>
    <eff-svg-alert-circle class="icon warn inverted"></eff-svg-alert-circle>
    <eff-svg-alert-circle class="icon error inverted"></eff-svg-alert-circle>
    <ng-content select="[subtleIcon]" *ngIf="messageType === 'subtle'"></ng-content>
  </div>
  <div class="text-wrapper">
    <span class="title text-w600" *ngIf="messageTitle?.length > 0">{{ messageTitle }}</span>
    <span class="message custom-content" #ref>
      <ng-content></ng-content>
    </span>
    <span class="message">
      <span *ngIf="message?.length > 0 && !ref.children.length">{{ message }}</span>
      <button class="inline-button link icon-before" *ngIf="inlineButtonText" (click)="inlineButtonCallback.emit()">
        <ng-content select="[inlineButtonIcon]"></ng-content>
        {{ inlineButtonText }}
      </button>
    </span>
    <button class="secondary icon-before" (click)="executeCallback(0)" *ngIf="messages[0]">
      <ng-content select="[buttonIcon0]"></ng-content>
      {{ messages[0] }}
    </button>
    <button class="secondary icon-before" (click)="executeCallback(1)" *ngIf="messages[1]">
      <ng-content select="[buttonIcon1]"></ng-content>
      {{ messages[1] }}
    </button>
    <button class="secondary icon-before" (click)="executeCallback(2)" *ngIf="messages[2]">
      <ng-content select="[buttonIcon2]"></ng-content>
      {{ messages[2] }}
    </button>
  </div>
  <button class="secondary icon-before" (click)="executeCallback(0)" *ngIf="messages[0]">
    <ng-content select="[buttonIcon]"></ng-content>
    {{ messages[0] }}
  </button>
</div>
