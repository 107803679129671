import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-loaders",
  templateUrl: "./loaders.component.html",
  styleUrls: ["./loaders.component.scss"]
})
export class LoadersComponent implements OnInit {

  public ngOnInit() {
  }

}
