import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-accessibility",
  templateUrl: "./accessibility.component.html",
  styleUrls: ["./accessibility.component.scss"]
})
export class AccessibilityComponent implements OnInit {

  ngOnInit(): void {
  }

}
