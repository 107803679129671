import { AfterViewInit, Component, ComponentFactoryResolver, QueryList, ViewChildren, ViewContainerRef } from "@angular/core";
import * as publicApi from "projects/branding-angular/src/public-api";

@Component({
  selector: "app-icons",
  templateUrl: "./icons.component.html",
  styleUrls: ["./icons.component.scss"]
})
export class IconsComponent implements AfterViewInit {
  @ViewChildren("icon1", {read: ViewContainerRef}) public icons1ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon1inverted", {read: ViewContainerRef}) public icons1invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon2", {read: ViewContainerRef}) public icons2ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon2inverted", {read: ViewContainerRef}) public icons2invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon3", {read: ViewContainerRef}) public icons3ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon3inverted", {read: ViewContainerRef}) public icons3invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon4", {read: ViewContainerRef}) public icons4ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon4inverted", {read: ViewContainerRef}) public icons4invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon5", {read: ViewContainerRef}) public icons5ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon5inverted", {read: ViewContainerRef}) public icons5invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon6", {read: ViewContainerRef}) public icons6ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon6inverted", {read: ViewContainerRef}) public icons6invertedRef: QueryList<ViewContainerRef>;
  @ViewChildren("icon7", {read: ViewContainerRef}) public icons7ref: QueryList<ViewContainerRef>;
  @ViewChildren("icon7inverted", {read: ViewContainerRef}) public icons7invertedRef: QueryList<ViewContainerRef>;

  public headers = [
    "Element name",
    "Icon"
  ];

  public icons1 = [
    {
      name: "<eff-svg-activity>",
      element: publicApi.ActivitySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-afas>",
      element: publicApi.AfasSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-aggregations>",
      element: publicApi.AggregationsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-alert-circle>",
      element: publicApi.AlertCircleSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-alert-triangle>",
      element: publicApi.AlertTriangleSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-archive>",
      element: publicApi.ArchiveSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-arrow-down>",
      element: publicApi.ArrowDownSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-arrow-left>",
      element: publicApi.ArrowLeftSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-arrow-right>",
      element: publicApi.ArrowRightSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-arrow-sub>",
      element: publicApi.ArrowSubSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-arrow-up>",
      element: publicApi.ArrowUpSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-bar-chart>",
      element: publicApi.BarChartSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-bar-chart-2>",
      element: publicApi.BarChartAltSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-bell>",
      element: publicApi.BellSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-benchmark>",
      element: publicApi.BenchmarkSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-book-open>",
      element: publicApi.BookOpenSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-bookmark>",
      element: publicApi.BookmarkSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-box>",
      element: publicApi.BoxSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-briefcase>",
      element: publicApi.BriefcaseSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-building>",
      element: publicApi.BuildingSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-calendar>",
      element: publicApi.CalendarSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-calendar-add>",
      element: publicApi.CalendarAddSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-calendar-remove>",
      element: publicApi.CalendarRemoveSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-category>",
      element: publicApi.CategorySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-check>",
      element: publicApi.CheckSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-check-square>",
      element: publicApi.CheckSquareSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-check-square-inverted>",
      element: publicApi.CheckSquareInvertedSvgComponent,
      inverted: false
    }
  ];

  public icons2 = [
    {
      name: "<eff-svg-chevron-down>",
      element: publicApi.ChevronDownSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-chevron-end>",
      element: publicApi.ChevronEndSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-chevron-left>",
      element: publicApi.ChevronLeftSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-chevron-right>",
      element: publicApi.ChevronRightSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-chevron-start>",
      element: publicApi.ChevronStartSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-chevron-up>",
      element: publicApi.ChevronUpSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-clipboard>",
      element: publicApi.ClipboardSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-clipboard-a>",
      element: publicApi.ClipboardASvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-clipboard-note>",
      element: publicApi.ClipboardNoteSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-clipboard-settings>",
      element: publicApi.ClipboardSettingsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-clock>",
      element: publicApi.ClockSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-cookie>",
      element: publicApi.CookieSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-copy>",
      element: publicApi.CopySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-cpu>",
      element: publicApi.CpuSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-cross>",
      element: publicApi.CrossSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-down-vote>",
      element: publicApi.DownVoteSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-download>",
      element: publicApi.DownloadSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-download-2>",
      element: publicApi.Download2SvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-drag-drop>",
      element: publicApi.DragDropSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-duration>",
      element: publicApi.DurationSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-edit>",
      element: publicApi.EditSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-edit-inline>",
      element: publicApi.EditInlineSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-equal>",
      element: publicApi.EqualSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-excel>",
      element: publicApi.ExcelSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-export>",
      element: publicApi.ExportSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-external-link>",
      element: publicApi.ExternalLinkSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-eye>",
      element: publicApi.EyeSvgComponent,
      inverted: false
    }
  ];

  public icons3 = [
    {
      name: "<eff-svg-eye-off>",
      element: publicApi.EyeOffSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-extended>",
      element: publicApi.ExtendedSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-featured>",
      element: publicApi.FeaturedSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-find-replace>",
      element: publicApi.FindReplaceSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-file>",
      element: publicApi.FileSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-filter>",
      element: publicApi.FilterSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-folder>",
      element: publicApi.FolderSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-folder-notes>",
      element: publicApi.FolderNotesSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-gear>",
      element: publicApi.GearSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-gift>",
      element: publicApi.GiftSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-globe>",
      element: publicApi.GlobeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-group>",
      element: publicApi.GroupSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-help>",
      element: publicApi.HelpSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-hierarchy>",
      element: publicApi.HierarchySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-home>",
      element: publicApi.HomeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-info>",
      element: publicApi.InfoSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-layout>",
      element: publicApi.LayoutSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-levels>",
      element: publicApi.LevelsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-lightbulb>",
      element: publicApi.LightbulbSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-link>",
      element: publicApi.LinkSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-lock>",
      element: publicApi.LockSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-log-out>",
      element: publicApi.LogOutSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-love>",
      element: publicApi.LoveSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-mail>",
      element: publicApi.MailSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-map>",
      element: publicApi.MapSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-map-pin>",
      element: publicApi.MapPinSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-maximize>",
      element: publicApi.MaximizeSvgComponent,
      inverted: false
    }
  ];

  public icons4 = [
    {
      name: "<eff-svg-menu>",
      element: publicApi.MenuSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-message>",
      element: publicApi.MessageSvgComponent,
      inverted: true
    },
    {
      name: "<eff-svg-minimize>",
      element: publicApi.MinimizeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-minus>",
      element: publicApi.MinusSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-more-horizontal>",
      element: publicApi.MoreHorizontalSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-more-vertical>",
      element: publicApi.MoreVerticalSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-net-promoter-score>",
      element: publicApi.NetPromoterScoreSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-pause>",
      element: publicApi.PauseSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-pen-tool>",
      element: publicApi.PenToolSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-percent>",
      element: publicApi.PercentSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-personio>",
      element: publicApi.PersonioSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-pie-chart>",
      element: publicApi.PieChartSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-pipet>",
      element: publicApi.PipetSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-planned>",
      element: publicApi.PlannedSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-play>",
      element: publicApi.PlaySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-plus>",
      element: publicApi.PlusSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-plus-minus>",
      element: publicApi.PlusMinusSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-point-scale>",
      element: publicApi.PointScaleSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-privacy>",
      element: publicApi.PrivacySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-refresh>",
      element: publicApi.RefreshSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-response>",
      element: publicApi.ResponseSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-rotate-ccw>",
      element: publicApi.RotateCcwSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-running>",
      element: publicApi.RunningSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-search>",
      element: publicApi.SearchSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-send>",
      element: publicApi.SendSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-select-mouse>",
      element: publicApi.SelectMouseSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sftp>",
      element: publicApi.SftpSvgComponent,
      inverted: false
    }
  ];

  public icons5 = [
    {
      name: "<eff-svg-share>",
      element: publicApi.ShareSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-share-1>",
      element: publicApi.Share1SvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-shop>",
      element: publicApi.ShopSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-single-answer>",
      element: publicApi.SingleAnswerSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-star>",
      element: publicApi.StarSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-successfactors>",
      element: publicApi.SuccessfactorsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-successfactors-1>",
      element: publicApi.Successfactors1SvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-tag>",
      element: publicApi.TagSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-text-entry>",
      element: publicApi.TextEntrySvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-top-3>",
      element: publicApi.Top3SvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-trash>",
      element: publicApi.TrashSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-trend-down>",
      element: publicApi.TrendDownSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-trend-up>",
      element: publicApi.TrendUpSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-undo>",
      element: publicApi.UndoSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-unlock>",
      element: publicApi.UnlockSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-upload>",
      element: publicApi.UploadSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-upload-file>",
      element: publicApi.UploadFileSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-up-vote>",
      element: publicApi.UpVoteSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-user>",
      element: publicApi.UserSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-user-check>",
      element: publicApi.UserCheckSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-user-min>",
      element: publicApi.UserMinSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-user-plus>",
      element: publicApi.UserPlusSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-users>",
      element: publicApi.UsersSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-waypoint>",
      element: publicApi.WaypointSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-win>",
      element: publicApi.WinSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-words>",
      element: publicApi.WordsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-youtube>",
      element: publicApi.YoutubeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-zap>",
      element: publicApi.ZapSvgComponent,
      inverted: true
    }
  ];

  // Only contains Sidebar & Breadcrumb icons
  public icons6 = [
    {
      name: "<eff-svg-sidebar-actions>",
      element: publicApi.SidebarActionsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sidebar-analytics>",
      element: publicApi.SidebarAnalyticsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sidebar-employees>",
      element: publicApi.SidebarEmployeesSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sidebar-home>",
      element: publicApi.SidebarHomeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sidebar-projects>",
      element: publicApi.SidebarProjectsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-sidebar-settings>",
      element: publicApi.SidebarSettingsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-actions>",
      element: publicApi.BreadcrumbActionsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-analytics>",
      element: publicApi.BreadcrumbAnalyticsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-employees>",
      element: publicApi.BreadcrumbEmployeesSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-home>",
      element: publicApi.BreadcrumbHomeSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-projects>",
      element: publicApi.BreadcrumbProjectsSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-breadcrumb-settings>",
      element: publicApi.BreadcrumbSettingsSvgComponent,
      inverted: false
    }
  ];

  // Only contains flags
  public icons7 = [
    {
      name: "<eff-svg-flag-be>",
      element: publicApi.FlagBESvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-ch>",
      element: publicApi.FlagCHSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-cn>",
      element: publicApi.FlagCNSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-cz>",
      element: publicApi.FlagCZSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-de>",
      element: publicApi.FlagDESvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-dk>",
      element: publicApi.FlagDKSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-ee>",
      element: publicApi.FlagEESvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-es>",
      element: publicApi.FlagESSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-fi>",
      element: publicApi.FlagFISvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-fr>",
      element: publicApi.FlagFRSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-gb>",
      element: publicApi.FlagGBSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-hr>",
      element: publicApi.FlagHRSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-hu>",
      element: publicApi.FlagHUSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-in>",
      element: publicApi.FlagINSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-it>",
      element: publicApi.FlagITSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-jp>",
      element: publicApi.FlagJPSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-kr>",
      element: publicApi.FlagKRSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-lt>",
      element: publicApi.FlagLTSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-lv>",
      element: publicApi.FlagLVSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-mx>",
      element: publicApi.FlagMXSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-nl>",
      element: publicApi.FlagNLSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-no>",
      element: publicApi.FlagNOSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-pl>",
      element: publicApi.FlagPLSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-pt>",
      element: publicApi.FlagPTSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-ro>",
      element: publicApi.FlagROSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-rs>",
      element: publicApi.FlagRSSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-ru>",
      element: publicApi.FlagRUSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-se>",
      element: publicApi.FlagSESvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-si>",
      element: publicApi.FlagSISvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-sk>",
      element: publicApi.FlagSKSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-tr>",
      element: publicApi.FlagTRSvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-ua>",
      element: publicApi.FlagUASvgComponent,
      inverted: false
    },
    {
      name: "<eff-svg-flag-us>",
      element: publicApi.FlagUSSvgComponent,
      inverted: false
    }
  ];

  constructor(private cfr: ComponentFactoryResolver) {}

  public ngAfterViewInit() {
    this.loadIcons();
  }

  private loadIcons() {
    const iconLists = ["1", "2", "3", "4", "5", "6", "7"];
    for (const j of iconLists) {
      if (this["icons" + j + "ref"]) {
        for (let i = 0; i < this["icons" + j + "ref"].toArray().length; i++) {
          const icon = this["icons" + j][i];
          const component = icon.element;
          if (component) {
            const target = this["icons" + j + "ref"].toArray()[i];
            const iconComponent = this.cfr.resolveComponentFactory(component);
            const result = target.createComponent(iconComponent);
            result.location.nativeElement.classList.add("icon");
          }
          if (icon.inverted) {
            const target = this["icons" + j + "invertedRef"].toArray()[i];
            const iconComponent = this.cfr.resolveComponentFactory(component);
            const result = target.createComponent(iconComponent);
            result.location.nativeElement.classList.add("icon");
            result.location.nativeElement.classList.add("inverted");
          }
        }
      }
    }
  }
}
