import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActivitySvgComponent } from "../images/activity/activity.component";
import { AfasSvgComponent } from "../images/afas/afas.component";
import { AggregationsSvgComponent } from "../images/aggregations/aggregations.component";
import { AlertCircleSvgComponent } from "../images/alert-circle/alert-circle.component";
import { AlertTriangleSvgComponent } from "../images/alert-triangle/alert-triangle.component";
import { ArchiveSvgComponent } from "../images/archive/archive.component";
import { ArrowDownSvgComponent } from "../images/arrow-down/arrow-down.component";
import { ArrowLeftSvgComponent } from "../images/arrow-left/arrow-left.component";
import { ArrowRightSvgComponent } from "../images/arrow-right/arrow-right.component";
import { ArrowSubSvgComponent } from "../images/arrow-sub/arrow-sub.component";
import { ArrowUpSvgComponent } from "../images/arrow-up/arrow-up.component";
import { BarChartAltSvgComponent } from "../images/bar-chart-2/bar-chart-2.component";
import { BarChartSvgComponent } from "../images/bar-chart/bar-chart.component";
import { BellSvgComponent } from "../images/bell/bell.component";
import { BenchmarkSvgComponent } from "../images/benchmark/benchmark.component";
import { BlueAlertCircleSvgComponent } from "../images/blue-alert-circle/blue-alert-circle.component";
import { BookOpenSvgComponent } from "../images/book-open/book-open.component";
import { BookmarkSvgComponent } from "../images/bookmark/bookmark.component";
import { BoxSvgComponent } from "../images/box/box.component";
import { BreadcrumbActionsSvgComponent } from "../images/breadcrumb-actions/breadcrumb-actions.component";
import { BreadcrumbAnalyticsSvgComponent } from "../images/breadcrumb-analytics/breadcrumb-analytics.component";
import { BreadcrumbEmployeesSvgComponent } from "../images/breadcrumb-employees/breadcrumb-employees.component";
import { BreadcrumbHomeSvgComponent } from "../images/breadcrumb-home/breadcrumb-home.component";
import { BreadcrumbProjectsSvgComponent } from "../images/breadcrumb-projects/breadcrumb-projects.component";
import { BreadcrumbSettingsSvgComponent } from "../images/breadcrumb-settings/breadcrumb-settings.component";
import { BriefcaseSvgComponent } from "../images/briefcase/briefcase.component";
import { BuildingSvgComponent } from "../images/building/building.component";
import { CalendarAddSvgComponent } from "../images/calendar-add/calendar-add.component";
import { CalendarRemoveSvgComponent } from "../images/calendar-remove/calendar-remove.component";
import { CalendarSvgComponent } from "../images/calendar/calendar.component";
import { CategorySvgComponent } from "../images/category/category.component";
import { CheckDoneSvgComponent } from "../images/check-done/check-done.component";
import { CheckSquareInvertedSvgComponent } from "../images/check-square-inverted/check-square-inverted.component";
import { CheckSquareSvgComponent } from "../images/check-square/check-square.component";
import { CheckSvgComponent } from "../images/check/check.component";
import { ChevronDownSvgComponent } from "../images/chevron-down/chevron-down.component";
import { ChevronEndSvgComponent } from "../images/chevron-end/chevron-end.component";
import { ChevronLeftSvgComponent } from "../images/chevron-left/chevron-left.component";
import { ChevronRightSvgComponent } from "../images/chevron-right/chevron-right.component";
import { ChevronStartSvgComponent } from "../images/chevron-start/chevron-start.component";
import { ChevronUpSvgComponent } from "../images/chevron-up/chevron-up.component";
import { ClipboardASvgComponent } from "../images/clipboard-a/clipboard-a.component";
import { ClipboardNoteSvgComponent } from "../images/clipboard-note/clipboard-note.component";
import { ClipboardSettingsSvgComponent } from "../images/clipboard-settings/clipboard-settings.component";
import { ClipboardSvgComponent } from "../images/clipboard/clipboard.component";
import { ClockSvgComponent } from "../images/clock/clock.component";
import { ColumnIllustrationSvgComponent } from "../images/column-illustration/column-illustration.component";
import { CompletedLargeSvgComponent } from "../images/completed-large/completed-large.component";
import { CookieSvgComponent } from "../images/cookie/cookie.component";
import { CopySvgComponent } from "../images/copy/copy.component";
import { CpuSvgComponent } from "../images/cpu/cpu.component";
import { CrossSvgComponent } from "../images/cross/cross.component";
import { DataIllustrationSvgComponent } from "../images/data-illustration/data-illustration.component";
import { DesktopSvgComponent } from "../images/desktop/desktop.component";
import { Download2SvgComponent } from "../images/download-2/download-2.component";
import { DownloadSvgComponent } from "../images/download/download.component";
import { DownVoteSvgComponent } from "../images/down-vote/down-vote.component";
import { DragDropSvgComponent } from "../images/drag-drop/drag-drop.component";
import { DurationSvgComponent } from "../images/duration/duration.component";
import { EditInlineSvgComponent } from "../images/edit-inline/edit-inline.component";
import { EditSvgComponent } from "../images/edit/edit.component";
import { EffectoryLogoFullSvgComponent } from "../images/effectory-logo-full/effectory-logo-full.component";
import { EffectoryLogoSvgComponent } from "../images/effectory-logo/effectory-logo.component";
import { EffectoryYellowSvgComponent } from "../images/effectory-logo-yellow/effectory-yellow-svg.component";
import { EmptyStateSvgComponent } from "../images/empty-state/empty-state.component";
import { EqualSvgComponent } from "../images/equal/equal.component";
import { ErrorSvgComponent } from "../images/error-svg/error-svg.component";
import { ExcelSvgComponent } from "../images/excel/excel.component";
import { ExportSvgComponent } from "../images/export/export.component";
import { ExtendedSvgComponent } from "../images/extended/extended.component";
import { ExternalLinkSvgComponent } from "../images/external-link/external-link.component";
import { EyeOffSvgComponent } from "../images/eye-off/eye-off.component";
import { EyeSvgComponent } from "../images/eye/eye.component";
import { FeaturedSvgComponent } from "../images/featured/featured.component";
import { FeedbackLargeSvgComponent } from "../images/feedback-large/feedback-large.component";
import { FileIllustrationSvgComponent } from "../images/file-illustration/file-illustration.component";
import { FileSvgComponent } from "../images/file/file.component";
import { FilterSvgComponent } from "../images/filter/filter.component";
import { FindReplaceSvgComponent } from "../images/find-replace/find-replace.component";
import { FlagBESvgComponent } from "../images/flag-be/flag-be.component";
import { FlagCHSvgComponent } from "../images/flag-ch/flag-ch.component";
import { FlagCNSvgComponent } from "../images/flag-cn/flag-cn.component";
import { FlagCZSvgComponent } from "../images/flag-cz/flag-cz.component";
import { FlagDESvgComponent } from "../images/flag-de/flag-de.component";
import { FlagDKSvgComponent } from "../images/flag-dk/flag-dk.component";
import { FlagEESvgComponent } from "../images/flag-ee/flag-ee.component";
import { FlagESSvgComponent } from "../images/flag-es/flag-es.component";
import { FlagFISvgComponent } from "../images/flag-fi/flag-fi.component";
import { FlagFRSvgComponent } from "../images/flag-fr/flag-fr.component";
import { FlagGBSvgComponent } from "../images/flag-gb/flag-gb.component";
import { FlagHRSvgComponent } from "../images/flag-hr/flag-hr.component";
import { FlagHUSvgComponent } from "../images/flag-hu/flag-hu.component";
import { FlagINSvgComponent } from "../images/flag-in/flag-in.component";
import { FlagITSvgComponent } from "../images/flag-it/flag-it.component";
import { FlagJPSvgComponent } from "../images/flag-jp/flag-jp.component";
import { FlagKRSvgComponent } from "../images/flag-kr/flag-kr.component";
import { FlagLTSvgComponent } from "../images/flag-lt/flag-lt.component";
import { FlagLVSvgComponent } from "../images/flag-lv/flag-lv.component";
import { FlagMXSvgComponent } from "../images/flag-mx/flag-mx.component";
import { FlagNLSvgComponent } from "../images/flag-nl/flag-nl.component";
import { FlagNOSvgComponent } from "../images/flag-no/flag-no.component";
import { FlagPLSvgComponent } from "../images/flag-pl/flag-pl.component";
import { FlagPTSvgComponent } from "../images/flag-pt/flag-pt.component";
import { FlagROSvgComponent } from "../images/flag-ro/flag-ro.component";
import { FlagRSSvgComponent } from "../images/flag-rs/flag-rs.component";
import { FlagRUSvgComponent } from "../images/flag-ru/flag-ru.component";
import { FlagSESvgComponent } from "../images/flag-se/flag-se.component";
import { FlagSISvgComponent } from "../images/flag-si/flag-si.component";
import { FlagSKSvgComponent } from "../images/flag-sk/flag-sk.component";
import { FlagTRSvgComponent } from "../images/flag-tr/flag-tr.component";
import { FlagUASvgComponent } from "../images/flag-ua/flag-ua.component";
import { FlagUSSvgComponent } from "../images/flag-us/flag-us.component";
import { FolderSvgComponent } from "../images/folder/folder.component";
import { FolderNotesSvgComponent } from "../images/folder-notes/folder-notes.component";
import { FormCompletedLargeSvgComponent } from "../images/form-completed-large/form-completed-large.component";
import { GearSvgComponent } from "../images/gear/gear.component";
import { GiftSvgComponent } from "../images/gift/gift.component";
import { GlobeSvgComponent } from "../images/globe/globe.component";
import { GroupSvgComponent } from "../images/group/group.component";
import { HelpSvgComponent } from "../images/help/help.component";
import { HierarchySvgComponent } from "../images/hierarchy/hierarchy.component";
import { HomeSvgComponent } from "../images/home/home.component";
import { InfoSvgComponent } from "../images/info/info.component";
import { InternetSpiegelLogoFullSvgComponent } from "../images/internetspiegel-logo-full/internetspiegel-logo-full.component";
import { InvitationSentIllustrationSvgComponent } from "../images/invitation-sent-illustration/invitation-sent-illustration.component";
import { IssueIllustrationSvgComponent } from "../images/issue-illustration/issue-illustration.component";
import { LayoutSvgComponent } from "../images/layout/layout.component";
import { LevelsSvgComponent } from "../images/levels/levels.component";
import { LightbulbIllustrationSvgComponent } from "../images/lightbulb-illustration/lightbulb-illustration.component";
import { LightbulbSvgComponent } from "../images/lightbulb/lightbulb.component";
import { LinkSvgComponent } from "../images/link/link.component";
import { LockSvgComponent } from "../images/lock/lock.component";
import { LogOutSvgComponent } from "../images/log-out/log-out.component";
import { LoveSvgComponent } from "../images/love/love.component";
import { MailInvitationIllustrationSvgComponent } from "../images/mail-invitation-illustration/mail-invitation-illustration.component";
import { MailSvgComponent } from "../images/mail/mail.component";
import { MapPinSvgComponent } from "../images/map-pin/map-pin.component";
import { MapSvgComponent } from "../images/map/map.component";
import { MaximizeSvgComponent } from "../images/maximize/maximize.component";
import { MenuSvgComponent } from "../images/menu/menu.component";
import { MessageSvgComponent } from "../images/message/message.component";
import { MinimizeSvgComponent } from "../images/minimize/minimize.component";
import { MinusSvgComponent } from "../images/minus/minus.component";
import { MobileSvgComponent } from "../images/mobile/mobile.component";
import { MoreHorizontalSvgComponent } from "../images/more-horizontal/more-horizontal.component";
import { MoreVerticalSvgComponent } from "../images/more-vertical/more-vertical.component";
import { NetPromoterScoreSvgComponent } from "../images/net-promoter-score/net-promoter-score.component";
import { NoActivitiesLargeSvgComponent } from "../images/no-activities-large/no-activities-large.component";
import { NoQuestionLibraryLargeSvgComponent } from "../images/no-question-library-large/no-question-library-large.component";
import { NoQuestionSetsLargeSvgComponent } from "../images/no-question-sets-large/no-question-sets-large.component";
import { NoResponseLargeSvgComponent } from "../images/no-response-large/no-response-large.component";
import { OrangeAlertCircleSvgComponent } from "../images/orange-alert-circle/orange-alert-circle.component";
import { PauseSvgComponent } from "../images/pause/pause.component";
import { PenToolSvgComponent } from "../images/pen-tool/pen-tool.component";
import { PercentSvgComponent } from "../images/percent/percent.component";
import { PersonioSvgComponent } from "../images/personio/personio.component";
import { PieChartSvgComponent } from "../images/pie-chart/pie-chart.component";
import { PipetSvgComponent } from "../images/pipet/pipet.component";
import { PlaceholderIllustrationSvgComponent } from "../images/placeholder-illustration/placeholder-illustration.component";
import { PlannedSvgComponent } from "../images/planned/planned.component";
import { PlaySvgComponent } from "../images/play/play.component";
import { PlusMinusSvgComponent } from "../images/plus-minus/plus-minus.component";
import { PlusSvgComponent } from "../images/plus/plus.component";
import { PointScaleSvgComponent } from "../images/point-scale/point-scale.component";
import { PrinterLargeSvgComponent } from "../images/printer-large/printer-large.component";
import { PrivacySvgComponent } from "../images/privacy/privacy.component";
import { ProjectLargeSvgComponent } from "../images/project-large/project-large.component";
import { QuestionLibraryLargeSvgComponent } from "../images/question-library-large/question-library-large.component";
import { QuestionnaireLargeSvgComponent } from "../images/questionnaire-large/questionnaire-large.component";
import { RefreshSvgComponent } from "../images/refresh/refresh.component";
import { ReportLargeSvgComponent } from "../images/report-large/report-large.component";
import { ResponseLargeSvgComponent } from "../images/response-large/response-large.component";
import { ResponseSvgComponent } from "../images/response/response.component";
import { RotateCcwSvgComponent } from "../images/rotate-ccw/rotate-ccw.component";
import { RunningSvgComponent } from "../images/running/running.component";
import { RunningClockLargeSvgComponent } from "../images/running-clock-large/running-clock-large.component";
import { SearchNoResultsIllustrationSvgComponent } from "../images/search-no-results-illustration/search-no-results-illustration.component";
import { SearchNoResultsLargeSvgComponent } from "../images/search-no-results-large/search-no-results-large.component";
import { SearchSvgComponent } from "../images/search/search.component";
import { SelectMouseSvgComponent } from "../images/select-mouse/select-mouse.component";
import { SendSvgComponent } from "../images/send/send.component";
import { SettingsConfirmedIllustrationSvgComponent } from "../images/settings-confirmed-illustration/settings-confirmed-illustration.component";
import { SftpSvgComponent } from "../images/sftp/sftp.component";
import { Share1SvgComponent } from "../images/share-1/share-1.component";
import { ShareSvgComponent } from "../images/share/share.component";
import { ShopSvgComponent } from "../images/shop/shop.component";
import { SidebarActionsSvgComponent } from "../images/sidebar-actions/sidebar-actions.component";
import { SidebarAnalyticsSvgComponent } from "../images/sidebar-analytics/sidebar-analytics.component";
import { SidebarEmployeesSvgComponent } from "../images/sidebar-employees/sidebar-employees.component";
import { SidebarHomeSvgComponent } from "../images/sidebar-home/sidebar-home.component";
import { SidebarProjectsSvgComponent } from "../images/sidebar-projects/sidebar-projects.component";
import { SidebarSettingsSvgComponent } from "../images/sidebar-settings/sidebar-settings.component";
import { SingleAnswerSvgComponent } from "../images/single-answer/single-answer.component";
import { StarSvgComponent } from "../images/star/star.component";
import { SuccessfactorsSvgComponent } from "../images/successfactors/successfactors.component";
import { Successfactors1SvgComponent } from "../images/successfactors-1/successfactors-1.component";
import { TagSvgComponent } from "../images/tag/tag.component";
import { TextEntrySvgComponent } from "../images/text-entry/text-entry.component";
import { TodoIllustrationSvgComponent } from "../images/todo-illustration/todo-illustration.component";
import { Top3SvgComponent } from "../images/top-3/top-3.component";
import { TrashSvgComponent } from "../images/trash/trash.component";
import { TrendDownSvgComponent } from "../images/trend-down/trend-down.component";
import { TrendUpSvgComponent } from "../images/trend-up/trend-up.component";
import { UndoSvgComponent } from "../images/undo/undo.component";
import { UnlockSvgComponent } from "../images/unlock/unlock.component";
import { UploadFileLargeSvgComponent } from "../images/upload-file-large/upload-file-large.component";
import { UploadFileSvgComponent } from "../images/upload-file/upload-file.component";
import { UploadSvgComponent } from "../images/upload/upload.component";
import { UpVoteSvgComponent } from "../images/up-vote/up-vote.component";
import { UserCheckSvgComponent } from "../images/user-check/user-check.component";
import { UserMinSvgComponent } from "../images/user-min/user-min.component";
import { UserPlusSvgComponent } from "../images/user-plus/user-plus.component";
import { UsersSvgComponent } from "../images/users/users.component";
import { UserSvgComponent } from "../images/user/user.component";
import { WaypointSvgComponent } from "./../images/waypoint/waypoint.component";
import { WcwpSvgComponent } from "../images/wcwp/wcwp.component";
import { WinSvgComponent } from "../images/win/win.component";
import { WordsSvgComponent } from "../images/words/words.component";
import { WorkInProgressComponent } from "../images/work-in-progress/work-in-progress.component";
import { YoutubeSvgComponent } from "../images/youtube/youtube.component";
import { ZapSvgComponent } from "../images/zap/zap.component";

@NgModule({
  declarations: [
    ActivitySvgComponent,
    AfasSvgComponent,
    AggregationsSvgComponent,
    AlertCircleSvgComponent,
    AlertTriangleSvgComponent,
    ArchiveSvgComponent,
    ArrowDownSvgComponent,
    ArrowLeftSvgComponent,
    ArrowRightSvgComponent,
    ArrowSubSvgComponent,
    ArrowUpSvgComponent,
    BarChartAltSvgComponent,
    BarChartSvgComponent,
    BellSvgComponent,
    BenchmarkSvgComponent,
    BlueAlertCircleSvgComponent,
    BookOpenSvgComponent,
    BookmarkSvgComponent,
    BoxSvgComponent,
    BreadcrumbActionsSvgComponent,
    BreadcrumbAnalyticsSvgComponent,
    BreadcrumbEmployeesSvgComponent,
    BreadcrumbHomeSvgComponent,
    BreadcrumbProjectsSvgComponent,
    BreadcrumbSettingsSvgComponent,
    BriefcaseSvgComponent,
    BuildingSvgComponent,
    CalendarAddSvgComponent,
    CalendarRemoveSvgComponent,
    CalendarSvgComponent,
    CategorySvgComponent,
    CheckDoneSvgComponent,
    CheckSquareInvertedSvgComponent,
    CheckSquareSvgComponent,
    CheckSvgComponent,
    ChevronDownSvgComponent,
    ChevronEndSvgComponent,
    ChevronLeftSvgComponent,
    ChevronRightSvgComponent,
    ChevronStartSvgComponent,
    ChevronUpSvgComponent,
    ClipboardASvgComponent,
    ClipboardNoteSvgComponent,
    ClipboardSettingsSvgComponent,
    ClipboardSvgComponent,
    ClockSvgComponent,
    ColumnIllustrationSvgComponent,
    CompletedLargeSvgComponent,
    CookieSvgComponent,
    CopySvgComponent,
    CpuSvgComponent,
    CrossSvgComponent,
    DataIllustrationSvgComponent,
    DesktopSvgComponent,
    Download2SvgComponent,
    DownloadSvgComponent,
    DownVoteSvgComponent,
    DragDropSvgComponent,
    DurationSvgComponent,
    EditInlineSvgComponent,
    EditSvgComponent,
    EffectoryLogoFullSvgComponent,
    EffectoryLogoSvgComponent,
    EffectoryYellowSvgComponent,
    EmptyStateSvgComponent,
    EqualSvgComponent,
    ErrorSvgComponent,
    ExcelSvgComponent,
    ExportSvgComponent,
    ExternalLinkSvgComponent,
    EyeOffSvgComponent,
    EyeSvgComponent,
    ExtendedSvgComponent,
    FeaturedSvgComponent,
    FeedbackLargeSvgComponent,
    FileIllustrationSvgComponent,
    FileSvgComponent,
    FilterSvgComponent,
    FindReplaceSvgComponent,
    FlagBESvgComponent,
    FlagCHSvgComponent,
    FlagCNSvgComponent,
    FlagCZSvgComponent,
    FlagDESvgComponent,
    FlagDKSvgComponent,
    FlagEESvgComponent,
    FlagESSvgComponent,
    FlagFISvgComponent,
    FlagFRSvgComponent,
    FlagGBSvgComponent,
    FlagHRSvgComponent,
    FlagHUSvgComponent,
    FlagINSvgComponent,
    FlagITSvgComponent,
    FlagJPSvgComponent,
    FlagKRSvgComponent,
    FlagLTSvgComponent,
    FlagLVSvgComponent,
    FlagMXSvgComponent,
    FlagNLSvgComponent,
    FlagNOSvgComponent,
    FlagPLSvgComponent,
    FlagPTSvgComponent,
    FlagROSvgComponent,
    FlagRSSvgComponent,
    FlagRUSvgComponent,
    FlagSESvgComponent,
    FlagSESvgComponent,
    FlagSISvgComponent,
    FlagSKSvgComponent,
    FlagTRSvgComponent,
    FlagUASvgComponent,
    FlagUSSvgComponent,
    FolderSvgComponent,
    FolderNotesSvgComponent,
    FormCompletedLargeSvgComponent,
    GearSvgComponent,
    GiftSvgComponent,
    GlobeSvgComponent,
    GroupSvgComponent,
    HelpSvgComponent,
    HierarchySvgComponent,
    HomeSvgComponent,
    InfoSvgComponent,
    InternetSpiegelLogoFullSvgComponent,
    InvitationSentIllustrationSvgComponent,
    IssueIllustrationSvgComponent,
    LayoutSvgComponent,
    LevelsSvgComponent,
    LightbulbIllustrationSvgComponent,
    LightbulbSvgComponent,
    LinkSvgComponent,
    LockSvgComponent,
    LogOutSvgComponent,
    LoveSvgComponent,
    MailInvitationIllustrationSvgComponent,
    MailSvgComponent,
    MapPinSvgComponent,
    MapSvgComponent,
    MaximizeSvgComponent,
    MenuSvgComponent,
    MessageSvgComponent,
    MinimizeSvgComponent,
    MinusSvgComponent,
    MobileSvgComponent,
    MoreHorizontalSvgComponent,
    MoreVerticalSvgComponent,
    NetPromoterScoreSvgComponent,
    NoActivitiesLargeSvgComponent,
    NoQuestionLibraryLargeSvgComponent,
    NoQuestionSetsLargeSvgComponent,
    NoResponseLargeSvgComponent,
    OrangeAlertCircleSvgComponent,
    PauseSvgComponent,
    PenToolSvgComponent,
    PercentSvgComponent,
    PersonioSvgComponent,
    PieChartSvgComponent,
    PipetSvgComponent,
    PlaceholderIllustrationSvgComponent,
    PlannedSvgComponent,
    PlaySvgComponent,
    PlusMinusSvgComponent,
    PlusSvgComponent,
    PointScaleSvgComponent,
    PrinterLargeSvgComponent,
    PrivacySvgComponent,
    ProjectLargeSvgComponent,
    QuestionLibraryLargeSvgComponent,
    QuestionnaireLargeSvgComponent,
    RefreshSvgComponent,
    ReportLargeSvgComponent,
    ResponseLargeSvgComponent,
    ResponseSvgComponent,
    RotateCcwSvgComponent,
    RunningSvgComponent,
    RunningClockLargeSvgComponent,
    SearchNoResultsIllustrationSvgComponent,
    SearchNoResultsLargeSvgComponent,
    SearchSvgComponent,
    SelectMouseSvgComponent,
    SendSvgComponent,
    SettingsConfirmedIllustrationSvgComponent,
    SftpSvgComponent,
    Share1SvgComponent,
    ShareSvgComponent,
    ShopSvgComponent,
    SidebarActionsSvgComponent,
    SidebarAnalyticsSvgComponent,
    SidebarEmployeesSvgComponent,
    SidebarHomeSvgComponent,
    SidebarProjectsSvgComponent,
    SidebarSettingsSvgComponent,
    SingleAnswerSvgComponent,
    StarSvgComponent,
    SuccessfactorsSvgComponent,
    Successfactors1SvgComponent,
    TagSvgComponent,
    TextEntrySvgComponent,
    TodoIllustrationSvgComponent,
    Top3SvgComponent,
    TrashSvgComponent,
    TrendDownSvgComponent,
    TrendUpSvgComponent,
    UndoSvgComponent,
    UnlockSvgComponent,
    UploadFileLargeSvgComponent,
    UploadFileSvgComponent,
    UploadSvgComponent,
    UpVoteSvgComponent,
    UserCheckSvgComponent,
    UserMinSvgComponent,
    UserPlusSvgComponent,
    UsersSvgComponent,
    UserSvgComponent,
    WaypointSvgComponent,
    WcwpSvgComponent,
    WinSvgComponent,
    WordsSvgComponent,
    WorkInProgressComponent,
    YoutubeSvgComponent,
    ZapSvgComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ActivitySvgComponent,
    AfasSvgComponent,
    AggregationsSvgComponent,
    AlertCircleSvgComponent,
    AlertTriangleSvgComponent,
    ArchiveSvgComponent,
    ArrowDownSvgComponent,
    ArrowLeftSvgComponent,
    ArrowRightSvgComponent,
    ArrowSubSvgComponent,
    ArrowUpSvgComponent,
    BarChartAltSvgComponent,
    BarChartSvgComponent,
    BellSvgComponent,
    BenchmarkSvgComponent,
    BlueAlertCircleSvgComponent,
    BookOpenSvgComponent,
    BookmarkSvgComponent,
    BoxSvgComponent,
    BreadcrumbActionsSvgComponent,
    BreadcrumbAnalyticsSvgComponent,
    BreadcrumbEmployeesSvgComponent,
    BreadcrumbHomeSvgComponent,
    BreadcrumbProjectsSvgComponent,
    BreadcrumbSettingsSvgComponent,
    BriefcaseSvgComponent,
    BuildingSvgComponent,
    CalendarAddSvgComponent,
    CalendarRemoveSvgComponent,
    CalendarSvgComponent,
    CategorySvgComponent,
    CheckDoneSvgComponent,
    CheckSquareInvertedSvgComponent,
    CheckSquareSvgComponent,
    CheckSvgComponent,
    ChevronDownSvgComponent,
    ChevronEndSvgComponent,
    ChevronLeftSvgComponent,
    ChevronRightSvgComponent,
    ChevronStartSvgComponent,
    ChevronUpSvgComponent,
    ClipboardASvgComponent,
    ClipboardNoteSvgComponent,
    ClipboardSettingsSvgComponent,
    ClipboardSvgComponent,
    ClockSvgComponent,
    ColumnIllustrationSvgComponent,
    CompletedLargeSvgComponent,
    CookieSvgComponent,
    CopySvgComponent,
    CpuSvgComponent,
    CrossSvgComponent,
    DataIllustrationSvgComponent,
    DesktopSvgComponent,
    Download2SvgComponent,
    DownloadSvgComponent,
    DownVoteSvgComponent,
    DragDropSvgComponent,
    DurationSvgComponent,
    EditInlineSvgComponent,
    EditSvgComponent,
    EffectoryLogoFullSvgComponent,
    EffectoryLogoSvgComponent,
    EffectoryYellowSvgComponent,
    EmptyStateSvgComponent,
    EqualSvgComponent,
    ExtendedSvgComponent,
    ErrorSvgComponent,
    ExcelSvgComponent,
    ExportSvgComponent,
    ExternalLinkSvgComponent,
    EyeOffSvgComponent,
    EyeSvgComponent,
    FeaturedSvgComponent,
    FeedbackLargeSvgComponent,
    FileIllustrationSvgComponent,
    FileSvgComponent,
    FilterSvgComponent,
    FindReplaceSvgComponent,
    FlagBESvgComponent,
    FlagCHSvgComponent,
    FlagCNSvgComponent,
    FlagCZSvgComponent,
    FlagDESvgComponent,
    FlagDKSvgComponent,
    FlagEESvgComponent,
    FlagESSvgComponent,
    FlagFISvgComponent,
    FlagFRSvgComponent,
    FlagGBSvgComponent,
    FlagHRSvgComponent,
    FlagHUSvgComponent,
    FlagINSvgComponent,
    FlagITSvgComponent,
    FlagJPSvgComponent,
    FlagKRSvgComponent,
    FlagLTSvgComponent,
    FlagLVSvgComponent,
    FlagMXSvgComponent,
    FlagNLSvgComponent,
    FlagNOSvgComponent,
    FlagPLSvgComponent,
    FlagPTSvgComponent,
    FlagROSvgComponent,
    FlagRSSvgComponent,
    FlagRUSvgComponent,
    FlagSESvgComponent,
    FlagSESvgComponent,
    FlagSISvgComponent,
    FlagSKSvgComponent,
    FlagTRSvgComponent,
    FlagUASvgComponent,
    FlagUSSvgComponent,
    FolderSvgComponent,
    FolderNotesSvgComponent,
    FormCompletedLargeSvgComponent,
    GearSvgComponent,
    GiftSvgComponent,
    GlobeSvgComponent,
    GroupSvgComponent,
    HelpSvgComponent,
    HierarchySvgComponent,
    HomeSvgComponent,
    InfoSvgComponent,
    InternetSpiegelLogoFullSvgComponent,
    InvitationSentIllustrationSvgComponent,
    IssueIllustrationSvgComponent,
    LayoutSvgComponent,
    LevelsSvgComponent,
    LightbulbIllustrationSvgComponent,
    LightbulbSvgComponent,
    LinkSvgComponent,
    LockSvgComponent,
    LogOutSvgComponent,
    LoveSvgComponent,
    MailInvitationIllustrationSvgComponent,
    MailSvgComponent,
    MapPinSvgComponent,
    MapSvgComponent,
    MaximizeSvgComponent,
    MenuSvgComponent,
    MessageSvgComponent,
    MinimizeSvgComponent,
    MinusSvgComponent,
    MobileSvgComponent,
    MoreHorizontalSvgComponent,
    MoreVerticalSvgComponent,
    NetPromoterScoreSvgComponent,
    NoActivitiesLargeSvgComponent,
    NoQuestionLibraryLargeSvgComponent,
    NoQuestionSetsLargeSvgComponent,
    NoResponseLargeSvgComponent,
    OrangeAlertCircleSvgComponent,
    PauseSvgComponent,
    PenToolSvgComponent,
    PercentSvgComponent,
    PersonioSvgComponent,
    PieChartSvgComponent,
    PipetSvgComponent,
    PlaceholderIllustrationSvgComponent,
    PlannedSvgComponent,
    PlaySvgComponent,
    PlusMinusSvgComponent,
    PlusSvgComponent,
    PointScaleSvgComponent,
    PrinterLargeSvgComponent,
    PrivacySvgComponent,
    ProjectLargeSvgComponent,
    QuestionLibraryLargeSvgComponent,
    QuestionnaireLargeSvgComponent,
    RefreshSvgComponent,
    ReportLargeSvgComponent,
    ResponseLargeSvgComponent,
    ResponseSvgComponent,
    RotateCcwSvgComponent,
    RunningSvgComponent,
    RunningClockLargeSvgComponent,
    SearchNoResultsIllustrationSvgComponent,
    SearchNoResultsLargeSvgComponent,
    SearchSvgComponent,
    SelectMouseSvgComponent,
    SendSvgComponent,
    SettingsConfirmedIllustrationSvgComponent,
    SftpSvgComponent,
    Share1SvgComponent,
    ShareSvgComponent,
    ShopSvgComponent,
    SidebarActionsSvgComponent,
    SidebarAnalyticsSvgComponent,
    SidebarEmployeesSvgComponent,
    SidebarHomeSvgComponent,
    SidebarProjectsSvgComponent,
    SidebarSettingsSvgComponent,
    SingleAnswerSvgComponent,
    StarSvgComponent,
    SuccessfactorsSvgComponent,
    Successfactors1SvgComponent,
    TagSvgComponent,
    TextEntrySvgComponent,
    TodoIllustrationSvgComponent,
    Top3SvgComponent,
    TrashSvgComponent,
    TrendDownSvgComponent,
    TrendUpSvgComponent,
    UndoSvgComponent,
    UnlockSvgComponent,
    UploadFileLargeSvgComponent,
    UploadFileSvgComponent,
    UploadSvgComponent,
    UpVoteSvgComponent,
    UserCheckSvgComponent,
    UserMinSvgComponent,
    UserPlusSvgComponent,
    UsersSvgComponent,
    UserSvgComponent,
    WaypointSvgComponent,
    WcwpSvgComponent,
    WinSvgComponent,
    WordsSvgComponent,
    WorkInProgressComponent,
    YoutubeSvgComponent,
    ZapSvgComponent
  ]
})
export class SvgModule { }
