<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">System notifications</h2>
  </div>
  <div class="card-content-container">
    <p>To use the system notifications, put the following element in your <code>app.component.ts</code>. This element is
      already present in the My Effectory Shell Client and doesn't need to be imported again in child libraries.</p>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-notification>&lt;/eff-notification>
</code>
</pre>
    </details>
    <p>To then send notifications, import the <code>NotificationService</code> and call the methods</p>
    <details>
      <summary>Code</summary>
      <pre>
<code>
constructor(
  private notificationService: NotificationService) &#123; &#125;

public sendSuccessNotification() &#123;
  this.notificationService.success(
    'Success message!', 'Close', () => &#123;&#125;, 2000);
&#125;

public sendWarningNotification() &#123;
  this.notificationService.warn(
    'Warning message!');
&#125;

public sendErrorNotification() &#123;
  this.notificationService.error(
    'Error message!', 'Close', () => &#123;&#125;);
&#125;

public sendInfoNotification() &#123;
  this.notificationService.info(
    'Info message!', 'Close', () => &#123;&#125;);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="sendSuccessNotification()">Send success notification</button><br>
    <button class="primary" (click)="sendWarningNotification()">Send warning notification</button><br>
    <button class="primary" (click)="sendErrorNotification()">Send error notification</button><br>
    <button class="primary" (click)="sendInfoNotification()">Send info notification</button>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Toast notifications</h2>
  </div>
  <div class="card-content-container">
    <p>To use the toast notifications, put the following element in your <code>app.component.ts</code>. This element is already present in the My Effectory Shell Client and doesn't need to be imported again in child libraries.</p>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-notification>&lt;/eff-notification>
</code>
</pre>
    </details>
    <p>To then send notifications, import the <code>NotificationService</code> and call the methods</p>
    <details>
      <summary>Code</summary>
      <pre>
<code>
constructor(
  private notificationService: NotificationService) &#123; &#125;

public sendToastNotification() &#123;
  this.notificationService.taost(
    'Link copied to clipboard', null, null, 4000);
&#125;

public sendToastNotificationWithAction() &#123;
  this.notificationService.taost(
    'Good one! It's saved to your action plan', 'View Action Items', () => &#123; &#125;);
&#125;
</code>
</pre>
    </details>
    <button class="primary" (click)="sendToastNotification()">Send toast notification</button><br>
    <button class="primary" (click)="sendToastNotificationWithAction()">Send toast notification with action</button>
    <p>Note that the toast notifications do not have a close button. Therefore, either a timeout or an action must be
      set to close the notification. If neither is set, a default timeout of 2 seconds is applied.</p>
  </div>
</div>


<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Banner notifications</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-banner-notification
  [messageHeader]="'Header here'"
  [messageTitle]="'Message title'"
  [message]="'Test banner message'"
  [messageType]="'warn | info | success | error'"
  *ngIf="showBannerNotification">
  &lt;button class="secondary" (click)="showNotification = false">
    {{ "Dismiss label" }}
  &lt;/button>
  &lt;button class="secondary" (click)="sendSuccessNotification()">
    {{ "Button label" }} &lt;eff-svg-arrow-up class="icon rotate-90">&lt;/eff-svg-arrow-up>
  &lt;/button>
  &lt;button class="link" (click)="sendSuccessNotification()">
    {{ "Link label" }}
  &lt;/button>
  &lt;ng-template>
    &lt;eff-svg-column-illustration>&lt;/eff-svg-column-illustration>
  &lt;/ng-template>
&lt;/eff-banner-notification>
</code>
</pre>
    </details>
    <eff-banner-notification
      [messageHeader]="'Header here'"
      [messageTitle]="'Message title'"
      [message]="'Test banner message'"
      [messageType]="'info'" *ngIf="showBannerNotification">
      <button class="secondary" (click)="showBannerNotification = false">
        {{ "Dismiss label" }}
      </button>
      <button class="secondary" (click)="sendInfoNotification()">
        {{ "Button label" }} <eff-svg-arrow-up class="icon rotate-90"></eff-svg-arrow-up>
      </button>
      <button class="link" (click)="sendInfoNotification()">
        {{ "Link label" }}
      </button>
      <ng-template>
        <eff-svg-column-illustration></eff-svg-column-illustration>
      </ng-template>
    </eff-banner-notification>
    <eff-banner-notification
      [messageHeader]="'Header here'"
      [messageTitle]="'Message title'"
      [message]="'Test banner message'"
      [messageType]="'error'">
      <button class="secondary" (click)="sendErrorNotification()">
        {{ "Button label" }} <eff-svg-arrow-up class="icon rotate-90"></eff-svg-arrow-up>
      </button>
      <ng-template>
        <eff-svg-issue-illustration></eff-svg-issue-illustration>
      </ng-template>
    </eff-banner-notification>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Inline notifications</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>All properties</summary>
      <pre>
<code>
[messageTitle] | string | Message title (in bold)
[message] | string | Message body (regular text) | ignored if custom content is inserted
[messageType] | 'subtle' 'info' 'warn' 'error' 'success' | Color & Mood

[buttonMessages] | string[] | The text on the buttons (max 3)
(buttonCallback0) | EventEmitter() | Callback on button 0
(buttonCallback1) | EventEmitter() | Callback on button 1
(buttonCallback2) | EventEmitter() | Callback on button 2

[inlineButtonText] | string | The text on the inline button
(inlineButtonCallback) | EventEmitter | Callback on the inline button

[showCloseButton] | boolean | Set to true to show small close button
(closeNotification) | EventEmitter | Callback on the close button

[isOneLiner] | boolean | Puts content on one line
[dismissOnly] | boolean | Moves the button to the right

Important:

- For 'subtle' notifications, provide your own icon with the 'subtleIcon' attribute
- To add button icons, provide icons with 'buttonIcon0' etc. or with 'inlineButtonIcon'
- To insert custom content, set `isOneLiner` to false
</code>
</pre>
    </details>

    <h3 class="text-l5">Moods</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-inline-notification [messageTitle]="'This notification is subtle'" [message]="'With some extra information'" [messageType]="'subtle'"
  [inlineButtonText]="'Learn more'" (inlineButtonCallback)="sendSuccessNotification()">
  &lt;eff-svg-featured subtleIcon class="icon subtle">&lt;/eff-svg-featured>
&lt;/eff-inline-notification>

&lt;eff-inline-notification [messageTitle]="'You probably want to do something'" [message]="'Because we are warning you'" [messageType]="'warn'"
  [buttonMessages]="['Do something']" (buttonCallback0)="sendSuccessNotification()">&lt;/eff-inline-notification>

&lt;eff-inline-notification [messageTitle]="'Just a heads-up'" [message]="'This might be important information for you'"
  [messageType]="'info'" [inlineButtonText]="'Got it'" (inlineButtonCallback)="sendSuccessNotification()">
&lt;/eff-inline-notification>

&lt;eff-inline-notification [messageTitle]="'Hoorah!'" [message]="'You have completed your assignment'"
  [messageType]="'success'" [buttonMessages]="['Next assignment', 'Stop']"
  (buttonCallback0)="sendSuccessNotification()" (buttonCallback1)="sendSuccessNotification()">
  &lt;eff-svg-check buttonIcon0 class="icon">&lt;/eff-svg-check>
  &lt;eff-svg-cross buttonIcon1 class="icon">&lt;/eff-svg-cross>
&lt;/eff-inline-notification>

&lt;eff-inline-notification [messageTitle]="'You did something wrong'" [message]="'Or maybe it was us, but wrong it was'" [messageType]="'error'"
  [buttonMessages]="['Try again']" (buttonCallback0)="sendSuccessNotification()"
  [inlineButtonText]="'Learn more'" (inlineButtonCallback)="sendSuccessNotification()">&lt;/eff-inline-notification>
</code>
</pre>
    </details>
    <eff-inline-notification [messageTitle]="'This notification is subtle'" [message]="'With some extra information'" [messageType]="'subtle'"
      [inlineButtonText]="'Learn more'" (inlineButtonCallback)="sendSuccessNotification()">
      <eff-svg-featured subtleIcon class="icon subtle"></eff-svg-featured>
    </eff-inline-notification>

    <eff-inline-notification [messageTitle]="'You probably want to do something'" [message]="'Because we are warning you'" [messageType]="'warn'"
      [buttonMessages]="['Do something']" (buttonCallback0)="sendSuccessNotification()"></eff-inline-notification>

    <eff-inline-notification [messageTitle]="'Just a heads-up'" [message]="'This might be important information for you'" [messageType]="'info'" [inlineButtonText]="'Got it'" (inlineButtonCallback)="sendSuccessNotification()">
    </eff-inline-notification>

    <eff-inline-notification [messageTitle]="'Hoorah!'" [message]="'You have completed your assignment'"
      [messageType]="'success'" [buttonMessages]="['Next assignment', 'Stop']" (buttonCallback0)="sendSuccessNotification()" (buttonCallback1)="sendSuccessNotification()">
      <eff-svg-check buttonIcon0 class="icon"></eff-svg-check>
      <eff-svg-cross buttonIcon1 class="icon"></eff-svg-cross>
    </eff-inline-notification>

    <eff-inline-notification [messageTitle]="'You did something wrong'" [message]="'Or maybe it was us, but wrong it was'" [messageType]="'error'"
      [buttonMessages]="['Try again']" (buttonCallback0)="sendSuccessNotification()" [inlineButtonText]="'Learn more'" (inlineButtonCallback)="sendSuccessNotification()"></eff-inline-notification>

    <h3 class="text-l5">Closable</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-inline-notification [messageTitle]="'This is closable'"
  [message]="'Click to close button to close this'"
  [messageType]="'info'"
  [showCloseButton]="true"
  (closeNotification)="showNotification = false"
  *ngIf="showNotification"
&lt;/eff-inline-notification>
</code>
</pre>
    </details>
    <eff-inline-notification [messageTitle]="'This is closable'" [message]="'Click to close button to close this'" [messageType]="'info'"
      [showCloseButton]="true"
      (closeNotification)="showNotification = false" *ngIf="showNotification"></eff-inline-notification>

    <h3 class="text-l5">Custom content</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-inline-notification [messageTitle]="'Congratulations'" [messageType]="'success'"
  [isOneLiner]="false">
  &lt;p>You have completed the following tasks&lt;/p>
  &lt;ul>
    &lt;li>Task 1&lt;/li>
    &lt;li>Task 2&lt;/li>
    &lt;li>Task 3&lt;/li>
  &lt;/ul>
&lt;/eff-inline-notification>
</code>
</pre>
    </details>
    <eff-inline-notification [messageTitle]="'Congratulations'" [messageType]="'success'" [isOneLiner]="false" *ngIf="showNotification">
      <p>You have completed the following tasks</p>
      <ul>
        <li>Task 1</li>
        <li>Task 2</li>
        <li>Task 3</li>
      </ul>
    </eff-inline-notification>

    <h3 class="text-l5">Dismiss-only</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-inline-notification
  [dismissOnly]="true"
  [messageTitle]="'Both a title'"
  [message]="'And a message body'"
  [messageType]="'subtle'"
  [buttonMessages]="['Dismiss']"
  (buttonCallback0)="sendSuccessNotification()"
  [inlineButtonText]="'Learn more'"
  (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification"
  [showCloseButton]="true">
  &lt;eff-svg-featured subtleIcon class="icon subtle">&lt;/eff-svg-featured>
&lt;/eff-inline-notification>
</code>
</pre>
    </details>
    <eff-inline-notification [dismissOnly]="true" [messageTitle]="'Both a title'" [message]="'And a message body'" [messageType]="'subtle'" [buttonMessages]="['Dismiss']"
      (buttonCallback0)="sendSuccessNotification()" [inlineButtonText]="'Learn more'" (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification" [showCloseButton]="true">
      <eff-svg-featured subtleIcon class="icon subtle"></eff-svg-featured>
    </eff-inline-notification>

    <h3 class="text-l5">One-liner notifications</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-inline-notification
  [message]="'If you only provide a message'"
  [messageType]="'info'"
  [buttonMessages]="['Dismiss']"
  (buttonCallback0)="sendSuccessNotification()"
  [inlineButtonText]="'Learn more'"
  (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification"
  [showCloseButton]="true">
&lt;/eff-inline-notification>

&lt;eff-inline-notification
  [isOneLiner]="true"
  [messageTitle]="'Both a title'"
  [message]="'And a message body'"
  [messageType]="'subtle'"
  [buttonMessages]="['Dismiss']"
  (buttonCallback0)="sendSuccessNotification()"
  [inlineButtonText]="'Learn more'"
  (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification"
  [showCloseButton]="true">
  &lt;eff-svg-featured subtleIcon class="icon subtle">&lt;/eff-svg-featured>
&lt;/eff-inline-notification>

&lt;eff-inline-notification
  [messageTitle]="'Only a title'"
  [messageType]="'warn'"
  [buttonMessages]="['Dismiss']"
  (buttonCallback0)="sendSuccessNotification()">
&lt;/eff-inline-notification>

&lt;eff-inline-notification
  [messageTitle]="'Without a button'"
  [messageType]="'error'">
&lt;/eff-inline-notification>
</code>
</pre>
    </details>
    <eff-inline-notification [message]="'If you only provide a message'" [messageType]="'info'" [buttonMessages]="['Dismiss']"
      (buttonCallback0)="sendSuccessNotification()" [inlineButtonText]="'Learn more'" (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification" [showCloseButton]="true"></eff-inline-notification>

    <eff-inline-notification [isOneLiner]="true" [messageTitle]="'Both a title'" [message]="'And a message body'" [messageType]="'subtle'" [buttonMessages]="['Dismiss']"
      (buttonCallback0)="sendSuccessNotification()" [inlineButtonText]="'Learn more'" (inlineButtonCallback)="showDismissOnlyNotification = showDismissOnlyNotification" [showCloseButton]="true">
      <eff-svg-featured subtleIcon class="icon subtle"></eff-svg-featured>
    </eff-inline-notification>

    <eff-inline-notification [messageTitle]="'Only a title'" [messageType]="'warn'" [buttonMessages]="['Dismiss']"
      (buttonCallback0)="sendSuccessNotification()"></eff-inline-notification>

    <eff-inline-notification [messageTitle]="'Without a button'" [messageType]="'error'"></eff-inline-notification>
  </div>
</div>
