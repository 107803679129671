<div class="mat-dialog-header"></div>
<mat-dialog-content #elementRef>
  <h1 class="text-l2">{{'shell.choose-a-language' | translate }}</h1>

  <button class="close-button" (click)="close()">
    <span> {{'shell.close' | translate }}</span>
    <eff-svg-cross></eff-svg-cross>
  </button>
  <div>
    <input type="search" [(ngModel)]="searchInput" [class.with-placeholder]="searchInput !== null && searchInput !== ''" placeholder="{{'shell.search-language' | translate }}" (ngModelChange)="searchInputChanged($event)" #searchElement />
  </div>
  <div class="quick-select-languages">
    <span class="quick-select-languages-label">{{'shell.my-effectory-in-language' | translate }}</span>
    <a tabindex="0" *ngFor="let culture of quickSelectCultures; let i = index" (click)="selectLanguage(culture)">{{culture | culturename:true }}</a>
  </div>

  <div *ngIf="cultures.length === 0" class="no-results text-l6 text-w500">
    {{ 'shell.no-results' | translate}}
  </div>

  <div *ngIf="cultures.length > 0" class="table-container">
    <table class="table-list">
      <thead>
        <th scope="col">
          {{'shell.language' | translate }}
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let culture of cultures; let i = index">
          <td>
            <button type="button" (click)="selectLanguage(culture)">
              <eff-svg-check *ngIf="culture === translate.currentLang">
              </eff-svg-check>
              <span class="lang" [class.active]="culture === translate.currentLang" lang="{{culture}}">{{culture | culturename }}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination" [class.hidden]="hidePagination || cultures.length === 0">
      <span class="page-numbers text-w600 text-small">{{startNumber}}-{{startNumber+cultures.length-1}} {{'shell.of' | translate }}
        {{totalNumber}}</span>
      <eff-pagination #pagination [pageSize]="pageSize" [totalNumber]="totalNumber" [initialPage]="initialPage" (pageChanged)="setPagination($event)">
      </eff-pagination>
    </div>
  </div>

  <div class="google-translate" *ngIf="hasGoogleTranslateFT">
    <span>Or find your language here:</span>
    <div id="google_translate_element" (click)="selectEnglish()"></div>
  </div>

  <button class="primary" (click)="close()" *ngIf="hasGoogleTranslateFT">Close menu</button>

</mat-dialog-content>
