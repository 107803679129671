import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

@Component({
  selector: "eff-inline-notification",
  templateUrl: "./inline-notification.component.html",
  styleUrls: ["./inline-notification.component.scss"]
})
export class InlineNotificationComponent implements OnInit {
  // Deprecated -------------
  @Input() public linkMessages: string[] = [];
  @Output() public linkCallback0 = new EventEmitter();
  @Output() public linkCallback1 = new EventEmitter();
  @Output() public linkCallback2 = new EventEmitter();
  // -------------------------

  @Input() public messageTitle: string;
  @Input() public message: string;
  @Input() public messageType: string;

  @Input() public buttonMessages: string[] = [];
  @Output() public buttonCallback0 = new EventEmitter();
  @Output() public buttonCallback1 = new EventEmitter();
  @Output() public buttonCallback2 = new EventEmitter();

  @Input() public inlineButtonText: string;
  @Output() public inlineButtonCallback = new EventEmitter();

  @Input() public showCloseButton = false;
  @Output() public closeNotification = new EventEmitter();

  @Input() public isOneLiner = undefined;
  @Input() public dismissOnly = false;

  public get messages(): string[] {
    return this.buttonMessages.length > 0 ? this.buttonMessages : this.linkMessages;
  }

  public ngOnInit(): void {
    if (this.dismissOnly && this.messages.length > 1) {
      throw new Error("There can only be one button for a dismiss-only notification");
    }
    if (this.messages && this.messages.length > 3) {
      throw new Error("More than three buttons are not supported");
    }
    this.isOneLiner ??= !(this.messageTitle?.length > 0) || !(this.message?.length > 0);
    if (this.isOneLiner && this.messages.length > 1) {
      throw new Error("There can only be one button for a one-liner notification");
    }
  }

  public executeCallback(index = 0): void {
    if (index > 2) {
      throw new Error("More than three buttons are not supported");
    }

    this["linkCallback" + index].emit();
    this["buttonCallback" + index].emit();
  }
}
