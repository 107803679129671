<div [class.menu-open]="mobileSidebarService.sidebarExpanded" class="styleguide-grid-container">
  <header>
    <button class="toggle-sidebar" (click)="mobileSidebarService.sidebarExpanded = !mobileSidebarService.sidebarExpanded">
      <eff-svg-menu class="menu-icon"></eff-svg-menu>
    </button>
    <eff-svg-logo class="effectory-logo"></eff-svg-logo>
    <h1 class="text-l5">Effectory Styleguide</h1>
    <ul class="top-navigation">
      <ng-container *ngFor="let route of router.config">
        <li *ngIf="route.data?.showInTopbar">
          <a class="text-subdued text-w600" [routerLink]="route.path" routerLinkActive="active">
            <span>{{ route.data?.name }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </header>
  <section class="main" role="main">
    <div class="menu-overlay" (click)="mobileSidebarService.sidebarExpanded = false"></div>
    <router-outlet></router-outlet>
  </section>
  <eff-notification></eff-notification>
</div>
