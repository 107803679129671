import { TourTooltipComponent } from "./../../../projects/branding-angular/src/lib/components/tooltip-dialog/tooltip-dialog.component";
import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-tooltips",
  templateUrl: "./tooltips.component.html",
  styleUrls: ["./tooltips.component.scss"]
})
export class TooltipsComponent {
  @ViewChild("yourTargetElement") public targetButton: ElementRef;
  @ViewChild("yourSvgTargetElement") public svgTargetButton: ElementRef;
  @ViewChild("tooltipTargetOne") public targetOne: ElementRef;
  @ViewChild("tooltipTargetTwo") public targetTwo: ElementRef;
  @ViewChild("tooltipTargetThree") public targetThree: ElementRef;
  @ViewChild("tooltipWithCallToAction") public tourTooltipWithCallToAction: TourTooltipComponent;

  public showTooltipDialog = false;
  public showSvgTooltipDialog = false;
  public showTooltipDialogWithProgressPartOne = false;
  public showTooltipDialogWithProgressPartTwo = false;
  public showTooltipDialogWithProgressPartThree = false;
}
