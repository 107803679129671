<app-navigation></app-navigation>
<div class="card welcome-tile" *ngIf="isHome">
  <img class="home-illustration-mobile" src="assets/img/home-illustration-mobile.svg" />
  <div class="welcome-content">
    <h1 class="text-l1 text-w600">Welcome!</h1>
    <span class="welcome-subtitle text-subdued">
      This is the styleguide of Effectory. This website will show you everything about the style of our applications.
      This guide contains colors, breakpoints, icons and lots of components. Everything here is maintained by the design
      team in collaboration with the Branding team (Jesse &amp; Pavol)
    </span>
  </div>
  <img class="home-illustration" src="assets/img/home-illustration.svg" />
</div>
<router-outlet></router-outlet>
