<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Buttons</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;button class="primary">Primary button&lt;/button>

&lt;button class="primary loading">Loading button&lt;/button>

&lt;button class="secondary">Secondary button&lt;/button>

&lt;button class="primary icon-before">
  &lt;eff-svg-eye class="icon">&lt;/eff-svg-eye>
  Icon before
&lt;/button>

&lt;button class="secondary icon-after">
  Icon after
  &lt;eff-svg-eye class="icon">&lt;/eff-svg-eye>
&lt;/button>

&lt;button class="link">Link button&lt;/button>

&lt;button class="secondary delete">Delete button&lt;/button>

&lt;button class="link delete">Link delete button&lt;/button>

&lt;button class="primary" disabled="true">
  Disabled primary button
&lt;/button>

&lt;button class="secondary" disabled="true">
  Disabled secondary button
&lt;/button>

&lt;button class="link" disabled="true">
  Disabled link button
&lt;/button>
</code>
</pre>
    </details>
    <p>
      <button class="primary">Primary button</button>
    </p>
    <p>
      <button class="primary loading">Loading button</button>
    </p>
    <p>
      <button class="secondary">Secondary button</button>
    </p>
    <p>
      <button class="primary icon-before">
        <eff-svg-eye class="icon"></eff-svg-eye>
        Icon before
      </button>
    </p>
    <p>
      <button class="secondary icon-after">
        Icon after
        <eff-svg-eye class="icon"></eff-svg-eye>
      </button>
    </p>
    <p>
      <button class="link">Link button</button>
    </p>
    <p>
      <button class="primary delete">Delete button</button>
    </p>
    <p>
      <button class="secondary delete">Secondary delete button</button>
    </p>
    <p>
      <button class="link delete">Link delete button</button>
    </p>
    <p>
      <button class="primary" disabled="true">Disabled primary button</button>
    </p>
    <p>
      <button class="secondary" disabled="true">Disabled secondary button</button>
    </p>
    <p>
      <button class="link" disabled="true">Disabled link button</button>
    </p>
  </div>
</div>
