import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-dropdowns",
  templateUrl: "./dropdowns.component.html",
  styleUrls: ["./dropdowns.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DropdownsComponent implements OnInit {

  public ngOnInit() {
  }

}
