<div class="color-overview">
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Primary colors</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="primaryColors">
        <ng-container matColumnDef="Color">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let row">
            <div class="color-value" [ngStyle]="{'background-color': row.properties[1]}"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Variable name">
          <th mat-header-cell *matHeaderCellDef>Variable name</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="Hex code">
          <th mat-header-cell *matHeaderCellDef>Hex code</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[1]}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Neutral colors</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="neutralColors">
        <ng-container matColumnDef="Color">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let row">
            <div class="color-value" [ngStyle]="{'background-color': row.properties[1]}"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Variable name">
          <th mat-header-cell *matHeaderCellDef>Variable name</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="Hex code">
          <th mat-header-cell *matHeaderCellDef>Hex code</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[1]}}</td>
        </ng-container>

        <ng-container matColumnDef="Usage">
          <th mat-header-cell *matHeaderCellDef>Usage</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[2]}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="neutralColorHeaders"></tr>
        <tr mat-row *matRowDef="let row; columns: neutralColorHeaders; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="card-title text-l5">Status colors</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="statusColors">
        <ng-container matColumnDef="Color">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let row">
            <div class="color-value" [ngStyle]="{'background-color': row.properties[1]}"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Variable name">
          <th mat-header-cell *matHeaderCellDef>Variable name</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="Hex code">
          <th mat-header-cell *matHeaderCellDef>Hex code</th>
          <td mat-cell *matCellDef="let row">{{ row.properties[1]}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

</div>
