import {Component, Input} from "@angular/core";

@Component({
  selector: "eff-addon-tag",
  templateUrl: "./addon-tag.component.html",
  styleUrls: ["./addon-tag.component.scss"]
})
export class AddonTagComponent {
  @Input() public enabled: boolean = false;
  @Input() public showTooltip: boolean = false;
  @Input() public tooltipText: string;
}
