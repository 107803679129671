import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { debounceTime, pairwise } from "rxjs/operators";

@Component({
  selector: "eff-timepicker",
  templateUrl: "./timepicker.component.html"
})
export class TimepickerComponent implements OnInit, OnDestroy {
  @ViewChild("inputHours", {static: true}) inputHours: ElementRef<HTMLInputElement>;
  @ViewChild("inputMinutes", {static: true}) inputMinutes: ElementRef<HTMLInputElement>;
  @Input() public showIncreaseByPeriodButtons = true;
  @Input() public disabled = false;
  @Input() public hour: BehaviorSubject<number> = new BehaviorSubject(null);
  @Input() public minute: BehaviorSubject<number> = new BehaviorSubject(null);
  @Input() public hoursMinTreshold = 0;
  @Input() public hoursMaxTreshold = 23;
  @Input() public minutesMinTreshold = 0;
  @Input() public minutesMaxTreshold = 59;
  @Input() public debounceTime = 0;
  @Output() public timeUpdated = new EventEmitter<[number, number]>();
  public hoursForm = new UntypedFormControl();
  public minutesForm = new UntypedFormControl();
  private minutesMax = 59;
  private minutesMin = 0;
  private minutesPeriod = 15;
  private changeSelectionFocusFromHoursToMinutesInput = true;
  private subscriptions: Subscription[] = [];
  private emitTimesSubject = new Subject<void>();

  public ngOnInit(): void {
    this.hoursForm.valueChanges.pipe(pairwise()).subscribe(([prevValue, currentValue]: [string, string]) => {
      if (prevValue !== currentValue) {
        const onlyNumbers = this.removeLettersFromString(currentValue);
        this.hoursForm.setValue(onlyNumbers);

        if (this.changeSelectionFocusFromHoursToMinutesInput && onlyNumbers.length === 2) {
          this.setMinutesSelectionRange();
        }

        this.changeSelectionFocusFromHoursToMinutesInput = true;
      }
    });

    this.minutesForm.valueChanges.pipe(pairwise()).subscribe(([prevValue, currentValue]: [string, string]) => {
      if (prevValue !== currentValue) {
        const onlyNumbers = this.removeLettersFromString(currentValue);
        this.minutesForm.setValue(onlyNumbers);
      }
    });

    this.subscriptions.push(this.hour.subscribe((hour: number)=> {
      if (hour !== null) {
        this.padNumber(String(hour), true);
      }
    }));

    this.subscriptions.push(this.minute.subscribe((minute: number) => {
      if (minute !== null) {
        this.padNumber(String(minute), false);
      }
    }));

    this.subscriptions.push(this.emitTimesSubject.pipe(debounceTime(this.debounceTime)).subscribe(() => {
      this.timeUpdated.emit([Number(this.hoursForm.value), Number(this.minutesForm.value)]);
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  public changeHour(increase: boolean): void {
    const changeToHour = increase ? Number(this.hoursForm.value) + 1 : Number(this.hoursForm.value) - 1;

    if (changeToHour <= this.hoursMaxTreshold && changeToHour >= this.hoursMinTreshold && changeToHour !== -1) {
      this.padNumber(String(changeToHour), true);
    }

    if (changeToHour > this.hoursMaxTreshold) {
      this.padNumber(String(this.hoursMinTreshold), true);
    }

    if (changeToHour < this.hoursMinTreshold) {
      this.padNumber(String(this.hoursMaxTreshold), true);
    }

    if (changeToHour === -1) {
      this.padNumber(String(this.hoursMaxTreshold), true);
    }
  }

  public increaseMinutesOnKeyUp(): void {
    const increasedMinutesValue = Number(this.minutesForm.value) + 1;
    const setMinutesTo = this.increaseMinutesHelper(increasedMinutesValue);

    this.padNumber(String(setMinutesTo), false);

    if (!this.hoursForm.value) {
      this.padNumber(String(this.hoursMinTreshold), true);
    }

    this.emitTimesSubject.next();
  }

  public increaseMinutesByPeriod(): void {
    const minutesPeriodPortion = Math.floor(this.minutesForm.value / this.minutesPeriod);
    const minutesInPeriodPortion = (minutesPeriodPortion + 1) * this.minutesPeriod;
    const setMinutesTo = this.increaseMinutesHelper(minutesInPeriodPortion);

    this.padNumber(String(setMinutesTo), false);

    if (!this.hoursForm.value) {
      this.padNumber(String(this.hoursMinTreshold), true);
    }

    this.emitTimesSubject.next();
  }

  public decreaseMinutesOnKeyDown(): void {
    const changeToMinutes = Number(this.minutesForm.value) - 1;
    const withinMinHrTreshold = this.withinTheMinHourTreshold(this.hoursForm.value);
    // * DO NOT CARE ABOUT THE MINUTES TRESHOLD
    if (withinMinHrTreshold) {
      if (changeToMinutes < this.minutesMin) {
        this.padNumber(String(this.minutesMax), false);
        this.changeHour(false);

        this.emitTimesSubject.next();
        return;
      }
    }
    // * TAKE THE MINUTES TRESHOLD INTO CONSIDERATION
    else {
      if (changeToMinutes < this.minutesMinTreshold) {
        this.padNumber(String(this.minutesMaxTreshold), false);
        this.changeHour(false);

        this.emitTimesSubject.next();
        return;
      }
    }

    this.padNumber(String(changeToMinutes), false);
    this.emitTimesSubject.next();
  }

  public decreaseMinutesByPeriod(): void {
    const minutesPeriodParts = Math.ceil(this.minutesForm.value / this.minutesPeriod);
    const withinMinHrTreshold = this.withinTheMinHourTreshold(this.hoursForm.value);
    const calculateNewMinutePeriod = (minutesPeriodParts - 1) * this.minutesPeriod;

    // * DO NOT CARE ABOUT THE MINUTES TRESHOLD
    if (withinMinHrTreshold) {
      if (calculateNewMinutePeriod < this.minutesMin) {
        if (calculateNewMinutePeriod < 0) {
          this.padNumber(String(60 - this.minutesPeriod), false);
        } else {
          this.padNumber(String(this.minutesMax), false);
        }
        this.changeHour(false);

        this.emitTimesSubject.next();
        return;
      }
    }
    // * TAKE THE MINUTES TRESHOLD INTO CONSIDERATION
    else {
      const periodPart = Math.floor(this.minutesMaxTreshold / this.minutesPeriod) * this.minutesPeriod;
      if (calculateNewMinutePeriod < this.minutesMinTreshold) {
        this.padNumber(String(periodPart), false);
        this.changeHour(false);

        this.emitTimesSubject.next();
        return;
      }
    }

    this.padNumber(String(calculateNewMinutePeriod), false);

    this.emitTimesSubject.next();
  }

  public onBlurHour(time: string): void {
    const timeInNumber = Number(time);

    if (timeInNumber === this.hoursMaxTreshold && this.minutesMaxTreshold < this.hoursForm.value) {
      this.padNumber(String(this.minutesMaxTreshold), false);
    }

    if (timeInNumber === this.hoursMinTreshold && this.minutesMinTreshold > this.hoursForm.value) {
      this.padNumber(String(this.minutesMinTreshold), false);
    }

    if (timeInNumber <= this.hoursMaxTreshold && timeInNumber >= this.hoursMinTreshold) {
      this.padNumber(String(timeInNumber), true);
    }

    if (timeInNumber > this.hoursMaxTreshold) {
      this.padNumber(String(this.hoursMaxTreshold), true);
    }

    if (timeInNumber < this.hoursMinTreshold) {
      this.padNumber(String(this.hoursMinTreshold), true);
    }

    this.emitTimesSubject.next();
  }

  public onBlurMinutes(time: string): void {
    const timeInNumber = Number(time);
    let minutesMaxTreshold = this.minutesMax;
    let minutesMinTreshold = this.minutesMin;

    if (!this.withinTheMinHourTreshold(this.hoursForm.value)) {
      minutesMinTreshold = this.minutesMinTreshold;
    }
    if (!this.withinTheMaxHourTreshold(this.hoursForm.value)) {
      minutesMaxTreshold = this.minutesMaxTreshold;
    }

    if (timeInNumber <= minutesMaxTreshold && timeInNumber >= minutesMinTreshold) {
      this.padNumber(String(timeInNumber), false);
    }

    if (timeInNumber > minutesMaxTreshold) {
      this.padNumber(String(minutesMaxTreshold), false);
    }

    if (timeInNumber < minutesMinTreshold) {
      this.padNumber(String(minutesMinTreshold), false);
    }

    this.emitTimesSubject.next();
  }

  public padNumber(time: string, isHours: boolean): void {
    const paddedTime = `${Number(time) > 9 || time === "00" ? "" : "0"}${time}`;
    // * We want to change the selection focus only when user inserts the input NOT on keydown or increase/decrease button
    this.changeSelectionFocusFromHoursToMinutesInput = false;

    if (isHours) {
      this.hoursForm.setValue(paddedTime);
    } else {
      this.minutesForm.setValue(paddedTime);
    }
  }

  public setMinutesSelectionRange(): void {
    this.inputMinutes.nativeElement.select();
  }

  public setHoursSelectionRange(): void {
    this.inputHours.nativeElement.select();
  }

  private increaseMinutesHelper(minutes: number): number {
    const withinMaxHrTreshold = this.withinTheMaxHourTreshold(this.hoursForm.value);

    // * DO NOT CARE ABOUT THE MINUTES MAX TRESHOLD
    if (withinMaxHrTreshold) {
      if (minutes > this.minutesMax) {
        this.changeHour(true);
        return this.minutesMin;
      }
    }
    // * TAKE THE MINUTES TRESHOLD INTO CONSIDERATION
    else {
      if (minutes > this.minutesMaxTreshold) {
        this.changeHour(true);
        return this.minutesMinTreshold;
      }
    }

    return minutes;
  }

  private removeLettersFromString(input: string): string {
    return [...input].filter((letter) => this.isNumber(letter)).join("");
  }

  private withinTheMinHourTreshold(time: number): boolean {
    return this.hoursMinTreshold < time;
  }

  private withinTheMaxHourTreshold(time: number): boolean {
    return this.hoursMaxTreshold > time;
  }

  private isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  private toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
}
