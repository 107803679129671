import { CdkVirtualScrollViewport, ScrollDispatcher } from "@angular/cdk/scrolling";
import { AfterViewInit, ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { filter } from "rxjs";
import { SelectComponent } from "./select.component";

@Component({
  selector: "eff-select-virtual",
  templateUrl: "./select-virtual.component.html",
  styleUrls: ["./select-virtual.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectVirtualComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectVirtualComponent,
      multi: true
    }
  ]
})

export class SelectVirtualComponent extends SelectComponent implements AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  @ViewChildren(MatOption) matoptions: QueryList<MatOption>;

  constructor(private changeDetector: ChangeDetectorRef, readonly scrollDispatcher: ScrollDispatcher) {
    super();
  }

  public ngAfterViewInit(): void {
    this.scrollDispatcher
      .scrolled()
      .pipe(filter(scrollable => this.cdkVirtualScrollViewPort === scrollable))
      .subscribe(() => {
        let needUpdate = false;

        if (this.selectedValues) {
          this.matoptions.forEach(option => {
            const selected = this.selectedValues.includes(option.value);

            if (selected && !option.selected) {
              option.select();
              needUpdate = true;
            } else if (!selected && option.selected) {
              option.deselect();
              needUpdate = true;
            }
          });
        }

        if (needUpdate) {
          this.changeDetector.detectChanges();
        }
      });
  }

  public override onOpened(): void {
    super.onOpened();

    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }
}
