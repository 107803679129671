<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Video Player</h2>
  </div>
  <div class="card-content-container">
    <h4>Sample Video</h4>
    <div class="video-demo-container">
      <eff-video-player
        [srcVideoOptions]="sampleVideo"
        (videoPlayed)="pushEvent({name: 'videoPlayed', data: $event})"
        (videoPaused)="pushEvent({name: 'videoPaused', data: $event})"
        (videoEnded)="pushEvent({name: 'videoEnded', data: $event})"
        (videoResumed)="pushEvent({name: 'videoResumed', data: $event})"
        (videoSeeked)="pushEvent({name: 'videoSeeked', data: $event})"
        (videoImageError)="pushEvent({name: 'videoImageError', data: $event})"
        (browserNotSupported)="pushEvent({name: 'browserNotSupported', data: $event})"
        (subtitleChanged)="pushEvent({name: 'subtitleChanged', data: $event})"
        (playbackError)="pushEvent({name: 'playbackError', data: $event})"
        (userFeedback)="pushEvent({name:'userFeedback', data: $event})"
        showFeedback="true"
        fullScreenOnMobile="true"
        showPictureInPictureButton="false"
        showSubtitleButton="true"
        showVideoQualityButton="true"
      ></eff-video-player>
    </div>
    <h4>Raised events for sample video:</h4>
    <ul>
      <li *ngFor="let event of raisedEvents">
        {{event.name}}
        <details>{{ event.data }}</details>
      </li>
    </ul>
    <h4>Code</h4>
    <details>
      <pre>
<code>
      &lt;eff-video-player
        [srcVideoOptions]=&quot;sampleVideo&quot;
        (videoPlayed)=&quot;pushEvent($event)&quot;
        (videoPaused)=&quot;pushEvent($event)&quot;
        (videoEnded)=&quot;pushEvent($event)&quot;
        (videoResumed)=&quot;pushEvent($event)&quot;
        (videoSeeked)=&quot;pushEvent($event)&quot;
        (videoImageError)=&quot;pushEvent($event)&quot;
        (browserNotSupported)=&quot;pushEvent($event)&quot;
        (subtitleChanged)=&quot;pushEvent($event)&quot;
        (playbackError)=&quot;pushEvent($event)&quot;
        (userFeedback)=&quot;pushEvent($event)&quot;
        showFeedback=&quot;true&quot;
        fullScreenOnMobile=&quot;true&quot;
        showPictureInPictureButton=&quot;false&quot;
        showSubtitleButton=&quot;true&quot;
        showVideoQualityButton=&quot;true&quot;
      &gt;&lt;/eff-video-player&gt;
</code>
      </pre>
    </details>
    <h4>Properties</h4>
    <table class="mat-table cdk-table">
      <thead>
        <tr>
          <th class="mat-header-cell cdk-header-cell">Property</th>
          <th class="mat-header-cell cdk-header-cell">Type</th>
          <th class="mat-header-cell cdk-header-cell">Default</th>
          <th class="mat-header-cell cdk-header-cell">Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">srcVideoOptions</td>
          <td class="mat-cell cdk-cell">object/complex</td>
          <td class="mat-cell cdk-cell"></td>
          <td class="mat-cell cdk-cell">Player use this property to load posters and the video</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">showFeedback</td>
          <td class="mat-cell cdk-cell">boolean</td>
          <td class="mat-cell cdk-cell"><code>false</code></td>
          <td class="mat-cell cdk-cell">When video playback reached the end will prompt a like/dislike prompt</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">fullScreenOnMobile</td>
          <td class="mat-cell cdk-cell">boolean</td>
          <td class="mat-cell cdk-cell"><code>true</code></td>
          <td class="mat-cell cdk-cell">On smaller screens, player will play videos in fullscreen</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">showPictureInPictureButton</td>
          <td class="mat-cell cdk-cell">boolean</td>
          <td class="mat-cell cdk-cell"><code>false</code></td>
          <td class="mat-cell cdk-cell"></td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">showSubtitleButton</td>
          <td class="mat-cell cdk-cell">boolean</td>
          <td class="mat-cell cdk-cell"><code>true</code></td>
          <td class="mat-cell cdk-cell"></td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell cdk-cell">showVideoQualityButton</td>
          <td class="mat-cell cdk-cell">boolean</td>
          <td class="mat-cell cdk-cell"><code>true</code></td>
          <td class="mat-cell cdk-cell"></td>
        </tr>
      </tbody>
    </table>

    <h4>Src Video Options</h4>
    <pre>
VideoOptions >
    dashUrl: string;
    posterBasePath: string;
    name: string;
    id: string;
    </pre>
    <ul>
      <li>dashUrl(string): Full http/https address of the dash manifest (mpd) file. e.g:
        https://example.com/video1/manifest.mpd
      </li>
      <li>poseterBasePath(string): http/https address of dir posters, by convention it tries to show them (responsive
        and lazy). The sizes are [360, 768, 1200]. These files 360.jpg, 768.jpg and 1200.jpg need to be in the video
        directory. e.g: https://example.com/video1
      </li>
      <li>name(string): name of the video, usually is the same as the video directory</li>
      <li>id(string): id of the video, any string is acceptable, it's for logging usage.</li>
    </ul>

    <h4>Notes:</h4>
    <ul>
      <li>You don't need to set all properties like the code above, by default subtitles, quality and fullScreen on
        mobile are enabled.
      </li>
      <li>This player supports every standard dash manifest format with one only exception, all paths in the manifest
        need to be full and absolute path, e.g. https://example.com/videos/manifest.mpd
      </li>
      <li>This component installs some polyfills on demand/on the fly (if they are needed by the browser), with that
        Shaka Player will make sure if everything like full-screen and other capabilites works fine on every browser
      </li>
    </ul>
  </div>
</div>

