import { Component } from "@angular/core";

@Component({
  selector: "app-radio-buttons",
  templateUrl: "./radio-buttons.component.html",
  styleUrls: ["./radio-buttons.component.scss"]
})
export class RadioButtonsComponent {
  public value: number;
}
