import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, combineLatestWith, debounce, distinctUntilChanged, interval, map, Observable } from "rxjs";

const DEBOUNCE_INTERVAL = 350;

@Component({
  selector: "eff-search-box",
  templateUrl: "./searchbox.component.html",
  styleUrls: ["./searchbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent {
  @Output() public searchTerm = new EventEmitter<string>();
  @Output() public index: Observable<number>;

  @Input() public placeholder: string;

  public isNextAllowed$: Observable<boolean>;
  public isPrevAllowed$: Observable<boolean>;
  public currentIndex$: Observable<number>;
  public readonly search = new FormControl("");

  public currentSearchSubject = "";

  private indexSubject = new BehaviorSubject<number>(0);
  private totalItemsSubject = new BehaviorSubject(0);

  constructor() {
    this.index = this.indexSubject.asObservable();
    this.isNextAllowed$ = this.indexSubject.pipe(
      combineLatestWith(this.totalItemsSubject),
      map( ([index, total]) => index < total - 1)
    );
    this.isPrevAllowed$ = this.indexSubject.pipe(map(index => index > 0));

    this.currentIndex$ = this.indexSubject.pipe(
      combineLatestWith(this.totalItemsSubject),
      map(([index, total]) => total > 0 ? index + 1 : 0)
      );

    this.search.valueChanges
      .pipe(
        debounce(() => interval(DEBOUNCE_INTERVAL)),
        distinctUntilChanged()
      )
      .subscribe((inputValue) => {
        this.indexSubject.next(0);
        this.currentSearchSubject = inputValue;
        this.searchTerm.emit(inputValue);
      });
  }

  public get totalItems(): number {
    return this.totalItemsSubject.value;
  }

  @Input() public set totalItems(value: number) {
    this.totalItemsSubject.next(value);
  }

  public nextItem(): void {
    this.indexSubject.next(this.indexSubject.value + 1);
  }

  public prevItem(): void {
    if (this.indexSubject.value > 0) {
      this.indexSubject.next(this.indexSubject.value - 1);
    }
  }

  public clear(): void {
    this.search.setValue("");
  }
}
