import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-design-preview",
  templateUrl: "./design-preview.component.html",
  styleUrls: ["./design-preview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DesignPreviewComponent {
  public patternUrl =
    "https://questionnairedesign.blob.core.windows.net/questionnairedesign-images/ffcce9e43aefa47c7f4e93fefc2be5404cb54c2d2bff84497cd361db0182694c_pattern";
  public backgroundUrl =
    "https://questionnairedesign.blob.core.windows.net/questionnairedesign-images/ffcce9e43aefa47c7f4e93fefc2be5404cb54c2d2bff84497cd361db0182694c_background";
  public logoUrl =
    "https://questionnairedesign.blob.core.windows.net/questionnairedesign-images/ffcce9e43aefa47c7f4e93fefc2be5404cb54c2d2bff84497cd361db0182694c_logo";
  public backgroundMailUrl = "/assets/img/linea_mail_bg.jpeg";
  public primaryColor = "30B3AF";
  public backgroundColor = "30B3AF";
}
