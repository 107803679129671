import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SvgModule } from "@effectory/branding-angular/shared";
import { TimepickerComponent } from "./timepicker.component";

@NgModule({
  declarations: [
    TimepickerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SvgModule
  ],
  exports: [
    TimepickerComponent
  ]
})
export class TimepickerModule {}
