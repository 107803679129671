import { AddonTagComponent } from "./../components/addon-tag/addon-tag.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { CheckboxComponent } from "../components/checkbox/checkbox.component";
import { TooltipDirective } from "../directives/tooltip.directive";
import { SvgModule, LoaderModule } from "@effectory/branding-angular/shared";
import { BetaTagComponent } from "../components/beta-tag/beta-tag.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FloatBarComponent } from "../components/float-bar/float-bar.component";
import { SearchBoxComponent } from "../components/searchbox/searchbox.component";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
  declarations: [
    CheckboxComponent,
    BetaTagComponent,
    AddonTagComponent,
    TooltipDirective,
    FloatBarComponent,
    SearchBoxComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SvgModule,
    LoaderModule,
    TranslateModule,
    MatTooltipModule,
    ScrollingModule
  ],
  exports: [
    CheckboxComponent,
    TooltipDirective,
    BetaTagComponent,
    FloatBarComponent,
    SearchBoxComponent,
    AddonTagComponent
  ]
})
export class ControlsModule { }
