import { Component } from "@angular/core";

@Component({
  selector: "app-steppers",
  templateUrl: "./steppers.component.html",
  styleUrls: ["./steppers.component.scss"]
})
export class SteppersComponent {
  public orientation = "horizontal";
}
