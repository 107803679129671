<div class="design-container" [class.column-mode]="previewDesktopInColumnMode" *ngIf="!mobileVersion; else mobile">
  <div class="screen-container introduction">
    <div class="design-header-tile tag">
      <span>{{ "shell.introduction" | translate }}</span>
    </div>
    <div class="screen-wrapper">
      <div class="autoscaler"></div>
      <div class="screen">
        <div class="screen-header">
          <div class="logo" [ngStyle]="getLogo()"></div>
          <div class="dummy-title"></div>
        </div>
        <div class="background-layer" [ngStyle]="getBackgroundImage()">
          <div class="screen-content">
            <div class="top-overlay" [ngStyle]="getPrimaryColor()"></div>
            <div class="dummy-name"></div>
            <div class="dummy-name-subtitle"></div>
            <div class="dummy-icons-container">
              <div *ngFor="let i of [0, 1, 2]" class="dummy-icon-wrapper">
                <div class="dummy-icon circle"></div>
                <div class="dummy-icon-subtitle"></div>
                <div class="dummy-icon-subtitle"></div>
              </div>
            </div>
            <div class="dummy-button" [ngStyle]="getPrimaryColor()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="screen-container questions">
    <div class="design-header-tile tag">
      <span>{{ "shell.questions" | translate }}</span>
    </div>
    <div class="screen-wrapper">
      <div class="autoscaler"></div>
      <div class="screen">
        <div class="screen-header">
          <div class="logo" [ngStyle]="getLogo()"></div>
          <div class="dummy-title"></div>
        </div>
        <div class="background-layer" [ngStyle]="getBackgroundPattern()">
          <div class="screen-content">
            <div class="top-overlay" [ngStyle]="getPrimaryColor()"></div>
            <div class="dummy-name"></div>
            <div class="dummy-name-subtitle"></div>
            <div class="dummy-question">
              <div class="dummy-question-title"></div>
              <div class="dummy-answers">
                <div *ngFor="let i of [0, 1, 2, 3, 4]" class="dummy-answer-wrapper">
                  <div class="dummy-answer"></div>
                </div>
              </div>
              <div class="dummy-answers inner">
                <div *ngFor="let answerNumber of ['first', 'second', 'third', 'fourth', 'fifth']" class="dummy-answer-wrapper">
                  <div class="dummy-answer inner" [ngClass]="answerNumber"></div>
                </div>
              </div>
              <div class="dummy-no-answer-button"></div>
            </div>
            <div class="dummy-button-questions" [ngStyle]="getPrimaryColor()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE VERSION -->
<ng-template #mobile>
  <div class="design-container-m">
    <div class="tag design-header-tile">
      <span>{{ "shell.introduction" | translate }}</span>
    </div>
    <div class="introduction-m">
      <div class="introduction-m-content">
        <div class="header-m">
          <div class="dummy-title-m"></div>
          <div class="burger-container">
            <div class="burger-single"></div>
            <div class="burger-single"></div>
            <div class="burger-single"></div>
          </div>
        </div>
        <div class="body-m" [ngStyle]="getBackgroundImageAndPosition()">
          <div class="body-overlay-m">
            <div class="overlay-m" [ngStyle]="getPrimaryColor()"></div>
            <div class="title-m"></div>
            <div class="subtitle-m"></div>

            <div *ngFor="let i of [0, 1, 2]" class="icon-container">
              <div class="circle"></div>
              <div class="text"></div>
            </div>
          </div>
        </div>
        <div class="footer-m" [ngStyle]="getPrimaryColor()"></div>
      </div>
    </div>
    <div *ngIf="showRepositionBtn" class="reposition-btn-container">
      <button class="reposition-btn secondary" (click)="repositionImage()">{{ "shell.reposition-image" | translate }}</button>
    </div>
    <div class="tag design-header-tile">
      <span>{{ "shell.questions" | translate }}</span>
    </div>
    <div class="questions-m">
      <div class="questions-m-content">
        <div class="header-m">
          <div class="dummy-title-m"></div>
          <div class="burger-container">
            <div class="burger-single"></div>
            <div class="burger-single"></div>
            <div class="burger-single"></div>
          </div>
        </div>
        <div class="body-m pattern" [ngStyle]="getBackgroundPattern()">
          <div class="body-overlay-m">
            <div class="overlay-m" [ngStyle]="getPrimaryColor()"></div>
            <div class="title-m"></div>
            <div class="question-m">
              <div class="question-title-m"></div>
              <div class="option-container-m">
                <div *ngFor="let answerNumber of ['first', 'second', 'third', 'fourth', 'fifth']" class="single-option-m">
                  <div class="single-option-inner" [ngClass]="answerNumber"></div>
                </div>
              </div>
              <div class="question-button-m"></div>
            </div>
            <div class="submit-btn-m" [ngStyle]="getPrimaryColor()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
