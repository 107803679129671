import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule} from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import {VideoPlayerComponent} from "./video-player/video-player.component";
import {VideoFeedbackComponent} from "./video-feedback/video-feedback.component";
import {CultureService} from "./services/culture.service";
import {SvgModule} from "@effectory/branding-angular/shared";
import {ShakaWrapperUtility} from "./services/shaka-wrapper-utility.service";


@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoFeedbackComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTooltipModule,
    SvgModule
  ],
  providers: [
    CultureService,
    ShakaWrapperUtility
  ],
  exports: [
    VideoPlayerComponent
  ]
})
export class VideoModule { }
