import { Component } from "@angular/core";

@Component({
  selector: "app-accordions",
  templateUrl: "./accordions.component.html",
  styleUrls: ["./accordions.component.scss"]
})
export class AccordionsComponent {
  public displayedColumns = ["name", "function", "location", "gender"];

  public rows = [
    {
      properties: [
        "Jesse",
        "Boss",
        "Koog aan de Zaan",
        "Male"
      ]
    },
    {
      properties: [
        "Ramon",
        "Chief of Front-End",
        "Hoorn",
        "Male"
      ]
    },
    {
      properties: [
        "Roy",
        "Developer",
        "Almere",
        "Male"
      ]
    }
  ];
}
