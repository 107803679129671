<div class="search-container">
  <div class="input-wrapper">
    <input
      [formControl]="search"
      [placeholder]="placeholder"
      [value]="currentSearchSubject"
      id="search"
      type="search"
    />
    <div *ngIf="currentSearchSubject !== ''" class="buttons-wrapper">
      <span class="text-small text-w400">
        {{ currentIndex$ | async }} / {{ totalItems }}
      </span>
      <button
        (click)="prevItem()"
        [attr.aria-label]="'question-management.prev-item' | translate"
        [disabled]="(isPrevAllowed$ | async) === false"
        class="icon-wrapper"
      >
        <eff-svg-chevron-up class="icon-20"></eff-svg-chevron-up>
      </button>
      <button
        (click)="nextItem()"
        [attr.aria-label]="'question-management.next-item' | translate"
        [disabled]="(isNextAllowed$ | async) === false"
        class="icon-wrapper"
      >
        <eff-svg-chevron-down class="icon-20"></eff-svg-chevron-down>
      </button>
      <button
        (click)="clear()"
        [attr.aria-label]="'question-management.clear-search' | translate"
        class="icon-wrapper"
      >
        <eff-svg-cross class="icon-20"></eff-svg-cross>
      </button>
    </div>
  </div>
</div>
