<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Selects</h2>
  </div>
  <div class="card-content-container">

    <h3 class="text-l5">Select</h3>
    <details>
      <summary>Code</summary>
      <pre>
    <code>
&lt;mat-select disableRipple placeholder="Please select an option">
  &lt;mat-option value="1">Option 1&lt;/mat-option>
  &lt;mat-option value="2">Option 2&lt;/mat-option>
  &lt;mat-option value="3">Option 3&lt;/mat-option>
  &lt;mat-option disabled value="4">Option 4&lt;/mat-option>
&lt;/mat-select>
</code>
</pre>
    </details>
    <mat-select [disabled]="false" disableRipple placeholder="Please select an option">
      <mat-option value="1">Option 1</mat-option>
      <mat-option value="2">Option 2</mat-option>
      <mat-option value="3">Option 3</mat-option>
      <mat-option disabled value="4">Option 4</mat-option>
    </mat-select><br>

    <details>
      <summary>Code</summary>
      <pre>
    <code>
&lt;mat-select disableRipple placeholder="Please select an option">
  &lt;mat-option value="1">Option 1&lt;/mat-option>
  &lt;mat-option value="2">Option 2&lt;/mat-option>
  &lt;mat-option value="3">Option 3&lt;/mat-option>
  &lt;button class="add-option-container text-subdued icon-before" (click)="buttonClicked()">
    &lt;eff-svg-plus class="icon">&lt;/eff-svg-plus>
    &lt;span>Add something&lt;/span>
  &lt;/button>
&lt;/mat-select>
</code>
</pre>
    </details>
    <p>Option to add in dropdown</p>
    <mat-select #selectWithButton [disabled]="false" disableRipple placeholder="Please select an option">
      <mat-option value="1">Option 1</mat-option>
      <mat-option value="2">Option 2</mat-option>
      <mat-option value="3">Option 3</mat-option>
      <div class="divider"></div>
      <button class="add-option-container text-subdued icon-before" (click)="buttonClicked()">
        <eff-svg-plus class="icon"></eff-svg-plus>
        <span>Add something</span>
      </button>
    </mat-select><br>

    <details>
      <summary>Code (inline)</summary>
      <pre>
    <code>
&lt;mat-select class="inline" disableRipple placeholder="Please select an option">
  &lt;mat-option value="1">Option 1&lt;/mat-option>
  &lt;mat-option value="2">Option 2&lt;/mat-option>
  &lt;mat-option value="3">Option 3&lt;/mat-option>
  &lt;mat-option disabled value="4">Option 4&lt;/mat-option>
&lt;/mat-select>
</code>
</pre>
    </details>
    <mat-select class="inline" [disabled]="false" disableRipple placeholder="Please select an option">
      <mat-option value="1">Option 1</mat-option>
      <mat-option value="2">Option 2</mat-option>
      <mat-option value="3">Option 3</mat-option>
      <mat-option disabled value="4">Option 4</mat-option>
    </mat-select><br>

    <details>
      <summary>Code (multiselect)</summary>
      <pre>
    <code>
&lt;mat-select multiple disableRipple placeholder="Please select an option">
  &lt;mat-option value="1">Option 1&lt;/mat-option>
  &lt;mat-option value="2">Option 2&lt;/mat-option>
  &lt;mat-option value="3">Option 3&lt;/mat-option>
  &lt;mat-option disabled value="4">Option 4&lt;/mat-option>
&lt;/mat-select>
</code>
</pre>
    </details>
    <mat-select multiple disableRipple placeholder="Please select an option">
      <mat-option value="1">Option 1</mat-option>
      <mat-option value="2">Option 2</mat-option>
      <mat-option value="3">Option 3</mat-option>
      <mat-option disabled value="4">Option 4</mat-option>
    </mat-select><br>
    <details>
      <summary>Code (searchable / multiline)</summary>
      <pre>
        <code>
&lt;eff-select [required]="true" [multiple]="true" [searchable]="true" [disabled]="false" [(ngModel)]="values"
  [options]="[&#123;value: 0, text: 'Invalid Option', invalid: true&#125;,
    &#123;value: 1, text: 'Option 1'&#125;,
    &#123;value: 2, text: 'Option 2'&#125;,
    &#123;value: 3, text: 'Option 3'&#125;,
    &#123;value: 4, text: 'Option 4', disabled: true&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value"
  validValueLabel="Valid options" invalidValueLabel="Invalid options"
  invalidValueError="Invalid value selected"&gt;
&lt;/eff-select&gt;

&lt;eff-select [required]="true" [(ngModel)]="value" [options]="
  [&#123;value: 0, text: 'Option 0', description: 'The first option'&#125;,
  &#123;value: 1, text: 'Option 1', description: 'The second option'&#125;,
  &#123;value: 2, text: 'Option 2', description: 'The third option'&#125;,
  &#123;value: 3, text: 'Option 3', description: 'The fourth option'&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value">
&lt;/eff-select>

Available properties:
[inline] = false;
[multiple] = false;
[searchable] = false;
[required] = false;
[disabled] = false;
[previewData] = false;
[disableLocalSearch] = false;
[searchInDescription] = false;
[isLoading] = false;
[placeholderSelect]: string;
[placeholderSearch]: string;
[validValueLabel]: string;
[invalidValueLabel]: string;
[invalidValueError]: string;
[options]: EffectoryOption[];

interface EffectoryOption &#123;
  text: string;
  description?: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  dataPreview?: string[];
&#125;

(selectionChange) = new EventEmitter&lt;MatSelectChange>();
(closedWithChange) = new EventEmitter();
(searchTermChange) = new EventEmitter&lt;string>();
        </code>
      </pre>
    </details>

    <eff-checkbox [checked]="inline" (checkedChange)="inline = !inline" [labelText]="'Inline'"></eff-checkbox>
    <eff-checkbox [checked]="searchable" (checkedChange)="searchable = !searchable" [labelText]="'Searchable'"></eff-checkbox>
    <eff-checkbox [checked]="selectDisabled" (checkedChange)="selectDisabled = !selectDisabled" [labelText]="'Disabled'"></eff-checkbox>
    <eff-checkbox [checked]="searchInDescription" (checkedChange)="searchInDescription = !searchInDescription" [labelText]="'Search in description'"></eff-checkbox>
    <eff-checkbox [checked]="isLoading" (checkedChange)="isLoading = !isLoading" [labelText]="'Loading'"></eff-checkbox>
    <eff-checkbox [checked]="disableLocalSearch" (checkedChange)="disableLocalSearch = !disableLocalSearch" [labelText]="'Disable local search'"></eff-checkbox><br><br>
    <eff-checkbox [checked]="showPreviewData" (checkedChange)="showPreviewData = !showPreviewData" [labelText]="'Show preview data'"></eff-checkbox><br><br>

    <eff-select [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [isLoading]="isLoading" [(ngModel)]="value" [options]="[{value: 0, text: 'Option 0', description: 'The first option', dataPreview: ['value-1', 'value-2']}, {value: 1, text: 'Option 1', description: 'The second option', dataPreview: ['value-3', 'value-4']},
    {value: 2, text: 'Option 2', description: 'The third option'},{value: 3, text: 'Option 3', description: 'The fourth option', dataPreview: ['value-1', 'value-2', 'value-3', 'value-4', 'value-5']}]" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select>

    <p>Multi-select:</p>
    <eff-select [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [multiple]="true" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [isLoading]="isLoading" [(ngModel)]="value1" [options]="[{value: 0, text: 'Option 0', description: 'The first option', dataPreview: ['value-1', 'value-2']}, {value: 1, text: 'Option 1', description: 'The second option', dataPreview: ['value-3', 'value-4']},
    {value: 2, text: 'Option 2', description: 'The third option'},{value: 3, text: 'Option 3', description: 'The fourth option', dataPreview: ['value-1', 'value-2', 'value-3', 'value-4', 'value-5']}]" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select>

    <p>Invalid values selected:</p>
    <eff-select [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [multiple]="true" [isLoading]="isLoading" [(ngModel)]="values" [options]="[{value: 0, text: 'Invalid Option', invalid: true}, {value: 1, text: 'Option 1', dataPreview: ['value-1', 'value-2']}, {value: 2, text: 'Option 2', dataPreview: ['value-3', 'value-4']},{value: 3, text: 'Option 3', dataPreview: ['value-5', 'value-6']}, {value: 4, text: 'Option 4', disabled: true, dataPreview: ['value-7', 'value-8']}]"
      placeholderSelect="Please select an option" placeholderSearch="Search value" validValueLabel="Valid options" invalidValueLabel="Invalid options" invalidValueError="Invalid value selected"></eff-select>
    <br>
    <h3 class="text-l5">With virtual scrolling</h3>
    <details>
      <summary>Code (searchable / multiline) with Virtual scrolling</summary>
      <pre>
        <code>
&lt;eff-select-virtual [required]="true" [multiple]="true" [searchable]="true" [disabled]="false" [(ngModel)]="values"
  [options]="[&#123;value: 0, text: 'Invalid Option', invalid: true&#125;,
    &#123;value: 1, text: 'Option 1'&#125;,
    &#123;value: 2, text: 'Option 2'&#125;,
    &#123;value: 3, text: 'Option 3'&#125;,
    &#123;value: 4, text: 'Option 4', disabled: true&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value"
  validValueLabel="Valid options" invalidValueLabel="Invalid options"
  invalidValueError="Invalid value selected"&gt;
&lt;/eff-select-virtual&gt;

&lt;eff-select-virtual [required]="true" [(ngModel)]="value" [options]="
  [&#123;value: 0, text: 'Option 0', description: 'The first option'&#125;,
  &#123;value: 1, text: 'Option 1', description: 'The second option'&#125;,
  &#123;value: 2, text: 'Option 2', description: 'The third option'&#125;,
  &#123;value: 3, text: 'Option 3', description: 'The fourth option'&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value">
&lt;/eff-select-virtual>

Available properties:
[inline] = false;
[multiple] = false;
[searchable] = false;
[required] = false;
[disabled] = false;
[disableLocalSearch] = false;
[searchInDescription] = false;
[previewData] = false;
[isLoading] = false;
[placeholderSelect]: string;
[placeholderSearch]: string;
[validValueLabel]: string;
[invalidValueLabel]: string;
[invalidValueError]: string;
[options]: EffectoryOption[];

interface EffectoryOption &#123;
  text: string;
  description?: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  dataPreview?: string[];
&#125;

(selectionChange) = new EventEmitter&lt;MatSelectChange>();
(closedWithChange) = new EventEmitter();
(searchTermChange) = new EventEmitter&lt;string>();
        </code>
      </pre>
    </details>

    <eff-select-virtual [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [(ngModel)]="value" [options]="[{value: 0, text: 'Option 0', description: 'The first option', dataPreview: ['value-1', 'value-2']}, {value: 1, text: 'Option 1', description: 'The second option', dataPreview: ['value-3', 'value-4']},
    {value: 2, text: 'Option 2', description: 'The third option'},{value: 3, text: 'Option 3', description: 'The fourth option', dataPreview: ['value-1', 'value-2', 'value-3', 'value-4', 'value-5']}]" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select-virtual>

    <p>Multi-select:</p>
    <eff-select-virtual [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [multiple]="true" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [(ngModel)]="virtualValue1" [options]="[{value: 0, text: 'Option 0', description: 'The first option', dataPreview: ['value-1', 'value-2']}, {value: 1, text: 'Option 1', description: 'The second option', dataPreview: ['value-3', 'value-4']},
    {value: 2, text: 'Option 2', description: 'The third option'},{value: 3, text: 'Option 3', description: 'The fourth option', dataPreview: ['value-1', 'value-2', 'value-3', 'value-4', 'value-5']}]" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select-virtual>

    <p>Invalid values selected:</p>
    <eff-select-virtual [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [multiple]="true" [(ngModel)]="virtualValues" [options]="[{value: 0, text: 'Invalid Option', invalid: true}, {value: 1, text: 'Option 1', dataPreview: ['value-1', 'value-2']}, {value: 2, text: 'Option 2', dataPreview: ['value-3', 'value-4']},{value: 3, text: 'Option 3', dataPreview: ['value-5', 'value-6']}, {value: 4, text: 'Option 4', disabled: true, dataPreview: ['value-7', 'value-8']}]"
      placeholderSelect="Please select an option" placeholderSearch="Search value" validValueLabel="Valid options" invalidValueLabel="Invalid options" invalidValueError="Invalid value selected"></eff-select-virtual>
    <br>
    <h3 class="text-l5">Language picker</h3>
    <details>
      <summary>Code (searchable / multiline) with Language picker</summary>
      <pre>
        <code>
&lt;eff-select-language [required]="true" [multiple]="true" [searchable]="true" [disabled]="false" [(ngModel)]="values"
  [options]="[&#123;value: en-US, text: 'Invalid Option', invalid: true&#125;,
    &#123;value: en-GB, text: 'Option 1'&#125;,
    &#123;value: nl-NL, text: 'Option 2'&#125;,
    &#123;value: de-DE, text: 'Option 3'&#125;,
    &#123;value: pt-PT, text: 'Option 4', disabled: true&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value"
  validValueLabel="Valid options" invalidValueLabel="Invalid options"
  invalidValueError="Invalid value selected"&gt;
&lt;/eff-select-language&gt;

&lt;eff-select-language [required]="true" [(ngModel)]="value" [options]="
  [&#123;value: 0, text: 'Option 0', description: 'The first option'&#125;,
  &#123;value: 1, text: 'Option 1', description: 'The second option'&#125;,
  &#123;value: 2, text: 'Option 2', description: 'The third option'&#125;,
  &#123;value: 3, text: 'Option 3', description: 'The fourth option'&#125;]"
  placeholderSelect="Please select an option" placeholderSearch="Search value">
&lt;/eff-select-language>

Available properties:
[inline] = false;
[multiple] = false;
[searchable] = false;
[required] = false;
[disabled] = false;
[disableLocalSearch] = false;
[searchInDescription] = false;
[previewData] = false;
[isLoading] = false;
[placeholderSelect]: string;
[placeholderSearch]: string;
[validValueLabel]: string;
[invalidValueLabel]: string;
[invalidValueError]: string;
[options]: EffectoryOption[];

interface EffectoryOption &#123;
  text: string; // Can be null, will be overwritten with the translation of the language name
  description?: string;
  value: string;  // This should be a cultureCode (xx-XX)
  disabled?: boolean;
  invalid?: boolean;
&#125;

(selectionChange) = new EventEmitter&lt;MatSelectChange>();
(closedWithChange) = new EventEmitter();
(searchTermChange) = new EventEmitter&lt;string>();
        </code>
      </pre>
    </details>

    <eff-select-language [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [(ngModel)]="culture" [options]="cultureCodes" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select-language>

    <p>Multi-select:</p>
    <eff-select-language [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [multiple]="true" [disabled]="selectDisabled" [disableLocalSearch]="disableLocalSearch" [(ngModel)]="languageValue2" [options]="[{value: 'en-GB', text: 'Option 0', description: 'The first option', dataPreview: ['value-1', 'value-2']}, {value: 'nl-NL', text: 'Option 1', description: 'The second option', dataPreview: ['value-3', 'value-4']},
    {value: 'de-DE', text: 'Option 2', description: 'The third option'},{value: 'fr-FR', text: 'Option 3', description: 'The fourth option', dataPreview: ['value-1', 'value-2', 'value-3', 'value-4', 'value-5']}]" placeholderSelect="Please select an option" placeholderSearch="Search value"></eff-select-language>

    <p>Invalid values selected:</p>
    <eff-select-language [previewData]="showPreviewData" [inline]="inline" [searchable]="searchable" [searchInDescription]="searchInDescription" [disabled]="selectDisabled" [multiple]="true" [(ngModel)]="languageValues" [options]="[{value: 'en-US', text: 'Invalid Option', invalid: true}, {value: 'en-GB', text: 'Option 1', dataPreview: ['value-1', 'value-2']}, {value: 'nl-NL', text: 'Option 2', dataPreview: ['value-3', 'value-4']},{value: 'de-DE', text: 'Option 3', dataPreview: ['value-5', 'value-6']}, {value: 'fr-FR', text: 'Option 4', disabled: true, dataPreview: ['value-7', 'value-8']}]"
      placeholderSelect="Please select an option" placeholderSearch="Search value" validValueLabel="Valid options" invalidValueLabel="Invalid options" invalidValueError="Invalid value selected"></eff-select-language>
    <br>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Datepicker</h2>
  </div>
  <div class="card-content-container">
    <p>
      The datepicker we are using is from the Angular Material library.<br />
      You can find the documentation of the datepicker <a href="https://material.angular.io/components/datepicker/overview" target="_BLANK">here</a>.
    </p>
    <p>Available properties:</p>
    <ul>
      <li>[placeholder] - string</li>
      <li>[selectedDate] - moment | Date</li>
      <li>[minDate] - moment | Date</li>
      <li>[maxDate] - moment | Date</li>
      <li>[disabled] - boolean</li>
      <li>(datepickerChanged) - moment (if you need to convert to JS date; new Date(event.value);)</li>
    </ul>

    <br>

    <h3 class="text-l5 card-title">Datepicker with placeholder</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-datepicker
  [placeholder]="placeholder">
&lt;/eff-datepicker>
</code>
</pre>
    </details>
    <eff-datepicker [placeholder]="placeholder">
    </eff-datepicker>

    <br>

    <h3 class="text-l5 card-title">Datepicker with preselected date</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-datepicker
  [selectedDate]="selectedDate">
&lt;/eff-datepicker>
</code>
</pre>
    </details>
    <eff-datepicker [selectedDate]="selectedDate">
    </eff-datepicker>
    <br>
    <h3 class="text-l5 card-title">Datepicker with min &amp; max date</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-datepicker
  [selectedDate]="selectedDate"
  [minDate]="minDate"
  [maxDate]="maxDate">
&lt;/eff-datepicker>
</code>
</pre>
    </details>
    <eff-datepicker [selectedDate]="selectedDate" [minDate]="minDate" [maxDate]="maxDate">
    </eff-datepicker>

    <br>

    <h3 class="text-l5 card-title">Datepicker disabled</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-datepicker
  placeholder="Disabled datepicker"
  [disabled]="disabled">
&lt;/eff-datepicker>
</code>
</pre>
    </details>
    <eff-datepicker placeholder="Disabled datepicker" [disabled]="disabled">
    </eff-datepicker>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">In-line Datepicker</h2>
  </div>
  <div class="card-content-container">
    <p>
      The inline datepicker we are using is the mat-calendar from mat-datepicker in the Angular Material library.<br />
      You can find the documentation of the datepicker <a href="https://material.angular.io/components/datepicker/overview" target="_BLANK">here</a>.
    </p>
    <p>Available properties:</p>
    <ul>
      <li>[selected] - moment | Date</li>
      <li>[minDate] - moment | Date</li>
      <li>[maxDate] - moment | Date</li>
      <li>(selectedChanged) - moment | Date</li>
    </ul>

    <br>
    <h3 class="text-l5 card-title">In-line Datepicker</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-calendar>
&lt;/mat-calendar>
</code>
</pre>
    </details>
    <mat-calendar></mat-calendar>

    <h3 class="text-l5 card-title">Pre-selected In-line Datepicker with min &amp; max date</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-calendar [selected]="selectedDate" [minDate]="minDate" [maxDate]="maxDate">
&lt;/mat-calendar>
</code>
</pre>
    </details>
    <mat-calendar [selected]="selectedDate" [minDate]="minDate" [maxDate]="maxDate" (selectedChange)="dateChanged($event)"></mat-calendar>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Timepicker</h2>
  </div>
  <div class="card-content-container">
    <p>
      The timepicker we are using was built by us, so feel free to add to it or correct any issues that may experience.<br />
    </p>
    <p>Available properties:</p>
    <ul>
      <li>[showIncreaseByPeriodButtons] - boolean</li>
      <li>[hour] - number</li>
      <li>[minute] - number</li>
      <li>[hoursMinTreshold] - number</li>
      <li>[hoursMaxTreshold] - number</li>
      <li>[minutesMinTreshold] - number</li>
      <li>[minutesMaxTreshold] - number</li>
      <li>[debounceTime] - number</li>
      <li>(timeUpdated) - [number, number]</li>
    </ul>

    <br>

    <h3 class="text-l5 card-title">Timepicker default</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-timepicker>&lt;/eff-timepicker>
</code>
</pre>
    </details>
    <eff-timepicker></eff-timepicker>

    <br>

    <h3 class="text-l5 card-title">Timepicker preselected time</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-timepicker [hour]="hour" [minute]="minute">&lt;/eff-timepicker>
</code>
</pre>
    </details>
    <eff-timepicker [hour]="hour" [minute]="minute"></eff-timepicker>
    <br>

    <h3 class="text-l5 card-title">Timepicker with hours treshold set to 4-20</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-timepicker
  [hour]="hour"
  [minute]="minute"
  [hoursMinTreshold]="4"
  [hoursMaxTreshold]="20">
&lt;/eff-timepicker>
</code>
</pre>
    </details>
    <eff-timepicker [hour]="hour" [minute]="minute" [hoursMinTreshold]="4" [hoursMaxTreshold]="20">
    </eff-timepicker>

    <br>

    <h3 class="text-l5 card-title">Timepicker without period buttons</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-timepicker
  hour]="hour"
  [minute]="minute"
  [showIncreaseByPeriodButtons]="false">
&lt;/eff-timepicker>
</code>
</pre>
    </details>
    <eff-timepicker [hour]="hour" [minute]="minute" [showIncreaseByPeriodButtons]="false">
    </eff-timepicker>
    <br>

    <h3 class="text-l5 card-title">Timepicker disabled</h3>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-timepicker
  hour]="hour"
  [minute]="minute"
  [disabled]="true">
&lt;/eff-timepicker>
</code>
</pre>
    </details>
    <eff-timepicker [hour]="hour" [minute]="minute" [disabled]="true">
    </eff-timepicker>
  </div>
</div>
