import { Component, OnInit } from "@angular/core";
import { Notification, NotificationService } from "./../../services/notification.service";

@Component({
  selector: "eff-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  public ngOnInit(): void {
  }

  public closeNotification(notification: Notification) {
    this.notificationService.closeNotification(notification);
  }

  public executeCallback(notification: Notification, index = 0) {
    if (index > 2) {
      throw new Error("More than three links are not supported");
    }

    notification["linkCallback" + index]();

    this.closeNotification(notification);
  }
}
