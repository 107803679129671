import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "eff-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent implements OnInit {
  @Input() public totalNumber: number;
  @Input() public pageSize: number;
  @Input() public isLoading = false;
  @Input() public initialPage = 1;

  @Output() public pageChanged = new EventEmitter<number>();

  public startNumber = 1;
  public page = 1;
  public lastAction: string;

  public get nextButtonDisabled(): boolean {
    const endNumber = this.startNumber + this.pageSize - 1;
    return endNumber >= this.totalNumber;
  }

  public get previousButtonDisabled(): boolean {
    return this.page === 1;
  }

  public get nextButtonLoading(): boolean {
    return this.isLoading && this.lastAction === "next";
  }

  public get previousButtonLoading(): boolean {
    return this.isLoading && this.lastAction === "previous";
  }

  public ngOnInit(): void {
    this.page = this.initialPage;
    this.startNumber = this.pageSize * (this.page - 1) + 1;
  }

  public goToNextPage(): void {
    if (this.nextButtonDisabled) {
      return;
    }
    this.page++;
    this.lastAction = "next";
    this.startNumber += this.pageSize;
    this.pageChanged.emit(this.page);
  }

  public goToPreviousPage(): void {
    if (this.previousButtonDisabled) {
      return;
    }
    this.page--;
    this.lastAction = "previous";
    this.startNumber -= this.pageSize;
    this.pageChanged.emit(this.page);
  }

  public refresh(): void {
    this.startNumber = 1;
    this.page = 1;
  }

}
