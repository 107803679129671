import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FeatureToggleRepository, SignInRepository } from "@effectory/core";
import { TranslateService } from "@ngx-translate/core";
import { PaginationComponent } from "../pagination/pagination.component";
import { languageSettings } from "@effectory/core";
import { CultureNamePipe } from "@effectory/core";

@Component({
  selector: "eff-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"]
})
export class LanguageSelectorComponent implements AfterViewInit {
  @ViewChild("pagination", { static: false }) public pagination: PaginationComponent;
  @ViewChild("elementRef") public elementRef: ElementRef;

  public searchInput: string;
  public cultures: string[];
  public quickSelectCultures: string[] = ["en-US", "nl-NL", "de-DE"];
  // Pagination
  public startNumber = 1;
  public endNumber = 20;
  public pageSize = 10;
  public totalNumber = 0;
  public initialPage = 1;
  public hidePagination: boolean = false;
  public allCultures: string[];
  public hasGoogleTranslateFT = false;

  constructor(
    private dialogRef: MatDialogRef<LanguageSelectorComponent>,
    public translate: TranslateService,
    private cultureNamePipe: CultureNamePipe,
    public signInRepository: SignInRepository,
    private featureToggleRepository: FeatureToggleRepository) {

    const availableCultures = languageSettings.default.cultures;
    this.allCultures = [...availableCultures].sort((a, b) => this.cultureNamePipe.transform(a).localeCompare(this.cultureNamePipe.transform(b)));

    this.totalNumber = this.allCultures.length;
    this.hidePagination = this.pageSize > this.totalNumber;

    this.initialPage = Math.floor(this.allCultures.indexOf(this.translate.currentLang) / (this.pageSize)) + 1;
    this.setPagination(this.initialPage);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.hasGoogleTranslateFT = await this.featureToggleRepository.isFeatureEnabled("google-translate");
    if (this.hasGoogleTranslateFT) {
      const v = document.createElement("script");
      v.type = "text/javascript";
      v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element'); } ";
      this.elementRef.nativeElement.appendChild(v);
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      this.elementRef.nativeElement.appendChild(s);
    }
  }

  public selectLanguage(culture: string) {
    this.translate.use(culture);
    sessionStorage.setItem("selectedCulture", culture);
    this.setHtmlLangAttribute(culture);

    this.signInRepository.updateCulture(culture); // do not await to close dialog
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public searchInputChanged(text: string) {
    if (this.pagination) {
      this.pagination.refresh();
    }

    if (!text || text === "") {
      this.totalNumber = this.allCultures.length;
      this.cultures = this.paginate(this.allCultures, this.pageSize, 1);
    } else {
      const filteredItems = this.searchLanguages(this.allCultures, text);
      this.totalNumber = filteredItems.length;
      this.cultures = this.paginate(filteredItems, this.pageSize, 1);
    }

    this.setPagination(1);
  }

  public setPagination(page: number): void {
    this.startNumber = ((page - 1) * this.pageSize) + 1;
    this.endNumber = this.startNumber + this.pageSize > this.totalNumber ? this.totalNumber : this.startNumber + this.pageSize - 1;

    if (this.searchInput !== "") {
      const filteredItems = this.searchLanguages(this.allCultures, this.searchInput);
      this.cultures = this.paginate(filteredItems, this.pageSize, page);
    } else {
      this.cultures = this.paginate(this.allCultures, this.pageSize, page);
    }
  }

  public selectEnglish(): void {
    const culture = "en-US";
    this.translate.use(culture);
    sessionStorage.setItem("selectedCulture", culture);
    this.setHtmlLangAttribute(culture);
  }

  private paginate(array: any[], pageSize: number, pageNumber: number) {
    --pageNumber;
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  private searchLanguages(cultures: string[], text) {
    const results = cultures.filter((language) => this.cultureNamePipe.transform(language).search(new RegExp(text, "i")) > -1);
    return results;
  }

  private setHtmlLangAttribute(locale: string): void {
    document.querySelector("html").setAttribute("lang", locale);
  }
}
