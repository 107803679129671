import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {VideoOptions} from "../models/videoOptions";
import {FeedbackEvent} from "../models/feedbackEvent";

@Component({
  selector: "eff-video-feedback",
  templateUrl: "./video-feedback.component.html",
  styleUrls: ["./video-feedback.component.scss"]
})
export class VideoFeedbackComponent {
  @Output() public feedbackSent = new EventEmitter<FeedbackEvent>();

  @Input() public videoOptions: VideoOptions;

  @ViewChild("feedbackDialog") public feedbackDialog: TemplateRef<any>;

  public isFeedbackGiven = false;
  public suggestion: string;
  public isPositive: boolean;

  constructor(private readonly matDialog: MatDialog) {
  }

  public feedback(isPositive: boolean) {
    this.isPositive = isPositive;
    this.openFeedbackModal();
  }

  public sendFeedback(hasSuggestion: boolean) {
    this.isFeedbackGiven = true;
    this.feedbackSent.emit({
      videoId: this.videoOptions.id,
      videoName: this.videoOptions.name,
      suggestion: hasSuggestion ? this.suggestion : "",
      isPositive: this.isPositive
    });
    this.matDialog.closeAll();
  }

  private openFeedbackModal() {
    const config = new MatDialogConfig();
    config.panelClass = "dialog-s";
    config.backdropClass = "dialog-backdrop";
    this.matDialog.open(this.feedbackDialog, config);
  }
}
