import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { DialogAndPointerPositions, TooltipAlignments, TooltipPositions } from "./tooltip-dialog-and-pointer-positions.enum";
import { ITooltipDialogConfig } from "./tooltip-dialog.interface";

@Component({
  selector: "eff-tooltip-dialog",
  templateUrl: "./tooltip-dialog.component.html",
  styleUrls: ["./tooltip-dialog.component.scss"]
})
export class TourTooltipComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() public targetElement: ElementRef;
  @Input() public addOutlineToTargetElement: boolean = false;
  @Input() public targetElementOutlineColor: string = "#30B3AF";
  @Input() public addHighlightToTargetElement: boolean = false;
  @Input() public showDialogBackgroundOverlay: boolean = true;

  // DIALOG PARAMS
  @ViewChild("tourDialog") public tourDialog: ElementRef;
  @ViewChild("closeButton") public dialogCloseButton: ElementRef;
  @Input() public dialogWidth = 300;
  @Input() public dialogTitle: string;
  @Input() public dialogSubtitle: string;
  @Input() public dialogButtonText: string;
  @Input() public dialogLinkButtonText: string;
  @Input() public dialogProgressionStageCurrent: number;
  @Input() public isXmasVideo: boolean = false;
  @Input() public dialogProgressionStageTotal: number;
  /**
   * @deprecated The property should not be used anymore. Instead use dialogAlignmentToTarget and dialogPositionToTarget.
   */
  @Input() public dialogTooltipPosition: DialogAndPointerPositions;
  @Input() public dialogAlignmentToTarget: TooltipAlignments = TooltipAlignments.Start;
  @Input() public dialogPositionToTarget: TooltipPositions = TooltipPositions.Below;
  @Input() public svgUrl: string;
  @Input() public arrowColor = "#fff";
  @Input() public withoutButtons: boolean = false;
  @Output() public dialogClosedOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  public dialogConfigPosition: ITooltipDialogConfig;
  public backgroundOverlay: any;

  // Iterator created for ngFor to iterate for a givern amount of times
  public progressionIndicatorIterator: any[] = [];

  // TARGET ELEMENT PARAMS
  private targetElementLeft = 0;
  private targetElementRight = 0;
  private targetElementBottom = 0;
  private targetElementTop = 0;
  private targetElementBackgroundColor: string;
  private targetElementPosition: string;
  private targetElementBorderRadius: string;
  private targetElementOutline: string;
  private targetElementZIndex: string;

  // DIALOG PARAMS
  private hasDialogBeenDisplayed = false;
  private pointerSize = 16;
  private hasDialogBeenReposition = false;
  private dialogHasBeenClosed = false;
  private dialogHeight: number;

  // BODY PARAMS
  private padding = "2.4rem";
  private mobileHeaderHeight = 70;
  private bodyWidth = 0;
  private bodyHeight = 0;

  @HostListener("window:resize")
  onWindowResize() {
    if (!this.dialogHasBeenClosed) {
      this.dialogSetUp();
      this.setDialogTooltipStylesAndFocus();
    }
  }

  @HostListener("window:keydown", ["$event"])
  onWKeyDown(event: KeyboardEvent) {
    if (!this.dialogHasBeenClosed && this.hasDialogBeenDisplayed) {
      if (event.shiftKey && event.key === "Tab") {
        event.preventDefault();
      }

      if (event.key === "Tab" || event.shiftKey) {
        this.tourDialog.nativeElement.focus();
      }

      // Do not close on Escape if the dialog expects an outside action to close
      if (!this.withoutButtons && event.key === "Escape") {
        this.closeDialog(this.isXmasVideo);
      }
    }
  }

  public ngOnInit(): void {
    this.setInitialDialogAndPointerPosition();
    this.progressionIndicatorIterator = this.dialogProgressionStageTotal ? new Array(this.dialogProgressionStageTotal) : [];
  }

  public ngAfterViewChecked(): void {
    if (!this.hasDialogBeenDisplayed || !this.hasDialogBeenReposition || this.targetElementTop < 0) {

      if (this.targetElement?.nativeElement && !this.hasDialogBeenDisplayed) {
        this.disableHTMLScrolling();
        this.dialogSetUp();
        this.setDialogTooltipStylesAndFocus();

        if (this.showDialogBackgroundOverlay) {
          this.appendBackgroundOverlay();
        }

        if (this.addHighlightToTargetElement) {
          this.highlightTargetElement();
        }

        if (this.addOutlineToTargetElement) {
          this.outlineTargetElement();
        }
        this.hasDialogBeenDisplayed = true;
      }

      // Scroll & reposition the dialog if it's out of screen
      // 1) If it's out of the screen (negative offset => (this.targetElementTop < 0))
      // 2) If there is not enough room for dialog to be displayed underneath the target element
      if ((!this.hasDialogBeenReposition && this.tourDialog && this.hasDialogBeenDisplayed) || (this.targetElementTop < 0)) {
        this.enableHTMLScrolling();
        this.dialogHeight = this.getDialogHeight();
        const scrolled = this.scrollToElement(this.targetElementBottom, this.dialogHeight, this.pointerSize, this.bodyHeight, this.targetElementTop, this.mobileHeaderHeight);

        if (scrolled) {
          this.getOffsetsAndDimensionsOfTargetElement();
          this.setDialogAndPointerPosition(this.dialogPositionToTarget, this.dialogAlignmentToTarget);
          this.setDialogTooltipStylesAndFocus();
        }

        this.disableHTMLScrolling();
        this.hasDialogBeenReposition = true;
      }
    }
  }

  public ngOnDestroy(): void {
    this.closeDialog(true);
  }

  public closeDialog(withoutEmit: boolean = false): void {
    if (this.addHighlightToTargetElement) {
      this.removeHighlightFromTargetElement();
    }

    if (this.addOutlineToTargetElement) {
      this.removeOutlineFromTargetElement();
    }

    this.enableHTMLScrolling();
    this.removeDialogFromDOM();

    if (this.showDialogBackgroundOverlay) {
      this.removeBackgroundOverlay();
    }

    if (!withoutEmit) {
      this.dialogClosedOutput.emit(true);
    }
    this.dialogHasBeenClosed = true;
  }

  private dialogSetUp(): void {
    this.getOffsetsAndDimensionsOfTargetElement();
    this.bodyHeight = this.getBodyHeight();
    this.bodyWidth = this.getBodyWidth();
    this.targetElementBackgroundColor = this.getBackgroundColorOfTargetElement();
    this.targetElementPosition = this.getPositionOfTargetElement();
    this.targetElementBorderRadius = this.getBorderRadiusOfTargetElement();
    this.targetElementOutline = this.getOutlineOfTargetElement();
    this.targetElementZIndex = this.getZIndexOfTargetElement();
    this.setDialogAndPointerPosition(this.dialogPositionToTarget, this.dialogAlignmentToTarget);
  }

  private setInitialDialogAndPointerPosition() {
    // If someone is still using the old property, update the new ones
    if (this.dialogTooltipPosition) {
      this.dialogPositionToTarget = TooltipPositions.Below;
      switch(this.dialogTooltipPosition) {
        case DialogAndPointerPositions.Right:
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          break;
        case DialogAndPointerPositions.Left:
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          break;
        case DialogAndPointerPositions.Center:
          this.dialogAlignmentToTarget = TooltipAlignments.Center;
          break;
      }
    }
  }

  private setDialogAndPointerPosition(dialogPosition: TooltipPositions, dialogAlignment: TooltipAlignments): void {
    switch (dialogPosition) {
      case TooltipPositions.Above:
        this.setDialogAndPointerPositionAbove(dialogAlignment);
        break;
      case TooltipPositions.Below:
        this.setDialogAndPointerPositionBelow(dialogAlignment);
        break;
      case TooltipPositions.Before:
        this.setDialogAndPointerPositionBefore(dialogAlignment);
        break;
      case TooltipPositions.After:
        this.setDialogAndPointerPositionAfter(dialogAlignment);
        break;
    }
  }

  private setDialogAndPointerPositionAbove(dialogAlignment: TooltipAlignments) {
    const dialogRightPosition = this.getRightOffsetForDialogPosition();
    const dialogLeftPosition = this.getLeftOffsetForDialogPosition();
    const dialogBottomPosition = this.getBottomOffsetForDialogPosition();

    switch(dialogAlignment) {
      case TooltipAlignments.End: {
        // Is there enough room for dialog be displayed to the left
        if (this.targetElementRight >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, left: dialogRightPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the right
        if ((this.bodyWidth - this.targetElementLeft) >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, left: dialogLeftPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { bottom: dialogBottomPosition, right: this.padding };
        break;
      }

      case TooltipAlignments.Start: {
         // Is there enough room for dialog be displayed to the right
        if ((this.bodyWidth - this.targetElementLeft) >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, left: dialogLeftPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the left
        if (this.targetElementRight >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, left: dialogRightPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { bottom: dialogBottomPosition, left: this.padding };
        break;
      }
    }
  }

  private setDialogAndPointerPositionBelow(dialogAlignment: TooltipAlignments) {
    const dialogRightPosition = this.getRightOffsetForDialogPosition();
    const dialogLeftPosition = this.getLeftOffsetForDialogPosition();
    const dialogTopPosition = this.getTopOffsetForDialogPosition();

    switch(dialogAlignment) {
      case TooltipAlignments.End: {
        // Is there enough room for dialog be displayed to the left
        if (this.targetElementRight >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogRightPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the right
        if ((this.bodyWidth - this.targetElementLeft) >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogLeftPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: dialogTopPosition, right: this.padding };
        break;
      }

      case TooltipAlignments.Start: {
         // Is there enough room for dialog be displayed to the right
        if ((this.bodyWidth - this.targetElementLeft) >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogLeftPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the left
        if (this.targetElementRight >= this.dialogWidth) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogRightPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: dialogTopPosition, left: this.padding };
        break;
      }
    }
  }

  private setDialogAndPointerPositionBefore(dialogAlignment: TooltipAlignments) {
    const dialogRightPosition = this.getRightOffsetForDialogPosition();
    const dialogTopPosition = this.getTopOffsetForDialogPosition();
    const dialogBottomPosition = this.getBottomOffsetForDialogPosition();
    this.dialogHeight = this.getDialogHeight();

    switch(dialogAlignment) {
      case TooltipAlignments.End: {
        // Is there enough room for dialog be displayed to the bottom
        if (this.targetElementBottom >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, right: dialogRightPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the top
        if ((this.bodyHeight - this.targetElementTop) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, right: dialogRightPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: this.padding, right: this.padding };
        break;
      }

      case TooltipAlignments.Start: {
         // Is there enough room for dialog be displayed to the top
        if ((this.bodyHeight - this.targetElementTop) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, right: dialogRightPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the bottom
        if ((this.bodyHeight - this.targetElementBottom) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: dialogBottomPosition, right: dialogRightPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: this.padding, left: this.padding };
        break;
      }
    }
  }

  private setDialogAndPointerPositionAfter(dialogAlignment: TooltipAlignments) {
    const dialogLeftPosition = this.getLeftOffsetForDialogPosition();
    const dialogTopPosition = this.getTopOffsetForDialogPosition();
    const dialogBottomPosition = this.getBottomOffsetForDialogPosition();
    this.dialogHeight = this.getDialogHeight();

    switch(dialogAlignment) {
      case TooltipAlignments.End: {
        // Is there enough room for dialog be displayed to the bottom
        if (this.targetElementBottom >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: `calc(100vh - ${dialogBottomPosition})`, left: dialogLeftPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the top
        if ((this.bodyHeight - this.targetElementTop) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogLeftPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: this.padding, left: this.padding };
        break;
      }

      case TooltipAlignments.Start: {
         // Is there enough room for dialog be displayed to the top
        if ((this.bodyHeight - this.targetElementTop) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.Start;
          this.dialogConfigPosition = { top: dialogTopPosition, left: dialogLeftPosition };
          break;
        }

        // Is there enough room for dialog be displayed to the bottom
        if ((this.bodyHeight - this.targetElementBottom) >= this.dialogHeight) {
          this.dialogAlignmentToTarget = TooltipAlignments.End;
          this.dialogConfigPosition = { bottom: `calc(100vh - ${dialogBottomPosition})`, left: dialogLeftPosition };
          break;
        }

        this.dialogAlignmentToTarget = TooltipAlignments.None;
        this.dialogConfigPosition = { top: this.padding, left: this.padding };
        break;
      }
    }
  }

  // Scroll html page, if there is not enough room for the dialog to be displayed underneath the target element
  // Or if the target element is off the screen to the top (negative offset => (targetElementBottom < 0)
  private scrollToElement(targetElementBottom: number, dialogHeight: number, pointerSize: number, bodyHeight: number, targetElementTop: number, mobileHeaderHeight: number): boolean {
    switch(this.dialogPositionToTarget) {
      case TooltipPositions.Below:
        if (targetElementBottom < 0 || ((targetElementBottom + dialogHeight + pointerSize) > bodyHeight)) {
          window.scroll({ top: ((targetElementTop + window.pageYOffset) - mobileHeaderHeight) });

          return true;
        }
        break;
      case TooltipPositions.Above:
        if (targetElementTop < 0 || ((dialogHeight + pointerSize) > targetElementTop) || ((targetElementTop + dialogHeight + pointerSize) > bodyHeight)) {
          window.scroll({ top: ((targetElementTop - dialogHeight - pointerSize + window.pageYOffset) - mobileHeaderHeight) });

          return true;
        }
        break;
    }

    return false;
  }

  private setDialogTooltipStylesAndFocus(): void {
    if (this.dialogConfigPosition) {
      this.tourDialog.nativeElement.style.top = this.dialogConfigPosition.top;
      this.tourDialog.nativeElement.style.bottom = this.dialogConfigPosition.bottom;
      this.tourDialog.nativeElement.style.left = this.dialogConfigPosition.left;
      this.tourDialog.nativeElement.style.right = this.dialogConfigPosition.right;
    }

    this.tourDialog.nativeElement.style.width = this.dialogWidth + "px";
    this.tourDialog.nativeElement.focus();
  }

  private highlightTargetElement(): void {
    if (this.targetElementBackgroundColor === "rgba(0, 0, 0, 0)") {
      this.targetElement.nativeElement.style.backgroundColor = "#FFF";
    }

    this.targetElement.nativeElement.style.position = "relative";
    this.targetElement.nativeElement.style.borderRadius = ".4rem";
    this.targetElement.nativeElement.style.zIndex = 900;
  }

  private removeHighlightFromTargetElement(): void {
    this.targetElement.nativeElement.style.backgroundColor = this.targetElementBackgroundColor;
    this.targetElement.nativeElement.style.position = this.targetElementPosition;
    this.targetElement.nativeElement.style.borderRadius = this.targetElementBorderRadius;
    this.targetElement.nativeElement.style.zIndex = this.targetElementZIndex;
  }

  private appendBackgroundOverlay(): void {
    this.backgroundOverlay = document.createElement("div");
    this.backgroundOverlay.style.cssText = "position:fixed;width:100%;height:100vh;opacity:0.3;z-index:800;background:#000;top:0px;left:0px";
    document.body.appendChild(this.backgroundOverlay);
  }

  private outlineTargetElement(): void {
    this.targetElement.nativeElement.style.outline = `.2rem solid ${this.targetElementOutlineColor}`;
    this.targetElement.nativeElement.style.outlineOffset = "-.2rem";
  }

  private removeOutlineFromTargetElement(): void {
    this.targetElement.nativeElement.style.outline = this.targetElementOutline;
  }

  private removeDialogFromDOM(): void {
    this.tourDialog?.nativeElement?.remove();
  }

  private removeBackgroundOverlay(): void {
    this.backgroundOverlay?.remove();
  }

  private disableHTMLScrolling(): void {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  }

  private enableHTMLScrolling(): void {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
  }

  private getBodyHeight(): number {
    return document.body.getBoundingClientRect().height;
  }

  private getBodyWidth(): number {
    return document.body.getBoundingClientRect().width;
  }

  private getRightOffsetForDialogPosition(): string {
    switch(this.dialogPositionToTarget) {
      case TooltipPositions.Before:
        return String(this.bodyWidth - this.targetElementLeft + this.pointerSize) + "px";
      case TooltipPositions.Above:
      case TooltipPositions.Below:
        return String(this.targetElementRight - this.dialogWidth) + "px";
    }
  }

  private getLeftOffsetForDialogPosition(): string {
    switch(this.dialogPositionToTarget) {
      case TooltipPositions.After:
        return String(this.targetElementRight + this.pointerSize) + "px";
      case TooltipPositions.Above:
      case TooltipPositions.Below:
        return this.targetElementLeft + "px";
    }
  }

  private getTopOffsetForDialogPosition(): string {
    switch(this.dialogPositionToTarget) {
      case TooltipPositions.Before:
      case TooltipPositions.After:
        return this.targetElementTop + "px";
      case TooltipPositions.Above:
      case TooltipPositions.Below:
        return String(this.targetElementBottom + this.pointerSize) + "px";
    }
  }

  private getBottomOffsetForDialogPosition(): string {
    switch(this.dialogPositionToTarget) {
      case TooltipPositions.Before:
      case TooltipPositions.After:
        return String(this.bodyHeight - this.targetElementBottom)  + "px";
      case TooltipPositions.Above:
        return String(this.bodyHeight - this.targetElementTop + this.pointerSize) + "px";
      case TooltipPositions.Below:
        return String(this.targetElementTop - this.pointerSize) + "px";
    }
  }

  private getBackgroundColorOfTargetElement(): string {
    return window.getComputedStyle(this.targetElement.nativeElement)?.backgroundColor;
  }

  private getPositionOfTargetElement(): string {
    return window.getComputedStyle(this.targetElement.nativeElement)?.position;
  }

  private getBorderRadiusOfTargetElement(): string {
    return window.getComputedStyle(this.targetElement.nativeElement)?.borderRadius;
  }

  private getOutlineOfTargetElement(): string {
    return window.getComputedStyle(this.targetElement.nativeElement)?.outline;
  }

  private getZIndexOfTargetElement(): string {
    return window.getComputedStyle(this.targetElement.nativeElement)?.zIndex;
  }

  private getDialogHeight(): number {
    return this.tourDialog?.nativeElement?.getBoundingClientRect().height;
  }

  private getOffsetsAndDimensionsOfTargetElement(): void {
    const { top, left, bottom, right } = this.targetElement.nativeElement.getBoundingClientRect();
    this.targetElementLeft = left;
    this.targetElementRight = right;
    this.targetElementBottom = bottom;
    this.targetElementTop = top;
  }
}
