<div class="card">
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-stepper class=&quot;stepper&quot; orientation=&quot;horizontal | vertical&quot; linear #stepper&gt;
  &lt;ng-template matStepperIcon=&quot;number&quot; let-index=&quot;index&quot;&gt;
    &lt;div&gt;{{"{{index + 1}}"}}&lt;/div&gt;
  &lt;/ng-template&gt;
  &lt;ng-template matStepperIcon=&quot;edit&quot;&gt;
    &lt;eff-svg-check&gt;&lt;/eff-svg-check&gt;
  &lt;/ng-template&gt;
  &lt;mat-step completed=&quot;false&quot; label=&quot;Step 1&quot;&gt;
    &lt;button class=&quot;primary&quot; (click)=&quot;stepper.selected.completed = true; stepper.next();&quot;&gt;Next&lt;/button&gt;
  &lt;/mat-step&gt;
  &lt;mat-step completed=&quot;false&quot; label=&quot;Step 2&quot;&gt;
    &lt;div&gt;
      &lt;button class=&quot;link&quot; (click)=&quot;stepper.previous()&quot;&gt;Back&lt;/button&gt;
      &lt;button class=&quot;primary&quot; (click)=&quot;stepper.selected.completed = true; stepper.next();&quot;&gt;Next&lt;/button&gt;
    &lt;/div&gt;
  &lt;/mat-step&gt;
  &lt;mat-step completed=&quot;false&quot; label=&quot;Step 3&quot;&gt;
    &lt;div&gt;
      &lt;button class=&quot;link&quot; (click)=&quot;stepper.previous()&quot;&gt;Back&lt;/button&gt;
      &lt;button class=&quot;secondary&quot; (click)=&quot;stepper.reset()&quot;&gt;Reset&lt;/button&gt;
    &lt;/div&gt;
  &lt;/mat-step&gt;
&lt;/mat-stepper&gt;
</code>
      </pre>
    </details>
    <mat-button-toggle-group [(ngModel)]="orientation">
      <mat-button-toggle value="horizontal">Horizontal</mat-button-toggle>
      <mat-button-toggle value="vertical">Vertical</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-stepper class="stepper" [orientation]="orientation" linear #stepper>
      <ng-template matStepperIcon="number" let-index="index">
        <div>{{index + 1}}</div>
      </ng-template>
      <ng-template matStepperIcon="edit">
        <eff-svg-check></eff-svg-check>
      </ng-template>
      <mat-step completed="false" label="Step 1">
        <button class="primary" (click)="stepper.selected.completed = true; stepper.next();">Next</button>
      </mat-step>
      <mat-step completed="false" label="Step 2">
        <div>
          <button class="link" (click)="stepper.previous()">Back</button>
          <button class="primary" (click)="stepper.selected.completed = true; stepper.next();">Next</button>
        </div>
      </mat-step>
      <mat-step completed="false" label="Step 3">
        <div>
          <button class="link" (click)="stepper.previous()">Back</button>
          <button class="secondary" (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>