import { Component } from "@angular/core";

@Component({
  selector: "app-inputs",
  templateUrl: "./inputs.component.html",
  styleUrls: ["./inputs.component.scss"]
})
export class InputsComponent {
  public searchResultCount = 0;
  public activeFocusItem = 0;
  public searchTerm = "";
  public placeholder = "Placeholder text";
  public displayIndex = 0;
  public searchInput: string;

  getSearchTerm(searchStr: string): void {
    this.searchTerm = searchStr;
    this.searchResultCount = 5;
  }

  public getIndex(index: number): void {
    this.displayIndex = index;
  }
}
