import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { SelectComponent } from "./select.component";
import { SelectVirtualComponent } from "./select-virtual.component";
import { SelectLanguageComponent } from "./select-language.component";
import { SvgModule } from "@effectory/branding-angular/shared";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MtxTooltipModule } from "@ng-matero/extensions/tooltip";
import { LoaderModule } from "@effectory/branding-angular/shared";

@NgModule({
  declarations: [
    SelectComponent,
    SelectVirtualComponent,
    SelectLanguageComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SvgModule,
    TranslateModule,
    MatTooltipModule,
    ScrollingModule,
    MtxTooltipModule,
    LoaderModule
  ],
  exports: [
    SelectComponent,
    SelectVirtualComponent,
    SelectLanguageComponent
  ]
})
export class SelectModule { }
