// @ts-ignore
import * as shaka from "shaka-player/dist/shaka-player.ui.js";
import {Injectable} from "@angular/core";


@Injectable()
export class ShakaWrapperUtility {
  public create(htmlVideoDomElement): IShakaPlayer {
    return new shaka.Player(htmlVideoDomElement);
  }

  public installPolyfills(): void {
    shaka.polyfill.installAll();
  }

  public isBrowserSupported(): boolean {
    return shaka.Player.isBrowserSupported();
  }

  public createUIOverlay(player: IShakaPlayer, videoContainerDomElement, videoDomElement): IOverlay {
    return new shaka.ui.Overlay(player,
      videoContainerDomElement,
      videoDomElement);
  }
}

export declare interface IShaka {
  polyfill: IShakaPolyfill;
  ui: IShakaUI;
}

export declare interface IShakaPolyfill {
  installAll(): void;
}

export declare interface IShakaPlayer extends EventTarget {
  load(assetUri: string, startTimeout: number, mimeType: string): Promise<void>;

  getTextTracks(): ShakaTextTrackInterface[];

  selectTextTrack(language: ShakaTextTrackInterface): void;

  configure(configuration): void;
}

export declare interface IShakaUI {
  Overlay(player: IShakaPlayer, videoContainerDomElement, videoDomElement): IOverlay;
}

export declare interface IOverlay {
  getControls(): IShakaControls;

  configure(configuration): void;
}

export declare interface IShakaControls {
  toggleFullScreen(): void;
}

export declare interface ShakaTextTrackInterface {
  language: string;
  active: boolean;
}
