<div class="eff-qm-media">
  <div class="video-container">
    <div class="video-overlay" *ngIf="!isVideoLoaded" (click)="playVideo()" role="button"
         [attr.aria-label]="'shell.video.play' | translate" tabindex="0">
      <img [ngClass]="{'loading': !imageElement.complete}"
           loading="lazy"
           sizes="(max-width: 1400px) 100vw, 1400px"
           [srcset]="srcSet$ | async"
           [src]="defaultImageSrc$ | async"
           [attr.alt]="alternateTitle"
           (error)="onImageFailed($event)"
           #imageElement
      >
      <div class="play-button">
        <ng-container *ngIf="!isLoading; else loadingBlock">
          <div class="tri" *ngIf="!videoPlayerFailed; else retryButtonBlock"></div>
          <ng-template #retryButtonBlock>
            <eff-svg-rotate-ccw></eff-svg-rotate-ccw>
          </ng-template>
        </ng-container>
        <ng-template #loadingBlock>
          <div class="loader-inline"></div>
        </ng-template>
      </div>
    </div>
    <div class="play-video-mobile">
      <button class="secondary" (click)="playVideo()" *ngIf="!isVideoLoaded">{{'shell.video.play' | translate}}
      </button>
    </div>
    <div class="video-player" [ngClass]="{'hide-player': !isVideoLoaded}" [attr.data-subtitle]="selectedSubtitle$ | async">
      <div class="shaka-player" #videoPlayer [ngClass]="{feedback: isVideoFinished && !isVideoPlaying && showFeedback}">
        <video autoplay #videoElement></video>
      </div>
      <eff-video-feedback *ngIf="isVideoFinished && !isVideoPlaying && showFeedback"
                          [videoOptions]="srcVideoOptions"
                          (feedbackSent)="onFeedback($event)"></eff-video-feedback>
    </div>
  </div>
</div>
