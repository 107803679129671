export interface EffectoryOption {
  text: string;
  description?: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  dataPreview?: string[];
}

export interface EffectoryOptionGroup {
  label?: string;
  options: EffectoryOption[];
  hidden?: boolean;
}
