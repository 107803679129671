import { DesignPreviewComponent } from "./design-preview/design-preview.component";
import { SteppersComponent } from "./steppers/steppers.component";
import { TabsComponent } from "./tabs/tabs.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessibilityComponent } from "./accessibility/accessibility.component";
import { BreakpointsComponent } from "./breakpoints/breakpoints.component";
import { ColorsComponent } from "./colors/colors.component";
import { TooltipsComponent } from "./tooltips/tooltips.component";
import { DialogsComponent } from "./dialogs/dialogs.component";
import { DropdownsComponent } from "./dropdowns/dropdowns.component";
import { IconsComponent } from "./icons/icons.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { LoadersComponent } from "./loaders/loaders.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { TablesComponent } from "./tables/tables.component";
import { TypographyComponent } from "./typography/typography.component";
import { CardsComponent } from "./cards/cards.component";
import { IllustrationsComponent } from "./illustrations/illustrations.component";
import { ShadowsComponent } from "./shadows/shadows.component";
import { ButtonsComponent } from "./buttons/buttons.component";
import { RadioButtonsComponent } from "./radio-buttons/radio-buttons.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { TogglesComponent } from "./toggles/toggles.component";
import { InputsComponent } from "./inputs/inputs.component";
import { SelectsComponent } from "./selects/selects.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { TagsComponent } from "./tags/tags.component";
import { FooterComponent } from "./footer/footer.component";
import { AccordionsComponent } from "./accordions/accordions.component";
import { FloatBarComponent } from "./float-bar/float-bar.component";
import { VideoComponent } from "./video/video.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "styles",
    pathMatch: "full"
  },
  {
    path: "styles",
    component: LandingPageComponent,
    data: {
      showInTopbar: true,
      name: "Styleguide"
    },
    children: [
      {
        path: "accessibility",
        component: AccessibilityComponent,
        data: {
          name: "Accessibility"
        }
      },
      {
        path: "colors",
        component: ColorsComponent,
        data: {
          name: "Colors"
        }
      },
      {
        path: "icons",
        component: IconsComponent,
        data: {
          name: "Icons"
        }
      },
      {
        path: "breakpoints",
        component: BreakpointsComponent,
        data: {
          name: "Layout"
        }
      },
      {
        path: "shadows",
        component: ShadowsComponent,
        data: {
          name: "Shadows"
        }
      },
      {
        path: "typography",
        component: TypographyComponent,
        data: {
          name: "Typography"
        }
      }
    ]
  },
  {
    path: "components",
    component: LandingPageComponent,
    data: {
      showInTopbar: true,
      name: "Components"
    },
    children: [
      {
        path: "accordions",
        data: {
          name: "Accordions"
        },
        component: AccordionsComponent
      },
      {
        path: "buttons",
        data: {
          name: "Buttons"
        },
        component: ButtonsComponent
      },
      {
        path: "cards",
        component: CardsComponent,
        data: {
          name: "Cards"
        }
      },
      {
        path: "checkbox",
        component: CheckboxComponent,
        data: {
          name: "Checkbox"
        }
      },
      {
        path: "design-preview",
        component: DesignPreviewComponent,
        data: {
          name: "Design preview"
        }
      },
      {
        path: "dialogs",
        component: DialogsComponent,
        data: {
          name: "Dialogs"
        }
      },
      {
        path: "dropdowns",
        component: DropdownsComponent,
        data: {
          name: "Dropdowns"
        }
      },
      {
        path: "error-page",
        component: ErrorPageComponent,
        data: {
          name: "Error page"
        }
      },
      {
        path: "float-bar",
        component: FloatBarComponent,
        data: {
          name: "Float Bar"
        }
      },
      {
        path: "footer",
        component: FooterComponent,
        data: {
          name: "Footer"
        }
      },
      {
        path: "illustrations",
        component: IllustrationsComponent,
        data: {
          name: "Illustrations"
        }
      },
      {
        path: "inputs",
        component: InputsComponent,
        data: {
          name: "Inputs"
        }
      },
      {
        path: "loaders",
        component: LoadersComponent,
        data: {
          name: "Loaders"
        }
      },
      {
        path: "notifications",
        component: NotificationsComponent,
        data: {
          name: "Notifications"
        }
      },
      {
        path: "radio-buttons",
        component: RadioButtonsComponent,
        data: {
          name: "Radio buttons"
        }
      },
      {
        path: "selects",
        component: SelectsComponent,
        data: {
          name: "Selects"
        }
      },
      {
        path: "steppers",
        component: SteppersComponent,
        data: {
          name: "Steppers"
        }
      },
      {
        path: "tables",
        component: TablesComponent,
        data: {
          name: "Tables"
        }
      },
      {
        path: "tabs",
        component: TabsComponent,
        data: {
          name: "Tabs"
        }
      },
      {
        path: "tags",
        component: TagsComponent,
        data: {
          name: "Tags"
        }
      },
      {
        path: "toggles",
        component: TogglesComponent,
        data: {
          name: "Toggles"
        }
      },
      {
        path: "tooltips",
        component: TooltipsComponent,
        data: {
          name: "Tooltips"
        }
      },
      {
        path: "videos",
        component: VideoComponent,
        data: {
          name: "Video"
        }
      }
    ]
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule { }
