<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Tooltips</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;span [matTooltip]="'This is a tooltip! (top)'" matTooltipPosition="above">Hover tooltip (top)&lt;/span>

&lt;span [matTooltip]="'This is a tooltip! (left)'" matTooltipPosition="below">Hover tooltip (left)&lt;/span>

&lt;span [matTooltip]="'This is a tooltip! (right)'" matTooltipPosition="below">Hover tooltip (right)&lt;/span>

&lt;span [matTooltip]="'This is a tooltip! (bottom)'" matTooltipPosition="below">Hover tooltip (bottom)&lt;/span>
</code>
</pre>
      <p>You can also use our own tooltip, if you need more than plain text:</p>
      <pre>
<code>
&lt;span effTooltipDirective style="position: relative">
Hover tooltip (top / default)
&lt;ng-template #tooltipTemplate>
  &lt;div class="tooltip">
    &lt;span>This is a &lt;strong>tooltip!&lt;/strong>&lt;/span>
  &lt;/div>
&lt;/ng-template>
&lt;/span>
&lt;span effTooltipDirective style="position: relative">
Hover tooltip (bottom)
&lt;ng-template #tooltipTemplate>
  &lt;div class="tooltip bottom">
    &lt;span>This is a &lt;strong>tooltip!&lt;/strong>&lt;/span>
  &lt;/div>
&lt;/ng-template>
&lt;/span>
</code>
</pre>
    </details>
    <span [matTooltip]="'This is a tooltip! (top)'" matTooltipPosition="above">
      Hover tooltip (top)
    </span>
    <br>
    <span [matTooltip]="'This is a tooltip! (left)'" matTooltipPosition="left">
      Hover tooltip (left)
    </span>
    <br>
    <span [matTooltip]="'This is a tooltip! (right)'" matTooltipPosition="right">
      Hover tooltip (right)
    </span>
    <br>
    <span [matTooltip]="'This is a tooltip! (bottom)'" matTooltipPosition="below">
      Hover tooltip (bottom)
    </span>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Guided tooltip</h2>
  </div>
  <div class="card-content-container">
    <p>Available properties:</p>
    <ul>
      <li>[targetElement] - ElementRef</li>
      <li>[dialogTitle] - string</li>
      <li>[dialogSubtitle] - string (with HTML)</li>
      <li>[dialogButtonText] - string</li>
      <li>[dialogLinkButtonText] - string</li>
      <li class="deprecated">[dialogTooltipPosition] - 'left' | 'right'</li>
      <li>[dialogPositionToTarget] - 'above' | 'below' | 'before' | 'after'</li>
      <li>[dialogAlignmentToTarget] - 'start' | 'end'</li>
      <li>[dialogWidth] - number</li>
      <li>[dialogProgressionStageCurrent] - number</li>
      <li>[dialogProgressionStageTotal] - number</li>
      <li>[addOutlineToTargetElement] - boolean</li>
      <li>[targetElementOutlineColor] - string</li>
      <li>[addHighlightToTargetElement] - boolean</li>
      <li>[showDialogBackgroundOverlay] - boolean</li>
      <li>[svgUrl] - string (use only with 'with-svg' class)</li>
      <li>[arrowColor] - string (use only with 'with-svg' class)</li>
      <li>(dialogClosedOutput) - boolean</li>
    </ul>
    <details>
      <summary>Code</summary>
      <pre>
        <code>
&lt;!-- in HTML -->
&lt;eff-tooltip-dialog
  [targetElement]="yourTargetElement"
  [dialogTitle]="'Title'"
  [dialogSubtitle]="'This is a guided tooltip subtitle text'"
  [dialogButtonText]="'Close'"
  [dialogTooltipPosition]="'right'"
  [dialogWidth]=300
  [addOutlineToTargetElement]="true"
  [addHighlightToTargetElement]="true"
  >
&lt;/eff-tooltip-dialog>

&lt;!-- in TS -->
@ViewChild("yourTargetElement") public yourTargetElement: ElementRef;


        </code>
      </pre>
    </details>
    <button class="secondary" #yourTargetElement (click)="showTooltipDialog = true">Display tooltip</button>
    <br>

    <p>Add the class 'with-svg' to the tooltip and you can add an illustration to the tooltip with 'svgUrl'</p>
    <button class="secondary" #yourSvgTargetElement (click)="showSvgTooltipDialog = true">Display tooltip</button>
    <details>
      <summary>Code</summary>
      <pre>
        <code>
&lt;!-- in HTML -->
&lt;button class="secondary" #yourSvgTargetElement (click)="showSvgTooltipDialog = true">Display tooltip&lt;/button>

&lt;eff-tooltip-dialog
  *ngIf="showSvgTooltipDialog"
  class="with-svg"
  [targetElement]="svgTargetButton"
  [dialogTitle]="'Dialog title'"
  [dialogSubtitle]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a enim ac mauris fermentum commodo.'"
  [dialogButtonText]="'Close'"
  [dialogLinkButtonText]="'Got it'"
  [dialogTooltipPosition]="'right'"
  [dialogWidth]=336
  [addOutlineToTargetElement]="false"
  [addHighlightToTargetElement]="false"
  [svgUrl]="'assets/img/home-illustration.svg'"
  (dialogClosedOutput)="showSvgTooltipDialog = false">
&lt;/eff-tooltip-dialog>

&lt;!-- in TS -->
@ViewChild("yourSvgTargetElement") public svgTargetButton: ElementRef;
        </code>
      </pre>
    </details>
    <br><br>

    <p>Include both properties "dialogProgressionStageCurrent" and "dialogProgressionStageTotal" to show a progression indicator if the tooptip information has multiple steps.</p>
    <button class="secondary" #tooltipTargetOne (click)="showTooltipDialogWithProgressPartOne = true">Start tooltip progression</button>
    <button class="secondary" #tooltipTargetTwo (click)="tourTooltipWithCallToAction.closeDialog()" [disabled]="!showTooltipDialogWithProgressPartTwo">Some button to click</button>
    <button class="secondary" #tooltipTargetThree [disabled]="true">Some other element</button>

    <eff-tooltip-dialog
      *ngIf="showTooltipDialog"
      [targetElement]="targetButton"
      [dialogTitle]="'Dialog title'"
      [dialogSubtitle]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a enim ac mauris fermentum commodo.'"
      [dialogButtonText]="'Close'"
      [dialogTooltipPosition]="'right'"
      [dialogWidth]=300
      [addOutlineToTargetElement]="true"
      [addHighlightToTargetElement]="true"
      (dialogClosedOutput)="showTooltipDialog = false">
    </eff-tooltip-dialog>

    <eff-tooltip-dialog
      *ngIf="showSvgTooltipDialog"
      class="with-svg"
      [targetElement]="svgTargetButton"
      [dialogTitle]="'Dialog title'"
      [dialogSubtitle]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a enim ac mauris fermentum commodo.'"
      [dialogButtonText]="'Close'"
      [dialogLinkButtonText]="'Got it'"
      [dialogTooltipPosition]="'right'"
      [dialogWidth]=336
      [addOutlineToTargetElement]="false"
      [addHighlightToTargetElement]="false"
      [showDialogBackgroundOverlay]="false"
      [svgUrl]="'assets/img/home-illustration.svg'"
      (dialogClosedOutput)="showSvgTooltipDialog = false">
    </eff-tooltip-dialog>

    <eff-tooltip-dialog
      *ngIf="showTooltipDialogWithProgressPartOne"
      [targetElement]="targetOne"
      [dialogTitle]="'Tooltip Part 1'"
      [dialogSubtitle]="'This is the first part of the tooltip progression. Lorem ipsum dolor sit amet, consectetur adipiscing elit.'"
      [dialogButtonText]="'Next'"
      [dialogPositionToTarget]="'below'"
      [dialogAlignmentToTarget]="'start'"
      [dialogProgressionStageCurrent]="1"
      [dialogProgressionStageTotal]="3"
      [dialogWidth]=300
      [addOutlineToTargetElement]="true"
      [addHighlightToTargetElement]="true"
      (dialogClosedOutput)="showTooltipDialogWithProgressPartTwo = true; showTooltipDialogWithProgressPartOne = false;">
    </eff-tooltip-dialog>
    <eff-tooltip-dialog
      *ngIf="showTooltipDialogWithProgressPartTwo"
      #tooltipWithCallToAction
      [targetElement]="targetTwo"
      [dialogTitle]="'Tooltip Part 2'"
      [dialogSubtitle]="'This is the second part of the tooltip progression. Aliquam a enim ac mauris fermentum commodo.'"
      [dialogPositionToTarget]="'above'"
      [dialogAlignmentToTarget]="'start'"
      [dialogProgressionStageCurrent]="2"
      [dialogProgressionStageTotal]="3"
      [dialogWidth]=300
      [addOutlineToTargetElement]="true"
      [addHighlightToTargetElement]="true"
      [withoutButtons]="true"
      (dialogClosedOutput)="showTooltipDialogWithProgressPartThree = true; showTooltipDialogWithProgressPartTwo = false;">

      <div>Click the button</div>

    </eff-tooltip-dialog>
    <eff-tooltip-dialog
      *ngIf="showTooltipDialogWithProgressPartThree"
      [targetElement]="targetThree"
      [dialogTitle]="'Tooltip Part 3'"
      [dialogSubtitle]="'This is the third part of the tooltip progression. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'"
      [dialogButtonText]="'Finish'"
      [dialogPositionToTarget]="'above'"
      [dialogAlignmentToTarget]="'end'"
      [dialogProgressionStageCurrent]="3"
      [dialogProgressionStageTotal]="3"
      [dialogWidth]=300
      [addOutlineToTargetElement]="true"
      [addHighlightToTargetElement]="true"
      (dialogClosedOutput)="showTooltipDialogWithProgressPartThree = false;">
    </eff-tooltip-dialog>


    <details>
      <summary>Code</summary>
      <pre>
        <code>
&lt;!-- in HTML -->
&lt;button class="secondary" #tooltipTargetOne (click)="showTooltipDialogWithProgressPartOne = true">Start tooltip progression&lt;/button>
&lt;button class="secondary" #tooltipTargetTwo (click)="tourTooltipWithCallToAction.closeDialog()"
  [disabled]="!showTooltipDialogWithProgressPartTwo">Some button to click&lt;/button>
&lt;button class="secondary" #tooltipTargetThree [disabled]="true">Some other element&lt;/button>

&lt;eff-tooltip-dialog
  *ngIf="showTooltipDialogWithProgressPartOne"
  [targetElement]="targetOne"
  [dialogTitle]="'Tooltip Part 1'"
  [dialogSubtitle]="'This is the first part of the tooltip progression. Lorem ipsum dolor sit amet, consectetur adipiscing elit.'"
  [dialogButtonText]="'Next'"
  [dialogPositionToTarget]="'below'"
  [dialogAlignmentToTarget]="'start'"
  [dialogProgressionStageCurrent]="1"
  [dialogProgressionStageTotal]="3"
  [dialogWidth]=300
  [addOutlineToTargetElement]="true"
  [addHighlightToTargetElement]="true"
  (dialogClosedOutput)="showTooltipDialogWithProgressPartTwo = true; showTooltipDialogWithProgressPartOne = false;">
&lt;/eff-tooltip-dialog>

&lt;eff-tooltip-dialog
  *ngIf="showTooltipDialogWithProgressPartTwo"
  #tooltipWithCallToAction
  [targetElement]="targetTwo"
  [dialogTitle]="'Tooltip Part 2'"
  [dialogSubtitle]="'This is the second part of the tooltip progression. Aliquam a enim ac mauris fermentum commodo.'"
  [dialogPositionToTarget]="'above'"
  [dialogAlignmentToTarget]="'start'"
  [dialogProgressionStageCurrent]="2"
  [dialogProgressionStageTotal]="3"
  [dialogWidth]=300
  [addOutlineToTargetElement]="true"
  [addHighlightToTargetElement]="true"
  [withoutButtons]="true"
  (dialogClosedOutput)="showTooltipDialogWithProgressPartThree = true; showTooltipDialogWithProgressPartTwo = false;">
  &lt;!-- Insert here your template -->
  &lt;div>Click the button&lt;/div>
&lt;/eff-tooltip-dialog>

&lt;eff-tooltip-dialog
  *ngIf="showTooltipDialogWithProgressPartThree"
  [targetElement]="targetThree"
  [dialogTitle]="'Tooltip Part 3'"
  [dialogSubtitle]="'This is the third part of the tooltip progression. Excepteur sint occaecat cupidatat non proident.'"
  [dialogButtonText]="'Finish'"
  [dialogPositionToTarget]="'above'"
  [dialogAlignmentToTarget]="'end'"
  [dialogProgressionStageCurrent]="3"
  [dialogProgressionStageTotal]="3"
  [dialogWidth]=300
  [addOutlineToTargetElement]="true"
  [addHighlightToTargetElement]="true"
  (dialogClosedOutput)="showTooltipDialogWithProgressPartThree = false;">
&lt;/eff-tooltip-dialog>

&lt;!-- in TS -->
@ViewChild("tooltipTargetOne") public targetOne: ElementRef;
@ViewChild("tooltipTargetTwo") public targetTwo: ElementRef;
@ViewChild("tooltipTargetThree") public targetThree: ElementRef;
@ViewChild("tooltipWithCallToAction") public tourTooltipWithCallToAction: TourTooltipComponent;

public showTooltipDialogWithProgressPartOne = false;
public showTooltipDialogWithProgressPartTwo = false;
public showTooltipDialogWithProgressPartThree = false;
        </code>
      </pre>
    </details>
  </div>
</div>
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
