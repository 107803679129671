<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Accordions</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-expansion-panel>
  &lt;mat-expansion-panel-header>
    &lt;span class="text-l5">Example accordion&lt;/span>
  &lt;/mat-expansion-panel-header>
  &lt;ng-template matExpansionPanelContent>
    &lt;span>This is the primary content of the panel.&lt;/span>
  &lt;/ng-template>
&lt;/mat-expansion-panel>
</code>
</pre>
    </details>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span class="text-l5">Example accordion</span>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <span>This is the primary content of the panel.</span>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel id="example-1">
      <mat-expansion-panel-header>
        <div class="changes-summary">
          <div class="icon-wrapper">
            <eff-svg-plus class="icon"></eff-svg-plus>
          </div>
          <div class="text-wrapper">
            <span class="text-l5">12 coordinators will be asssigned</span>
            <span class="text-subdued">Once survey results are available, the coordinators will receive an e-mail invitation to access them.</span>
          </div>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <table mat-table [dataSource]="rows" class="gray-header">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">{{row.properties[0]}}</td>
          </ng-container>

          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef>Function</th>
            <td mat-cell *matCellDef="let row">{{row.properties[1]}}</td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let row">{{row.properties[2]}}</td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef>Gender</th>
            <td mat-cell *matCellDef="let row">{{row.properties[3]}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
        </table>
      </ng-template>
    </mat-expansion-panel>
  </div>
</div>
