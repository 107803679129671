<div class="card">
  <div class="card-title-container">
    <h3 class="text-l5 card-title">Error page (during loading)</h3>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-error (refreshClicked)="refreshPage()" *ngIf="loadingFailed" &lt;/eff-error>
</code>
</pre>
    </details>
    <eff-error></eff-error>
  </div>
</div>
