import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "eff-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})
export class ErrorComponent {
  @Output() public refreshClicked = new EventEmitter<MouseEvent>();

  public sendRefreshEvent(event: MouseEvent): void {
    this.refreshClicked.emit(event);
  }
}
