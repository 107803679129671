import { DateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgModule } from "@angular/core";
import { DatepickerComponent } from "./datepicker.component";
import { FormsModule } from "@angular/forms";
import { SvgModule } from "@effectory/branding-angular/shared";

@NgModule({
  declarations: [
    DatepickerComponent
  ],
  imports: [
    FormsModule,
    TranslateModule,
    SvgModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  exports: [
    DatepickerComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    }
  ]
})
export class DatepickerModule {}
