import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tables",
  templateUrl: "./tables.component.html",
  styleUrls: ["./tables.component.scss"]
})
export class TablesComponent implements OnInit {

  public displayedColumns = ["name", "function", "location", "gender"];

  public headers = [
    "Name",
    "Function",
    "Location",
    "Gender"
  ];

  public rows = [
    {
      properties: [
        "Jesse",
        "Boss",
        "Koog aan de Zaan",
        "Male"
      ]
    },
    {
      properties: [
        "Ramon",
        "Chief of Front-End",
        "Hoorn",
        "Male"
      ]
    },
    {
      properties: [
        "Roy",
        "Developer",
        "Almere",
        "Male"
      ]
    }
  ];

  public ngOnInit() {
  }

}
