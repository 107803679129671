import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "eff-float-bar",
  templateUrl: "./float-bar.component.html",
  styleUrls: ["./float-bar.component.scss"]
})
export class FloatBarComponent {
  @Input() public withCheckbox: boolean = false;
  @Input() public title: string;
  @Input() public checked: boolean;
  @Input() public intermediate: boolean;
  @Output() public checkboxChange = new EventEmitter<boolean>();

  public handleCheckChange($event) {
    this.checkboxChange.emit($event);
  }
}
