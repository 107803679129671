<div class="design-container" [class.mobile]="mobileVersion">
  <div *ngIf="showTag" class="design-header-tile tag">
    <span>{{ 'shell.mailing' | translate }}</span>
  </div>
  <div class="screen-container mailing">
    <div class="logo-container">
      <img *ngIf="logoUrl" class="logo" [src]="logoUrl" [alt]="'shell.mailing-logo-alt' | translate" />
    </div>
    <div class="line short"></div>
    <div class="line medium double-margin"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line short"></div>
    <div *ngIf="internetSpiegel" class="extra-lines-IS">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line short"></div>
    </div>
    <div class="dummy-btn" [class.dummy-btn-IS]="internetSpiegel" [ngStyle]="getPrimaryColor()"></div>
    <img *ngIf="!internetSpiegel && backgroundImageUrl" class="bg-image" [src]="backgroundImageUrl" aria-hidden="true"
    />
    <div class="line light"></div>
    <div class="line light"></div>
    <div class="line light short"></div>
  </div>
  <div class="disclamer-container">
    <eff-svg-info class="icon"></eff-svg-info>
    <p class="text-subdued">{{ 'shell.mailing-disclaimer' | translate }}</p>
  </div>
</div>
