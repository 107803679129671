import { Component, OnInit } from "@angular/core";

@Component({
  selector: "eff-svg-work-in-progress",
  templateUrl: "../../../../../node_modules/@effectory/branding-styles/dist/svg/work-in-progress.svg"
})
export class WorkInProgressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
