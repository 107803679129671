<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Float Bar</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-float-bar>
  &lt;!-- Start of your content -->
  &lt;button>Close float bar&lt;/button>
  &lt;!-- End of your content -->
&lt;/eff-float-bar>
</code>
</pre>
    </details>

    <button class="primary" (click)="isFloatBarVisible = true">Open float bar</button>
    <eff-float-bar *ngIf="isFloatBarVisible">
      <button (click)="isFloatBarVisible = false">Close float bar</button>
    </eff-float-bar>

    <br>
    <br>

    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-float-bar
  [withCheckbox]="true"
  [checked]="isAllChecked"
  [intermediate]="areSomeChecked"
  (checkboxChange)="selectAll($event)"
  [title]="'6 employee(s) selected'">
  &lt;!-- Start of your content -->
  &lt;button>Close float bar&lt;/button>
  &lt;!-- End of your content -->
&lt;/eff-float-bar>
</code>
</pre>
    </details>
    <button class="primary" (click)="isFloatBarWithCheckboxVisible = true">Open float bar with checkbox</button>
    <eff-float-bar *ngIf="isFloatBarWithCheckboxVisible" [withCheckbox]="true" [checked]="isAllChecked" [intermediate]="areSomeChecked" (checkboxChange)="selectAll($event)" [title]="checkedCount + ' employee(s) selected'">
      <button (click)="isFloatBarWithCheckboxVisible = false">Close float bar</button>
    </eff-float-bar>

    <br>
  </div>
</div>
