<div class="card">
  <div class="card-content-container">
    <p>
      The dropdown we use over here is the menu component from the Angular Material library. <br />
      You can find the documentation of the mat-menu <a href="https://material.angular.io/components/menu/overview" target="_BLANK">here</a>
    </p>
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;button tabindex="0" mat-button
class="support-nav-btn profile-menu-desktop"
#ProfileMenuTrigger="matMenuTrigger"
[matMenuTriggerFor]="profileMenu">
  Open dropdown
&lt;/button>

&lt;mat-menu #profileMenu="matMenu">
  &lt;button mat-menu-item disableRipple>
    &lt;eff-svg-user class="icon">
    &lt;/eff-svg-user>
    &lt;span>Open profile&lt;/span>
  &lt;/button>
  &lt;button mat-menu-item disableRipple>
    &lt;eff-svg-users class="icon">
    &lt;/eff-svg-users>
    &lt;span>Switch organization&lt;/span>
  &lt;/button>
  &lt;button mat-menu-item disableRipple>
    &lt;eff-svg-globe class="icon">
    &lt;/eff-svg-globe>
    &lt;span>Change language&lt;/span>
  &lt;/button>
  &lt;button mat-menu-item disableRipple>
    &lt;eff-svg-log-out class="icon">
    &lt;/eff-svg-log-out>
    &lt;span>Log out&lt;/span>
  &lt;/button>
&lt;/mat-menu>
</code>
</pre>
    </details>
    <button class="primary" tabindex="0" mat-button #ProfileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profileMenu">
      Open menu
    </button>

    <mat-menu #profileMenu="matMenu">
      <button mat-menu-item disableRipple>
        <eff-svg-user class="icon"></eff-svg-user>
        <span>Open profile</span>
      </button>
      <button mat-menu-item disableRipple>
        <eff-svg-users class="icon"></eff-svg-users>
        <span>Switch organization</span>
      </button>
      <button mat-menu-item disableRipple>
        <eff-svg-globe class="icon"></eff-svg-globe>
        <span>Change language</span>
      </button>
      <button mat-menu-item disableRipple>
        <eff-svg-log-out class="icon"></eff-svg-log-out>
        <span>Log out</span>
      </button>
    </mat-menu>
  </div>
</div>
