import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectComponent } from "./select.component";

interface TriggerValue {
  iconSource: string;
  text: string;
}

@Component({
  selector: "eff-select-language",
  templateUrl: "./select-language.component.html",
  styleUrls: ["./select-language.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectLanguageComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectLanguageComponent,
      multi: true
    }
  ]
})

export class SelectLanguageComponent extends SelectComponent implements OnInit{
  public triggerValues: TriggerValue[];

  constructor(private translateService: TranslateService) {
    super();
  }

  public writeValue(values: string[] | string): void {
    super.writeValue(values);
    this.updateTriggerValues();
  }

  public updateTriggerValues(): void {
    if (this.selectedValues === null || this.selectedValues === undefined) {
      this.triggerValues = undefined;
      return;
    }
    if (Array.isArray(this.selectedValues)) {
      this.triggerValues = this.selectedValues.map((val, i) => ({
        iconSource: this.getIconSource(val),
        text: this.options.find(x => x.value === val).text + (i < this.selectedValues.length-1 ? "," : "")
      }));
      return;
    }
    this.triggerValues = [{
      iconSource: this.getIconSource(this.selectedValues as string),
      text: this.options.find(x => x.value === this.selectedValues).text
    }];
  }

  public getIconSource(cultureCode: string): string {
    if (cultureCode === undefined) {
      return null;
    }
    const countryCode = cultureCode.split("-").slice(-1)[0].toUpperCase();
    const source = `assets/svg/${countryCode}.svg`;
    return source;
  }

  public ngOnInit(): void {
    this.options.forEach(option => {
      this.translateService.get("shell." + option.value.toLowerCase()).subscribe(r => option.text = r).unsubscribe();
    });
    this.updateTriggerValues();
  }
}
