<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Shadows</h2>
  </div>
  <div class="card-content-container">
    <div class="shadow">
      $shadow-100
    </div>
    <div class="shadow">
      $shadow-300
    </div>
    <div class="shadow">
      $shadow-500
    </div>
    <div class="shadow">
      $shadow-700
    </div>
    <div class="shadow">
      $shadow-900
    </div>
    <div class="shadow">
      $shadow-000<br>(footer)
    </div>
    <div class="shadow">
      $shadow-table-left<br>(table-cell)
    </div>
    <div class="shadow">
      $shadow-table-right<br>(table-cell)
    </div>
  </div>
</div>
