import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "eff-mailing-content-preview",
  templateUrl: "./mailing-content-preview.component.html",
  styleUrls: ["./mailing-content-preview.component.scss"]
})
export class MailingContentPreviewComponent implements OnInit {
  @Input() public logoUrl: string;
  @Input() public privacyHeader: string;
  @Input() public titleText: string;
  @Input() public subtitle: string;
  @Input() public introductionContent: string;
  @Input() public showIntroductionContent: boolean;
  @Input() public body1Content: string;
  @Input() public body2Content: string;
  @Input() public buttonText: string;
  @Input() public buttonLink: string;
  @Input() public backgroundImageUrl: string;
  @Input() public footer: string;
  @Input() public primaryColor: string = "#30b3af";
  @Input() public showTag: boolean = false;

  public ngOnInit(): void {
    if(this.primaryColor == null) {
      return;
    }

    this.primaryColor = this.addHashWhenNeeded(this.primaryColor);
  }

  private addHashWhenNeeded(input: string): string {
    if (input?.indexOf("#") === -1) {
      input = `#${input}`;
    }

    return input;
  }
}
