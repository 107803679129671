import { Component } from "@angular/core";

@Component({
  selector: "app-float-bar",
  templateUrl: "./float-bar.component.html",
  styleUrls: ["./float-bar.component.scss"]
})
export class FloatBarComponent {
  public selectItems = [false, true, false, true, false, true, false, false, false];
  public isFloatBarVisible = false;
  public isFloatBarWithCheckboxVisible = false;

  public get isAllChecked(): boolean {
    return this.selectItems.every(c => c);
  }

  public get areSomeChecked(): boolean {
    return !this.selectItems.every(c => !c);
  }

  public get checkedCount(): number {
    return this.selectItems.filter(c => c).length;
  }

  public selectAll(event: boolean) {
    for (let i = 0; i < this.selectItems.length; i++) {
      this.selectItems[i] = event;
    }
  }
}
