<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Radio buttons</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-radio-button name="test" value="1">Option 1&lt;/mat-radio-button>
&lt;mat-radio-button name="test" value="2">Option 2&lt;/mat-radio-button>
&lt;mat-radio-button name="test" value="3" disabled>Option 3 (Disabled)&lt;/mat-radio-button>
&lt;mat-radio-button name="test2" value="4" disabled checked>Disabled (selected)&lt;/mat-radio-button>
&lt;mat-radio-button name="test3" value="1" class="no-label">&lt;/mat-radio-button>
&lt;mat-radio-button name="test3" value="2" class="no-label">&lt;/mat-radio-button>
</code>
</pre>
    </details>
    <p>
      <mat-radio-button name="test" value="1">Option 1</mat-radio-button><br>
      <mat-radio-button name="test" value="2">Option 2</mat-radio-button><br>
      <mat-radio-button name="test" value="3" disabled>Option 3 (Disabled)</mat-radio-button><br>
      <mat-radio-button name="test2" value="4" disabled checked>Disabled (selected)</mat-radio-button><br>
      <mat-radio-button name="test3" value="1" class="no-label"></mat-radio-button><br>
      <mat-radio-button name="test3" value="2" class="no-label"></mat-radio-button><br>
    </p>

    <h4 class="text-l5">Radio tiles</h4>
    <mat-radio-group id="example" name="example" [(ngModel)]="value">
      <div class="radio-tile" [ngClass]="{ 'selected': value === 1 }">
        <mat-radio-button class="no-label" [value]="1" id="value-1" aria-labelledby="value-1-label"></mat-radio-button>
        <label for="value-1-input" data-cy="mode-selection-button"></label>
        <div class="description">
          <span class="text-w500 title" id="value-1-label">Value 1</span>
          <span class="text-subdued text-small">Here you can put a small description of what value 1 exactly means.</span>
        </div>
      </div>
      <div class="radio-tile" [ngClass]="{ 'selected': value === 2 }">
        <mat-radio-button class="no-label" [value]="2" id="value-2" aria-labelledby="value-2-label"></mat-radio-button>
        <label for="value-2-input" data-cy="mode-selection-button"></label>
        <div class="description">
          <span class="text-w500 title" id="value-2-label">Value 2</span>
          <span class="text-subdued text-small">Here you can put a small description of what value 2 exactly means.</span>
        </div>
      </div>
      <div class="radio-tile" [ngClass]="{ 'selected': value === 3 }">
        <mat-radio-button class="no-label" [value]="3" id="value-3" aria-labelledby="value-3-label"></mat-radio-button>
        <label for="value-3-input" data-cy="mode-selection-button"></label>
        <div class="description">
          <span class="text-w500 title" id="value-3-label">Value 3</span>
          <span class="text-subdued text-small">Here you can put a small description of what value 3 exactly means.</span>
        </div>
      </div>
    </mat-radio-group>

    <details>
      <summary>Code</summary>
      <pre>
<code>
Add the 'centered' class to a 'radio-tile' element to center the checkbox instead of aligning it at the top.

&lt;mat-radio-group id="example" name="example" [(ngModel)]="value">
  &lt;div class="radio-tile" [ngClass]="&#123; 'selected': value === 1 &#125;">
    &lt;mat-radio-button class="no-label" [value]="1" id="value-1" aria-labelledby="value-1-label">&lt;/mat-radio-button>
    &lt;label for="value-1-input" data-cy="mode-selection-button">&lt;/label>
    &lt;div class="description">
      &lt;span class="text-w500 title" id="value-1-label">Value 1&lt;/span>
      &lt;span class="text-subdued text-small">Here you can put a small description of what value 1 exactly means.&lt;/span>
    &lt;/div>
  &lt;/div>
  &lt;div class="radio-tile" [ngClass]="&#123; 'selected': value === 2 &#125;">
    &lt;mat-radio-button class="no-label" [value]="2" id="value-2" aria-labelledby="value-2-label">&lt;/mat-radio-button>
    &lt;label for="value-2-input" data-cy="mode-selection-button">&lt;/label>
    &lt;div class="description">
      &lt;span class="text-w500 title" id="value-2-label">Value 2&lt;/span>
      &lt;span class="text-subdued text-small">Here you can put a small description of what value 2 exactly means.&lt;/span>
    &lt;/div>
  &lt;/div>
  &lt;div class="radio-tile" [ngClass]="&#123; 'selected': value === 3 &#125;">
    &lt;mat-radio-button class="no-label" [value]="3" id="value-3" aria-labelledby="value-3-label">&lt;/mat-radio-button>
    &lt;label for="value-3-input" data-cy="mode-selection-button">&lt;/label>
    &lt;div class="description">
      &lt;span class="text-w500 title" id="value-3-label">Value 3&lt;/span>
      &lt;span class="text-subdued text-small">Here you can put a small description of what value 3 exactly means.&lt;/span>
    &lt;/div>
  &lt;/div>
&lt;/mat-radio-group>
</code>
      </pre>
    </details>
  </div>
</div>
