import {Component, Input} from "@angular/core";
import {TooltipPosition} from "@angular/material/tooltip";

@Component({
  selector: "eff-beta-tag",
  templateUrl: "./beta-tag.component.html",
  styleUrls: ["./beta-tag.component.scss"]
})
export class BetaTagComponent {
  @Input()
  public tooltipPosition: TooltipPosition = "below";
}
