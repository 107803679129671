import { Component, ContentChild, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "eff-banner-notification",
  templateUrl: "./banner-notification.component.html",
  styleUrls: ["./banner-notification.component.scss"]
})
export class BannerNotificationComponent implements OnInit {
  @ContentChild(TemplateRef) illustration: TemplateRef<any>;

  @Input() public messageHeader: string;
  @Input() public messageTitle: string;
  @Input() public messageType: string;
  @Input() public message: string;

  public ngOnInit(): void {
  }

  public hasIllustration(): boolean {
    if (this.illustration)
    {
      return true;
    }
    return false;
  }
}
