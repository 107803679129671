import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MobileSidebarService } from "./mobile-sidebar/mobile-sidebar.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(public mobileSidebarService: MobileSidebarService, private translateService: TranslateService, public router: Router) {
    this.translateService.use("en-US");
  }
}
