import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TourTooltipComponent } from "./tooltip-dialog.component";

@NgModule({
  declarations: [
    TourTooltipComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TourTooltipComponent
  ]
})
export class TooltipDialogModule {}
