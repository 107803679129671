<div class="notification banner" [ngClass]="messageType" role="alert">
  <div class="content-wrapper">
    <div class="text-wrapper">
      <span class="text-l6 text-subdued" *ngIf="messageHeader?.length > 0">{{ messageHeader }}</span>
      <span class="title text-l5 text-w600" *ngIf="messageTitle?.length > 0">{{ messageTitle }}</span>
      <div *ngIf="hasIllustration(); then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <div class="illustration-wrapper">
          <ng-container *ngTemplateOutlet="illustration"></ng-container>
        </div>
      </ng-template>
      <ng-template #elseBlock>
        <div class="illustration-wrapper">
          <eff-svg-placeholder-illustration></eff-svg-placeholder-illustration>
        </div>
      </ng-template>
      <span class="message text-subdued" *ngIf="message?.length > 0">{{ message }}</span>
    </div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="hasIllustration(); then thenBlockDesktop else elseBlockDesktop"></div>
  <ng-template #thenBlockDesktop>
    <div class="illustration-wrapper-desktop">
      <ng-container *ngTemplateOutlet="illustration"></ng-container>
    </div>
  </ng-template>
  <ng-template #elseBlockDesktop>
    <div class="illustration-wrapper-desktop">
      <eff-svg-placeholder-illustration></eff-svg-placeholder-illustration>
    </div>
  </ng-template>
</div>
