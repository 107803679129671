import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-breakpoints",
  templateUrl: "./breakpoints.component.html",
  styleUrls: ["./breakpoints.component.scss"]
})
export class BreakpointsComponent implements OnInit {

  public ngOnInit() {
  }

}
