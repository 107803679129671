<div class="card">
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-tab-group class="tabs" mat-align-tabs="start" disableRipple>
  &lt;mat-tab label="Tab A">
    &lt;div class="content">
      &lt;span class="text-l5">Content in tab A&lt;/span>
      &lt;input type="text" placeholder="Please type here" />
    &lt;/div>
  &lt;/mat-tab>
  &lt;mat-tab label="Tab B">
    &lt;div class="content">
      &lt;span class="text-l5">Content in tab B&lt;/span>
      &lt;select required>
        &lt;option selected disabled hidden>
          Please select an option
        &lt;/option>
        &lt;option>Option 1&lt;/option>
        &lt;option>Option 2&lt;/option>
        &lt;option>Option 3&lt;/option>
      &lt;/select>
    &lt;/div>
  &lt;/mat-tab>
&lt;/mat-tab-group>
</code>
      </pre>
    </details>
    <mat-tab-group class="tabs" mat-align-tabs="start" disableRipple>
      <mat-tab label="Tab A">
        <div class="content">
          <span class="text-l5">Content in tab A</span>
          <input type="text" placeholder="Please type here" />
        </div>
      </mat-tab>
      <mat-tab label="Tab B">
        <div class="content">
          <span class="text-l5">Content in tab B</span>
          <select required>
            <option selected disabled hidden>
              Please select an option
            </option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
