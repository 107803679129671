import { Component } from "@angular/core";

@Component({
  selector: "app-colors",
  templateUrl: "./colors.component.html",
  styleUrls: ["./colors.component.scss"]
})
export class ColorsComponent {

  public headers = [
    "Color",
    "Variable name",
    "Hex code"
  ];

  public neutralColorHeaders = [
    ...this.headers, "Usage"
  ];

  public primaryColors = [
    {
      properties: [
        "$primary-1",
        "#ffcf2e"
      ]
    },
    {
      properties: [
        "$primary-1-light-8",
        "#ffd857"
      ]
    },
    {
      properties: [
        "$primary-1-light-24",
        "#ffeba8"
      ]
    },
    {
      properties: [
        "$primary-1-dark-16",
        "#dba900"
      ]
    },
    {
      properties: [
        "$primary-2",
        "#30b3af"
      ]
    },
    {
      properties: [
        "$primary-2-light-8",
        "#40ccc8"
      ]
    },
    {
      properties: [
        "$primary-2-light-24",
        "#80ddda"
      ]
    },
    {
      properties: [
        "$primary-2-light-40",
        "#c1eeed"
      ]
    },
    {
      properties: [
        "$primary-2-light-48",
        "#e1f7f6"
      ]
    },
    {
      properties: [
        "$primary-2-dark-16",
        "#1f7370"
      ]
    },
    {
      properties: [
        "$primary-text-color",
        "#192743"
      ]
    }
  ];

  public statusColors = [
    {
      properties: [
        "$status-positive-100",
        "#f1f9f4"
      ]
    },
    {
      properties: [
        "$status-positive-200",
        "#d2eddb"
      ]
    },
    {
      properties: [
        "$status-positive-500",
        "#66b881"
      ]
    },
    {
      properties: [
        "$status-positive-900",
        "#418b5a"
      ]
    },
    {
      properties: [
        "$status-warning-100",
        "#fff2eb"
      ]
    },
    {
      properties: [
        "$status-warning-200",
        "#fee5d7"
      ]
    },
    {
      properties: [
        "$status-warning-500",
        "#fa8324"
      ]
    },
    {
      properties: [
        "$status-warning-900",
        "#de560a"
      ]
    },
    {
      properties: [
        "$status-negative-100",
        "#fdeded"
      ]
    },
    {
      properties: [
        "$status-negative-200",
        "#fde0e1"
      ]
    },
    {
      properties: [
        "$status-negative-500",
        "#e6373d"
      ]
    },
    {
      properties: [
        "$status-negative-900",
        "#b6161c"
      ]
    },
    {
      properties: [
        "$status-information-100",
        "#eff4ff"
      ]
    },
    {
      properties: [
        "$status-information-200",
        "#e4ebfc"
      ]
    },
    {
      properties: [
        "$status-information-500",
        "#5b8ef5"
      ]
    },
    {
      properties: [
        "$status-information-900",
        "#1c55c7"
      ]
    }
  ];

  public neutralColors = [
    {
      properties: [
        "$neutral",
        "#192743",
        "Title texts, captions, input fields and everywhere else where black is required"
      ]
    },
    {
      properties: [
        "$neutral-64",
        "#546178",
        "Secondary text; subtitles inactive navigation text; default button text; default select state"
      ]
    },
    {
      properties: [
        "$neutral-48",
        "#9da4b1",
        "Hover state of borders; icons; captions on white"
      ]
    },
    {
      properties: [
        "$neutral-16",
        "#d8dbe2",
        "Placeholder/default texts in text field; disabled states text & icons; borders"
      ]
    },
    {
      properties: [
        "$neutral-8",
        "#e6e9ee",
        "Disabled background for buttons; Dividers"
      ]
    },
    {
      properties: [
        "$neutral-4",
        "#f5f7fa",
        "Background, alternating rows of tables; dividers"
      ]
    },
    {
      properties: [
        "$neutral-2",
        "#f9fafb ",
        "Hover state of dropdowns, tables and selections"
      ]
    }
  ];

}
