<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Media breakpoints</h2>
  </div>
  <div class="card-content-container">
    <p>Mobile: <strong> &#60; 768px </strong>
    </p>
    <p>Tablet: <strong>768px - 1199px</strong></p>
    <p>Desktop: <strong>>= 1200px</strong></p>

  </div>
</div>

<br><br>

<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Grid</h2>
  </div>
  <div class="card-content-container">
    <p>In our designs, everything is built on an <strong>8 pixel grid</strong>. This means all margins/paddings/etc. are a
      multiple of 8px.</p>
    <p>If you see a value that is not a multiple of 8 in the designs, for example</p>
    <pre><code>margin-left: 15px</code></pre>
    <p>It's usually is an error in Zeplin/Figma and it's supposed to be 16px instead.</p>
  </div>
</div>