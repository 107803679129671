import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {BehaviorSubject, filter, lastValueFrom, map, Observable, startWith, throwError} from "rxjs";

import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {CultureService} from "../services/culture.service";
import {VideoPlayBackEventData, VideoSubtitleChangedData} from "../models/eventData";
import {VideoOptions} from "../models/videoOptions";
import {FeedbackEvent} from "../models/feedbackEvent";
import {IShakaControls, IShakaPlayer, ShakaWrapperUtility}
  from "../services/shaka-wrapper-utility.service";

const DEFAULT_IMAGE_SIZES = [360, 768, 1200];

@Component({
  selector: "eff-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: [
    "./video-player.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements AfterViewInit {
  private static shakaPolyfillsInstalled = false;

  @ViewChild("videoElement") public videoElement: ElementRef;
  @ViewChild("videoPlayer") public videoPlayer: ElementRef;

  @Input() public fullScreenOnMobile = true;
  @Input() public showFeedback = false;
  @Input() public showVideoQualityButton = true;
  @Input() public showSubtitleButton = true;
  @Input() public showPictureInPictureButton = false;

  @Output() public videoImageError = new EventEmitter<ErrorEvent>();
  @Output() public videoPlayed = new EventEmitter<VideoPlayBackEventData>();
  @Output() public videoPaused = new EventEmitter<VideoPlayBackEventData>();
  @Output() public videoResumed = new EventEmitter<VideoPlayBackEventData>();
  @Output() public videoSeeked = new EventEmitter<VideoPlayBackEventData>();
  @Output() public videoEnded = new EventEmitter<VideoPlayBackEventData>();
  @Output() public subtitleChanged = new EventEmitter<VideoSubtitleChangedData>();
  @Output() public browserNotSupported = new EventEmitter<string>();
  @Output() public playbackError = new EventEmitter<ErrorEvent>();
  @Output() public userFeedback = new EventEmitter<FeedbackEvent>();

  public srcSet$: Observable<string>;
  public defaultImageSrc$: Observable<string>;
  public selectedSubtitle$: Observable<string>;
  public videoPlayerFailed = false;
  public isLoading = false;
  public isVideoPlaying = false;
  public isVideoFinished = false;

  private firstTimePlayed = false;
  private player: IShakaPlayer;
  private videoControls: IShakaControls;
  private oldTextLanguage: string;
  private readonly videoOptionsBehaviorSubject = new BehaviorSubject<VideoOptions>(null);
  private readonly language$: Observable<string>;

  constructor(private readonly httpClient: HttpClient,
              private readonly translateService: TranslateService,
              private readonly cultureMatcher: CultureService,
              private readonly shakaUtility: ShakaWrapperUtility
  ) {
    this.language$ = translateService.onLangChange.pipe(
      map(lang => lang.lang),
      startWith(this.translateService.getDefaultLang())
    );

    this.oldTextLanguage = this.translateService.currentLang;

    this.srcSet$ = this.videoOptionsBehaviorSubject
      .pipe(
        filter(options => options != null),
        map(options => {
          const urls = DEFAULT_IMAGE_SIZES.map(size => VideoPlayerComponent.generateResponsiveImageUrl(this.srcVideoOptions.posterBasePath, size));
          return urls.join(",\r\n");
        })
      );

    this.defaultImageSrc$ = this.videoOptionsBehaviorSubject
      .pipe(
        filter(options => options != null),
        map(options => {
          const size = DEFAULT_IMAGE_SIZES[DEFAULT_IMAGE_SIZES.length - 1];
          return VideoPlayerComponent.generateResponsiveImageUrl(this.srcVideoOptions.posterBasePath, size);
        })
      );

    this.selectedSubtitle$ = this.language$.pipe(
      map(userLanguage => {

        if (typeof this.player === "undefined" || this.player == null) {
          return "";
        }

        let trackIndex = -1;
        const textTracks = this.player.getTextTracks();
        const textTrackLanguages = textTracks.map(track => track.language.toLowerCase());

        const matchedLanguage = this.cultureMatcher
          .getCulture(textTrackLanguages, "en-us", userLanguage.toLowerCase());


        trackIndex = textTracks.findIndex((element) => element.language.toLowerCase() === matchedLanguage);

        if (trackIndex > 0 && trackIndex < textTracks.length) {
          this.player.selectTextTrack(textTracks[trackIndex]);
        }

        return matchedLanguage;
      })
    );
  }

  public get alternateTitle() {
    if (this.srcVideoOptions == null) {
      return "";
    }
    return `${this.srcVideoOptions.name} video`;
  }

  public get isVideoLoaded() {
    return this.player !== undefined;
  }


  public get selectedSubtitleLanguage() {
    const track = this.player.getTextTracks().filter(t => t.active)[0] ?? {language: null};
    return track.language;
  }

  public get dashUrl() {
    return this.srcVideoOptions.dashUrl;
  }

  public get srcVideoOptions() {
    return this.videoOptionsBehaviorSubject.value;
  }

  @Input() public set srcVideoOptions(options: VideoOptions) {
    if (options.posterBasePath.endsWith("/")) {
      options.posterBasePath = options.posterBasePath.substring(0, options.posterBasePath.length - 1);
    }
    this.videoOptionsBehaviorSubject.next(options);
  }

  private static generateResponsiveImageUrl(baseUrl: string, size: number, format = "jpg") {
    return `${baseUrl}/${size}.${format} ${size}w`;
  }

  ngAfterViewInit() {
    if (!VideoPlayerComponent.shakaPolyfillsInstalled) {
      this.shakaUtility.installPolyfills();
      VideoPlayerComponent.shakaPolyfillsInstalled = true;
    }
  }

  public async playVideo() {
    if (!this.shakaUtility.isBrowserSupported()) {
      this.browserNotSupported.emit("Browser not supported");
      return;
    }

    if (this.isLoading) {
      return;
    }

    await this.initPlayer();
  }

  public onImageFailed($event: ErrorEvent) {
    this.videoImageError.emit($event);
  }

  public onDashFileFailed(error) {
    this.videoPlayerFailed = true;
    this.isLoading = false;

    this.raisePlayBackErrorEvent(error, "video-load-error");
    return throwError(() => new Error("playback failed"));
  }

  public onFeedback($event: FeedbackEvent) {
    this.userFeedback.emit($event);
  }

  private async initPlayer() {
    this.isLoading = true;
    const dashBlob = await this.loadDashFile();
    this.isLoading = false;

    if (dashBlob) {
      this.player = this.shakaUtility.create(this.videoElement?.nativeElement);
      this.player.configure({
        preferredTextLanguage: this.translateService.currentLang
      });
      this.initShakaPlayer(dashBlob);
      this.initPlayerControls();
      this.initPlayerEvents();
      this.isVideoPlaying = true;
    }
  }

  private async loadDashFile() {
    const videoResourceUrl = this.srcVideoOptions.dashUrl;
    const dashBlob$ = this.httpClient.get(videoResourceUrl, {responseType: "blob"})
      .pipe(
        map(result => window.URL.createObjectURL(result)
        )
      );

    return lastValueFrom(dashBlob$);
  }

  private initShakaPlayer(dashBlobUrl) {
    this.player.load(dashBlobUrl, 0, "application/dash+xml")
      .then(() => this.videoElement?.nativeElement.play())
      .catch(err => this.raisePlayBackErrorEvent(err));
  }

  private initPlayerEvents() {
    this.player.addEventListener("error", err => {
      this.raisePlayBackErrorEvent(err);
    });

    this.player.addEventListener("textchanged", () => {
      this.oldTextLanguage = this.selectedSubtitleLanguage;
      this.subtitleChanged.emit(this.getSubtitleEventData());
    });

    this.videoElement?.nativeElement.addEventListener("seeked",
      () => this.videoSeeked.emit(this.getPlayBackEventData())
    );

    this.videoElement?.nativeElement.addEventListener("pause", () => {
      this.isVideoPlaying = false;
      this.videoPaused.emit(this.getPlayBackEventData());
    });

    this.videoElement?.nativeElement.addEventListener("play", () => {
      this.forceFullScreenOnMobile();
      this.isVideoPlaying = true;
      this.isVideoFinished = false;

      if (this.firstTimePlayed) {
        this.videoPlayed.emit(this.getPlayBackEventData());
      } else {
        this.videoResumed.emit(this.getPlayBackEventData());
      }
    });
    this.videoElement?.nativeElement.addEventListener("ended", () => {
      this.isVideoFinished = true;
      this.isVideoPlaying = false;
      this.videoEnded.emit(this.getPlayBackEventData());
    });
  }

  private initPlayerControls() {
    const ui = this.shakaUtility.createUIOverlay(this.player,
      this.videoPlayer?.nativeElement,
      this.videoElement?.nativeElement
    );

    const overFlowButtons = [];

    if (this.showSubtitleButton) {
      overFlowButtons.push("captions");
    }

    if (this.showVideoQualityButton) {
      overFlowButtons.push("quality");
    }

    if (this.showPictureInPictureButton) {
      overFlowButtons.push("picture_in_picture");
    }

    ui.configure({overflowMenuButtons: overFlowButtons});

    this.videoControls = ui.getControls();
    this.forceFullScreenOnMobile();
  }

  private forceFullScreenOnMobile() {
    if (this.fullScreenOnMobile &&
      window.innerWidth <= 767 &&
      document.querySelector(".shaka-video-container:fullscreen") == null) {
      this.videoControls.toggleFullScreen();
    }
  }

  private raisePlayBackErrorEvent(error, type = "video-player-error") {
    if (error.code === 1002) {
      this.videoPlayerFailed = true;
      this.isLoading = false;
      this.player = undefined;
    }

    this.playbackError.emit(error);
  }

  private getPlayBackEventData(): VideoPlayBackEventData {
    const videoElement = this.videoElement?.nativeElement;

    return {
      videoId: this.srcVideoOptions.id,
      currentVideoPlayTime: videoElement?.currentTime,
      currentVideoTextLanguage: this.selectedSubtitleLanguage,
      videoName: this.srcVideoOptions.name
    };

  }

  private getSubtitleEventData(): VideoSubtitleChangedData {
    const videoElement = this.videoElement?.nativeElement;

    return {
      videoId: this.srcVideoOptions.id,
      currentVideoPlayTime: videoElement?.currentTime,
      videoName: this.srcVideoOptions.name,
      oldSubtitle: this.oldTextLanguage,
      newSubtitle: this.selectedSubtitleLanguage
    };
  }
}



