import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "eff-questionnaire-design-preview",
  templateUrl: "./questionnaire-design-preview.component.html",
  styleUrls: ["./questionnaire-design-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionnaireDesignPreviewComponent {
  @Input() public logoUrl: string;
  @Input() public backgroundImageUrl: string;
  @Input() public backgroundPatternUrl: string;
  @Input() public primaryColor: string;
  @Input() public backgroundColor: string;
  @Input() public previewDesktopInColumnMode: boolean = false;
  @Input() public mobileVersion: boolean = false;
  @Input() public showRepositionBtn: boolean = false;
  @Input() public imageHeight: number;
  @Input() public mobileImageXOffset: number;
  @Output() public imageRepositioned: EventEmitter<void> = new EventEmitter<void>();
  // Questions-m-content width is set to 12.32 and .body-m height to 22rem which gives us 0.56 ratio
  // This value backgroundImageContainerHeight should always match the height of the .body-m class => 22rem
  private backgroundImageContainerHeight: number = 220;

  public getBackgroundImage(): { [property: string]: string } {
    if(this.backgroundImageUrl == null){
      const backgroundColor = this.addHashWhenNeeded(this.backgroundColor);

      return {
        "background-color": backgroundColor
      };
    }

    return {
      "background-image": `url(${this.backgroundImageUrl})`
    };
  }

  public getBackgroundPattern(): { [property: string]: string } {
    const backgroundColor = this.addHashWhenNeeded(this.backgroundColor);

    if(this.backgroundPatternUrl == null){
      return {
        "background-color": backgroundColor
      };
    }

    return {
      "background-image": `url('${this.backgroundPatternUrl}')`,
      "background-color": backgroundColor
    };
  }

  public getPrimaryColor(): { [property: string]: string } {
    if(this.primaryColor == null){
      return;
    }

    const primaryColor = this.addHashWhenNeeded(this.primaryColor);

    return {
      "background-color": primaryColor
    };
  }

  public getLogo(): { [property: string]: string } {
    if(this.logoUrl == null){
      return;
    }

    return {
      "background-image": `url(${this.logoUrl})`
    };
  }

  public getBackgroundImageAndPosition(): { [property: string]: string } {
    if(this.backgroundImageUrl == null){
      const backgroundColor = this.addHashWhenNeeded(this.backgroundColor);

      return {
        "background-color": backgroundColor
      };
    }

    const proportionalHeight = this.imageHeight / this.backgroundImageContainerHeight;
    const proportionalXOffset = this.mobileImageXOffset / proportionalHeight;

    return {
      "background-image": `url(${this.backgroundImageUrl})`,
      "background-position-x": `-${proportionalXOffset}px`
    };
  }

  public repositionImage(): void {
    this.imageRepositioned.emit();
  }

  private addHashWhenNeeded(input: string): string {
    if (input?.indexOf("#") === -1) {
      input = `#${input}`;
    }

    return input;
  }
}
