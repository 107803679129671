<div class="mailing-content-container-branding" [ngStyle]="{'--primary-color': primaryColor}">
  <div *ngIf="showTag" class="design-header-tile tag">
    <span>{{ 'shell.mailing-content.tag' | translate }}</span>
  </div>
  <div class="screen-container mailing">
    <!-- * Logo -->
    <div class="logo-container">
      <img *ngIf="logoUrl" class="logo" [src]="logoUrl" [alt]="'shell.mailing-logo-alt' | translate" />
    </div>
    <div *ngIf="privacyHeader" class="privacy-header-container">
      <div [innerHTML]="privacyHeader"></div>
    </div>
    <!-- * Title -->
    <div *ngIf="titleText" class="text-l3 text-w600 title" [innerHTML]="titleText"></div>
    <!-- * Subtitle -->
    <div *ngIf="subtitle" class="text-l4 subtitle" [innerHTML]="subtitle"></div>
    <div *ngIf="showIntroductionContent">
      <div *ngIf="!introductionContent; else customContent" class="custom-content-container placeholder">
        <p class="text-l5 text-w400">
          {{ 'shell.mailing-content.placeholder-text' | translate }}
        </p>
      </div>
      <ng-template #customContent>
        <div class="custom-content-container">
          <div class="text-w500" [innerHTML]="introductionContent"></div>
        </div>
      </ng-template>
    </div>
    <!-- * First Body text -->
    <div *ngIf="body1Content" class="text-w500 content" [innerHTML]="body1Content"></div>
    <!-- * Button -->
    <a *ngIf="buttonText && buttonLink" [href]="buttonLink" target="_blank" class="dummy-btn">
      <span class="text-w500" [innerHTML]="buttonText"></span> 
    </a>
    <!-- * Image -->
    <img *ngIf="backgroundImageUrl" class="bg-image" [src]="backgroundImageUrl" aria-hidden="true"/>
    <!-- * Second Body text -->
    <div *ngIf="body2Content" class="text-w500 content" [innerHTML]="body2Content"></div>
    <!-- * Footer -->
    <div *ngIf="footer" class="text-small content" [innerHTML]="footer"></div>
  </div>
  <div class="disclaimer-container">
    <eff-svg-info class="icon"></eff-svg-info>
    <p class="text-subdued">{{ 'shell.mailing-disclaimer' | translate }}</p>
  </div>
</div>
