<div class="notification-backdrop">
  <ng-container *ngTemplateOutlet="notifs; context: {notifications: notificationService.notifications}"></ng-container>
</div>

<div class="notification-backdrop notification-backdrop-toast">
  <ng-container *ngTemplateOutlet="notifs; context: {notifications: notificationService.toastNotifications}"></ng-container>
</div>


<ng-template #notifs let-notifications="notifications">
  <div class="notification" *ngFor="let notification of notifications" [ngClass]="notification.messageType" role="alert">
    <button *ngIf="notification.messageType !== 'toast'" (click)="closeNotification(notification)" class="close-button" lang="en" aria-label="close">
      <eff-svg-cross></eff-svg-cross>
    </button>
    <span *ngIf="notification.messageTitle" class="title text-w500">{{ notification.messageTitle }}</span>
    <span class="message" [class.text-l5]="notification.messageType === 'toast'">{{ notification.message }}</span>
    <button *ngFor="let linkMessage of notification.linkMessages; index as i" [ngClass]="notification.messageType === 'toast' ? 'primary' : 'link'"
      (click)="executeCallback(notification, i)">{{ notification.linkMessages[i] }}</button>
  </div>
</ng-template>
