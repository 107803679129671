<h1 class="text-l2">Icons</h1>

<div class="icons-container">
  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Icons part 1</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons1">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon1></ng-template>
            <ng-template class="inverted" #icon1inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Icons part 2</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons2">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon2></ng-template>
            <ng-template class="inverted" #icon2inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Icons part 3</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons3">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon3></ng-template>
            <ng-template class="inverted" #icon3inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Icons part 4</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons4">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon4></ng-template>
            <ng-template class="inverted" #icon4inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Icons part 5</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons5">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon5></ng-template>
            <ng-template class="inverted" #icon5inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Sidebar & breadcrumb icons</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons6">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon6></ng-template>
            <ng-template class="inverted" #icon6inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Large Icons (80x80)</h2>
    </div>
    <div class="card-content-container">
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-upload-large></span>
        <eff-svg-upload-large class="large-icon"></eff-svg-upload-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-report-large></span>
        <eff-svg-report-large class="large-icon"></eff-svg-report-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-response-large></span>
        <eff-svg-response-large class="large-icon"></eff-svg-response-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-project-large></span>
        <eff-svg-project-large class="large-icon"></eff-svg-project-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-form-completed-large></span>
        <eff-svg-form-completed-large class="large-icon"></eff-svg-form-completed-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-running-clock-large></span>
        <eff-svg-running-clock-large class="large-icon"></eff-svg-running-clock-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-completed-large></span>
        <eff-svg-completed-large class="large-icon"></eff-svg-completed-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-no-activities-large></span>
        <eff-svg-no-activities-large class="large-icon"></eff-svg-no-activities-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-no-questionnaire-large></span>
        <eff-svg-no-questionnaire-large class="large-icon"></eff-svg-no-questionnaire-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-search-no-results-large></span>
        <eff-svg-search-no-results-large class="large-icon"></eff-svg-search-no-results-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-no-response-large></span>
        <eff-svg-no-response-large class="large-icon"></eff-svg-no-response-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-no-question-sets-large></span>
        <eff-svg-no-question-sets-large class="large-icon"></eff-svg-no-question-sets-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-no-question-library-large></span>
        <eff-svg-no-question-library-large class="large-icon"></eff-svg-no-question-library-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-question-library-large></span>
        <eff-svg-question-library-large class="large-icon"></eff-svg-question-library-large>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Large Icons (96x96)</h2>
    </div>
    <div class="card-content-container">
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-feedback-large></span>
        <eff-svg-feedback-large class="larger-icon"></eff-svg-feedback-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-printer-large></span>
        <eff-svg-printer-large class="larger-icon"></eff-svg-printer-large>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-work-in-progress></span>
        <eff-svg-work-in-progress class="larger-icon"></eff-svg-work-in-progress>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Logo's</h2>
    </div>
    <div class="card-content-container">
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-logo></span>
        <eff-svg-logo class="logo"></eff-svg-logo>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-logo-yellow></span>
        <eff-svg-logo-yellow class="logo"></eff-svg-logo-yellow>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-logo-full></span>
        <eff-svg-logo-full class="logo effectory-full-logo"></eff-svg-logo-full>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-wcwp></span>
        <eff-svg-wcwp class="logo wcwp-logo"></eff-svg-wcwp>
      </div>
      <div class="large-icon-wrapper">
        <span>&lt;eff-svg-internetspiegel-logo-full></span>
        <eff-svg-internetspiegel-logo-full class="logo"></eff-svg-internetspiegel-logo-full>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-title-container">
      <h2 class="text-l5 card-title">Flags</h2>
    </div>
    <div class="card-content-container">
      <table mat-table [dataSource]="icons7">
        <ng-container matColumnDef="Element name">
          <th mat-header-cell *matHeaderCellDef>Element name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Icon">
          <th mat-header-cell *matHeaderCellDef>Icon</th>
          <td mat-cell *matCellDef="let row" class="grid-cell">
            <ng-template #icon7></ng-template>
            <ng-template class="inverted" #icon7inverted></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
      </table>
    </div>
  </div>
</div>
