<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Sizes</h2>
  </div>
  <div class="card-content-container">
    <span class="text-l5">Font: Poppins</span><br><br>
    <table mat-table [dataSource]="sizes">

      <ng-container matColumnDef="Example">
        <th mat-header-cell *matHeaderCellDef>Example</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.example"></td>
      </ng-container>

      <ng-container matColumnDef="Class name">
        <th mat-header-cell *matHeaderCellDef>Class name</th>
        <td mat-cell *matCellDef="let row"><code>{{row.className}}</code></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headers"></tr>
      <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
    </table>
  </div>
</div>

<br>

<div class="card">
  <div class="card-title-container">
    <h2 class="text-l5 card-title">Modifiers</h2>
  </div>
  <div class="card-content-container">
    <table mat-table [dataSource]="modifiers">

      <ng-container matColumnDef="Example">
        <th mat-header-cell *matHeaderCellDef>Example</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.example"></td>
      </ng-container>

      <ng-container matColumnDef="Class name">
        <th mat-header-cell *matHeaderCellDef>Class name</th>
        <td mat-cell *matCellDef="let row"><code>{{row.className}}</code></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headers"></tr>
      <tr mat-row *matRowDef="let row; columns: headers; let i = index;"></tr>
    </table>
  </div>
</div>
