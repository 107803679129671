import {Component, OnInit} from "@angular/core";
import {VideoOptions} from "../../../projects/branding-angular/video/models/videoOptions";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"]
})
export class VideoComponent implements OnInit {

  public raisedEvents: object[] = [];

  constructor() {
  }

  public get sampleVideo(): VideoOptions {
    return {
      name: "Sample Video",
      id: "video1",
      dashUrl: "https://effectorybranding.blob.core.windows.net/videos/video1/video_manifest.mpd",
      posterBasePath: "https://effectorybranding.blob.core.windows.net/videos/video1/posters/"
    };
  }

  ngOnInit(): void {
  }

  public pushEvent(eventData) {
    this.raisedEvents.push({name: eventData.name, data: JSON.stringify(eventData.data, null, "\t")});
  }
}
