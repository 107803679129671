import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
  selector: "eff-overflow-shadow-side",
  templateUrl: "./overflow-shadow-side.component.html",
  styleUrls: ["./overflow-shadow-side.component.scss"]
})
export class OverflowShadowSideComponent implements OnInit, OnChanges {

  @Input() public container: HTMLElement;
  @Input() public content: any;
  @Input() public padding = "0";
  @Input() public paddingLeft = "0";
  @Input() public paddingRight = "0";
  @Input() public paddingBottom = "0";

  public shadowStyles: { bottom: string, left?: string, opacity: number, right?: string, transform: string }[] = [
    {
      bottom: this.paddingBottom,
      left: this.paddingLeft !== "0" ? this.paddingLeft : this.padding,
      opacity: 0,
      transform: "scaleX(0.5)"
    },
    {
      bottom: this.paddingBottom,
      opacity: 0,
      right: this.paddingRight !== "0" ? this.paddingRight : this.padding,
      transform: "scaleX(0.5)"
    }
  ];

  public ngOnInit(): void {
    window.addEventListener("resize", () => this.determineShadows());
  }

  public ngOnChanges(): void {
    this.determineShadows();
    if (this.container) {
      this.container.removeEventListener("scroll", () => this.determineShadows());
      this.container.addEventListener("scroll", () => this.determineShadows());
    }
  }

  public determineShadows(): void {
    if (!this.container) {
      return;
    }

    const totalOverflow = this.container.scrollWidth - this.container.clientWidth;

    if (totalOverflow <= 1) { // IE sometimes rounds scrollWidth and clientWidth incorrectly
      this.shadowStyles = [
        {
          bottom: this.paddingBottom,
          left: this.paddingLeft !== "0" ? this.paddingLeft : this.padding,
          opacity: 0,
          transform: "scaleX(0.5)"
        },
        {
          bottom: this.paddingBottom,
          opacity: 0,
          right: this.paddingRight !== "0" ? this.paddingRight : this.padding,
          transform: "scaleX(0.5)"
        }
      ];
      return;
    }

    const currentRightOverflow = this.container.scrollWidth - (this.container.clientWidth + this.container.scrollLeft);
    const currentLeftOverflow = totalOverflow - currentRightOverflow;

    this.shadowStyles[0] = {
      bottom: this.paddingBottom,
      left: this.paddingLeft !== "0" ? this.paddingLeft : this.padding,
      opacity: currentLeftOverflow / totalOverflow,
      transform: `scaleX(${0.5 + (currentLeftOverflow / totalOverflow) / 2})`
    };
    this.shadowStyles[1] = {
      bottom: this.paddingBottom,
      opacity: currentRightOverflow / totalOverflow,
      right: this.paddingRight !== "0" ? this.paddingRight : this.padding,
      transform: `scaleX(${0.5 + (currentRightOverflow / totalOverflow) / 2})`
    };
  }
}
