<div class="container" *ngIf="!isFeedbackGiven">
  <h3 class="text-l5 title">
    {{'shell.video.feedback-question' | translate}}
  </h3>

  <div class="buttons-container">
    <button class="secondary" (click)="feedback(true)" [attr.aria-label]="'shell.video.like' | translate">
      <eff-svg-up-vote></eff-svg-up-vote>
    </button>
    <div class="divider"></div>
    <button class="secondary" (click)="feedback(false)" [attr.aria-label]="'shell.video.dislike' | translate">
      <eff-svg-down-vote></eff-svg-down-vote>
    </button>
  </div>
</div>

<ng-template #feedbackDialog let-dialogRef="dialogRef" let-data>
  <div class="card">
    <div class="card-title-container">
      <h3 class="text-l5 card-title">
        {{'shell.video.share-your-feedback' | translate}}
      </h3>
      <button class="close-button" (click)="sendFeedback(false)" [attr.aria-label]="'shell.close' | translate">
        <eff-svg-cross></eff-svg-cross>
      </button>
    </div>
    <div class="card-content-container">
      <label for="suggestions">
        {{'shell.video.share-your-feedback-header' | translate}}
      </label>
      <textarea id="suggestions" [(ngModel)]="suggestion" [attr.placeholder]="'shell.video.share-your-feedback-explanation' | translate"></textarea>
    </div>
    <div class="action-container">
      <button class="action-button primary" (click)="sendFeedback(true)">
        {{'shell.generic.share' | translate}}
      </button>
      <button class="action-button secondary" (click)="sendFeedback(false)">
        {{'shell.generic.cancel' | translate}}
      </button>
    </div>
  </div>
</ng-template>
