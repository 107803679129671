<nav>
  <ul class="navigation">
    <li *ngFor="let route of children; let first = first">
      <a class="text-subdued" [routerLink]="route.path" routerLinkActive="active" (click)="mobileSidebarService.sidebarExpanded = false">
        <eff-svg-sidebar-home *ngIf="first" class="menu-icon"></eff-svg-sidebar-home>
        <eff-svg-sidebar-projects *ngIf="!first" class="menu-icon"></eff-svg-sidebar-projects>
        <span>{{ route.data?.name || route.path }}</span>
      </a>
    </li>
  </ul>
</nav>
