<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Checkbox</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;eff-checkbox [checked]="isAllChecked" [intermediate]="areSomeChecked" (checkedChange)="selectAll($event)" [labelText]="'(de-)Select all'">&lt;/eff-checkbox>
&lt;eff-checkbox [checked]="checkeds[0]" (checkedChange)="checkeds[0] = !checkeds[0]" [labelText]="'Checkbox 1'">&lt;/eff-checkbox>
&lt;eff-checkbox [checked]="checkeds[1]" (checkedChange)="checkeds[1] = !checkeds[1]" [labelText]="'Checkbox 2'">&lt;/eff-checkbox>
&lt;eff-checkbox [disabled]="true" [checked]="false" [labelText]="'Checkbox disabled'">&lt;/eff-checkbox>
&lt;eff-checkbox [disabled]="true" [checked]="true" [labelText]="'Checkbox disabled (checked)'">&lt;/eff-checkbox>
</code>
</pre>
    </details>
    <p>
      <eff-checkbox [checked]="isAllChecked" [intermediate]="areSomeChecked" (checkedChange)="selectAll($event)" [labelText]="'(de-)Select all'"></eff-checkbox><br>
      <eff-checkbox [checked]="checkeds[0]" (checkedChange)="checkeds[0] = !checkeds[0]" [labelText]="'Checkbox 1'"></eff-checkbox><br>
      <eff-checkbox [checked]="checkeds[1]" (checkedChange)="checkeds[1] = !checkeds[1]" [labelText]="'Checkbox 2 that is super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super super long'"></eff-checkbox><br>
      <eff-checkbox [disabled]="true" [checked]="false" [labelText]="'Checkbox disabled'"></eff-checkbox><br>
      <eff-checkbox [disabled]="true" [checked]="true" [labelText]="'Checkbox disabled (checked)'"></eff-checkbox><br>
    </p><br>
  </div>
</div>
