import { Injectable } from "@angular/core";

export class Notification {
  public messageTitle?: string;
  public message: string;
  public linkMessages = [];
  public linkCallback0: () => void;
  public linkCallback1: () => void;
  public linkCallback2: () => void;
  public messageType: string;
}

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private defaultNotificationTimeout = 2000;

  private _notifications: Notification[] = [];
  private _toastNotifications: Notification[] = [];

  get notifications(): Notification[] {
    return this._notifications;
  }

  get toastNotifications(): Notification[] {
    return this._toastNotifications;
  }

  public success(message: string, linkMessages?: string | string[], linkCallbacks?: () => void | (() => void)[], timeout?: number, messageTitle?: string): Notification;

  public success(message: string, linkMessages?: string | string[], linkCallbacks?: any, timeout?: number, messageTitle?: string): Notification {
    const linkMessagesArray = this.convertToArray(linkMessages);
    const linkCallbacksArray = this.convertToArray(linkCallbacks);
    return this.showMessage("success", message, messageTitle, linkMessagesArray, linkCallbacksArray, timeout);
  }

  public warn(message: string, linkMessages?: string | string[], linkCallbacks?: () => void | (() => void)[], timeout?: number, messageTitle?: string): Notification;

  public warn(message: string, linkMessages?: string | string[], linkCallbacks?: any, timeout?: number, messageTitle?: string): Notification {
    const linkMessagesArray = this.convertToArray(linkMessages);
    const linkCallbacksArray = this.convertToArray(linkCallbacks);
    return this.showMessage("warn", message, messageTitle, linkMessagesArray, linkCallbacksArray, timeout);
  }

  public error(message: string, linkMessages?: string | string[], linkCallbacks?: () => void | (() => void)[], timeout?: number, messageTitle?: string): Notification;

  public error(message: string, linkMessages?: string | string[], linkCallbacks?: any, timeout?: number, messageTitle?: string): Notification {
    const linkMessagesArray = this.convertToArray(linkMessages);
    const linkCallbacksArray = this.convertToArray(linkCallbacks);
    return this.showMessage("error", message, messageTitle, linkMessagesArray, linkCallbacksArray, timeout);
  }

  public info(message: string, linkMessages?: string | string[], linkCallbacks?: () => void | (() => void)[], timeout?: number, messageTitle?: string): Notification;

  public info(message: string, linkMessages?: string | string[], linkCallbacks?: any, timeout?: number, messageTitle?: string): Notification {
    const linkMessagesArray = this.convertToArray(linkMessages);
    const linkCallbacksArray = this.convertToArray(linkCallbacks);
    return this.showMessage("info", message, messageTitle, linkMessagesArray, linkCallbacksArray, timeout);
  }

  public toast(message: string, buttonActionText?: string, buttonCallback?: () => void, timeout?: number): Notification;
  public toast(message: string, buttonActionText?: string, buttonCallback?: any, timeout?: number): Notification {
    const buttonActionTextsArray = this.convertToArray(buttonActionText);
    const buttonCallbacksArray = this.convertToArray(buttonCallback);

    // fallback to a default timeout if neither timeout nor action to close notification is set
    if ((!buttonActionText || !buttonCallbacksArray || buttonActionText.length === 0 || buttonCallbacksArray.length === 0) && !timeout) {
      timeout = this.defaultNotificationTimeout;
    }
    return this.showMessage("toast", message, "", buttonActionTextsArray, buttonCallbacksArray, timeout, true);
  }

  public closeNotification(notif: Notification) {
    const notifications = notif.messageType === "toast" ? this._toastNotifications : this._notifications;
    const index = notifications.indexOf(notif);
    if (index > -1) {
      notifications.splice(index, 1);
    }
  }

  public closeAllNotifications(): void {
    this._notifications = [];
    this._toastNotifications = [];
  }

  private showMessage(messageType: string, message: string, messageTitle: string, linkMessages: string[], linkCallbacks: (() => void)[], timeout?: number, isToastNotification: boolean = false): Notification {

    if ((linkMessages && linkMessages.length > 3) || (linkCallbacks && linkCallbacks.length > 3)) {
      throw new Error("More than three links are not supported");
    }

    const notif = new Notification();
    notif.messageTitle = messageTitle;
    notif.message = message;
    notif.linkMessages = linkMessages;
    notif.linkCallback0 = linkCallbacks?.[0];
    notif.linkCallback1 = linkCallbacks?.[1];
    notif.linkCallback2 = linkCallbacks?.[2];
    notif.messageType = messageType;

    this.appendNotification(
      isToastNotification ? this._toastNotifications : this._notifications,
      notif, timeout);

    return notif;
  }

  private appendNotification(notifications: Notification[], notification: Notification, timeout?: number) {
    notifications.push(notification);
    if (timeout) {
      setTimeout(() => {
        this.closeNotification(notification);
      }, timeout);
    }
  }

  private convertToArray<T>(input: T | T[]): T[] {

    let inputAsArray: T[];

    if (input) {
      inputAsArray = Array.isArray(input) ? input : [input];
    }

    return inputAsArray;
  }
}
