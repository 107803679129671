import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { DatepickerModule, TimepickerModule } from "@effectory/branding-angular/date-time";
import { SelectModule } from "@effectory/branding-angular/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { VideoModule } from "@effectory/branding-angular/video";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MtxTooltipModule } from "@ng-matero/extensions/tooltip";
import { SvgModule, TooltipDialogModule, DesignPreviewModule, ControlsModule, LoaderModule, NotificationModule } from "@effectory/branding-angular";

import { AccessibilityComponent } from "./accessibility/accessibility.component";
import { AccordionsComponent } from "./accordions/accordions.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BreakpointsComponent } from "./breakpoints/breakpoints.component";
import { ButtonsComponent } from "./buttons/buttons.component";
import { CardsComponent } from "./cards/cards.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ColorsComponent } from "./colors/colors.component";
import { DateAdapter } from "@angular/material/core";
import { DesignPreviewComponent } from "./design-preview/design-preview.component";
import { DialogsComponent } from "./dialogs/dialogs.component";
import { DropdownsComponent } from "./dropdowns/dropdowns.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { FloatBarComponent } from "./float-bar/float-bar.component";
import { FooterComponent } from "./footer/footer.component";
import { IconsComponent } from "./icons/icons.component";
import { IllustrationsComponent } from "./illustrations/illustrations.component";
import { InputsComponent } from "./inputs/inputs.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { LoadersComponent } from "./loaders/loaders.component";
import { MobileSidebarService } from "./mobile-sidebar/mobile-sidebar.service";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NavigationComponent } from "./navigation/navigation.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { RadioButtonsComponent } from "./radio-buttons/radio-buttons.component";
import { SelectsComponent } from "./selects/selects.component";
import { ShadowsComponent } from "./shadows/shadows.component";
import { SteppersComponent } from "./steppers/steppers.component";
import { TableModule } from "./../../projects/branding-angular/src/lib/table/table.module";
import { TablesComponent } from "./tables/tables.component";
import { TabsComponent } from "./tabs/tabs.component";
import { TagsComponent } from "./tags/tags.component";
import { TogglesComponent } from "./toggles/toggles.component";
import { TooltipsComponent } from "./tooltips/tooltips.component";
import { TypographyComponent } from "./typography/typography.component";
import { VideoComponent } from "./video/video.component";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "/assets/i18n/");
}

@NgModule({
  declarations: [
    AccessibilityComponent,
    AccordionsComponent,
    AppComponent,
    BreakpointsComponent,
    ButtonsComponent,
    CardsComponent,
    CheckboxComponent,
    ColorsComponent,
    DesignPreviewComponent,
    DialogsComponent,
    DropdownsComponent,
    ErrorPageComponent,
    FloatBarComponent,
    FooterComponent,
    IconsComponent,
    IllustrationsComponent,
    InputsComponent,
    LandingPageComponent,
    LoadersComponent,
    NavigationComponent,
    NotificationsComponent,
    RadioButtonsComponent,
    SelectsComponent,
    ShadowsComponent,
    TablesComponent,
    SteppersComponent,
    TabsComponent,
    TagsComponent,
    TogglesComponent,
    TooltipsComponent,
    TypographyComponent,
    VideoComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    MatExpansionModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    MatDatepickerModule,
    FormsModule,
    ControlsModule,
    SvgModule,
    LoaderModule,
    TableModule,
    NotificationModule,
    DatepickerModule,
    SelectModule,
    TimepickerModule,
    HttpClientModule,
    DesignPreviewModule,
    TooltipDialogModule,
    VideoModule,
    ReactiveFormsModule,
    ScrollingModule,
    MtxTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    MobileSidebarService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
