import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-toggles",
  templateUrl: "./toggles.component.html",
  styleUrls: ["./toggles.component.scss"]
})
export class TogglesComponent {
  @ViewChild("yourTargetElement") public targetButton: ElementRef;
  public searchInput: string;

  // Toggle
  public toggleOn = true;

  // Search select
  public values = [0];
  // Guided tooltip
  public showTooltipDialog = false;

  public alert(message: any): void {
    alert(message);
  }

  public sethowTooltipDialog(): void {
    this.showTooltipDialog = true;
  }
}
