
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SvgModule } from "@effectory/branding-angular/shared";
import { NotificationComponent } from "../components/notification/notification.component";
import { BannerNotificationComponent } from "../components/banner-notification/banner-notification.component";
import { InlineNotificationComponent } from "../components/inline-notification/inline-notification.component";
import { ErrorComponent } from "../components/error/error.component";

@NgModule({
  declarations: [
    NotificationComponent,
    InlineNotificationComponent,
    ErrorComponent,
    BannerNotificationComponent
  ],
  imports: [
    CommonModule,
    SvgModule,
    TranslateModule
  ],
  exports: [
    NotificationComponent,
    InlineNotificationComponent,
    ErrorComponent,
    BannerNotificationComponent
  ]
})
export class NotificationModule { }
