import { Injectable } from "@angular/core";

@Injectable()
export class CultureService {
  public getCulture(
    availableLanguages: string[],
    fallbackLanguage: string,
    userLanguage: string
  ) {
    let defaultLanguage: string = null;

    if (availableLanguages.some(lang => userLanguage === lang)) {
      defaultLanguage = userLanguage;
    } else {
      const languagePrefix = userLanguage.substring(
        0,
        userLanguage.indexOf("-") - 1
      );
      const matchedLanguages = availableLanguages.filter(lang =>
        lang.toLocaleLowerCase().startsWith(languagePrefix)
      );

      if (matchedLanguages.length > 0) {
        defaultLanguage = matchedLanguages[0];
      } else {
        defaultLanguage = fallbackLanguage;
      }
    }
    return defaultLanguage;
  }
}
