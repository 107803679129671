<div tabindex="-1" [ngStyle]="{'--arrow-color': arrowColor}">
  <div tabindex="0" role="dialog" #tourDialog class="card tour-container {{ dialogAlignmentToTarget }} {{ dialogPositionToTarget }}">
    <img class="illustration" [src]="svgUrl" *ngIf="svgUrl" />
    <p class="text-l5">{{ dialogTitle }}</p>
    <p class="text-w400" [ngClass]="{ 'text-small' : !svgUrl, 'text-subdued' : svgUrl }" [innerHtml]="dialogSubtitle"></p>
    <div class="tour-footer">
      <div class="tour-progression-container" *ngIf="dialogProgressionStageCurrent && dialogProgressionStageTotal">
        <div class="tour-progression-indicator" [class.tour-progression-indicator-completed]="i <= dialogProgressionStageCurrent - 1"
          *ngFor="let item of progressionIndicatorIterator; let i = index"></div>
      </div>
      <div *ngIf="withoutButtons">
        <ng-content></ng-content>
      </div>
      <ng-container *ngIf="!withoutButtons">
        <button class="link" *ngIf="dialogLinkButtonText" (click)="closeDialog(true)">{{ dialogLinkButtonText }}</button>
        <button #dialogCloseButton class="primary" (click)="closeDialog()" [attr.aria-label]="dialogButtonText">{{ dialogButtonText }}</button>
      </ng-container>
    </div>
  </div>
</div>
