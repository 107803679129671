import { Injectable } from "@angular/core";

@Injectable()
export class MobileSidebarService {

  public sidebarExpanded: boolean;

  constructor() {
    this.sidebarExpanded = false;
  }

}
