import { Component } from "@angular/core";

@Component({
  selector: "app-typography",
  templateUrl: "./typography.component.html",
  styleUrls: ["./typography.component.scss"]
})
export class TypographyComponent {

  public headers = ["Example", "Class name"];

  public sizes = [
    {
      example: "<span class='text-l1'>Level 1</span>",
      className: ".text-l1"
    },
    {
      example: "<span class='text-l2'>Level 2</span>",
      className: ".text-l2"
    },
    {
      example: "<span class='text-l3'>Level 3</span>",
      className: ".text-l3"
    },
    {
      example: "<span class='text-l4'>Level 4</span>",
      className: ".text-l4"
    },
    {
      example: "<span class='text-l5'>Level 5</span>",
      className: ".text-l5"
    },
    {
      example: "<span class='text-l6'>Level 6</span>",
      className: ".text-l6"
    },
    {
      example: "<span>Body text</span>",
      className: ""
    },
    {
      example: "<span class='text-small'>Small</span>",
      className: ".text-small"
    },
    {
      example: "<span class='text-large'>Large</span>",
      className: ".text-large"
    },
    {
      example: "<a>Link</a>",
      className: ".text-l1"
    }
  ];

  public modifiers = [
    {
      example: "<span class='text-w600'>Semi-bold</span>",
      className: ".text-w600"
    },
    {
      example: "<span class='text-w500'>Medium</span>",
      className: ".text-w500"
    },
    {
      example: "<span class='text-w400'>Light</span>",
      className: ".text-w400"
    },
    {
      example: "<span class='text-subdued'>Subdued (neutral-64)</span>",
      className: ".text-subdued"
    },
    {
      example: "<span><mark>Highlighted</mark></span>",
      className: ""
    }
  ];
}
