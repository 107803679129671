import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PaginationComponent } from "../components/pagination/pagination.component";
import { SvgModule } from "@effectory/branding-angular/shared";
import { OverflowShadowSideComponent } from "./../components/overflow-shadow-side/overflow-shadow-side.component";

@NgModule({
  declarations: [
    PaginationComponent,
    OverflowShadowSideComponent
  ],
  imports: [
    CommonModule,
    SvgModule
  ],
  exports: [
    PaginationComponent,
    OverflowShadowSideComponent
  ]
})
export class TableModule { }
