<div class="timepicker-container" [class.disabled]="disabled">
  <input
    #inputHours
    class="hour-input"
    [attr.disabled]="disabled ? '' : null"
    data-cy="hour-input"
    [formControl]="hoursForm"
    maxlength="2"
    type="text"
    placeholder="HH"
    (click)="setHoursSelectionRange()"
    (blur)="onBlurHour(hoursForm.value)"
    (keydown.ArrowUp)="changeHour(true); $event.preventDefault()"
    (keydown.ArrowDown)="changeHour(false); $event.preventDefault()"
  >
  <span class="column">:</span>
  <input
    #inputMinutes
    class="minute-input"
    [attr.disabled]="disabled ? '' : null"
    data-cy="minute-input"
    [formControl]="minutesForm"
    maxlength="2"
    type="text"
    placeholder="MM"
    (click)="setMinutesSelectionRange()"
    (blur)="onBlurMinutes(minutesForm.value)"
    (keydown.ArrowUp)="increaseMinutesOnKeyUp(); $event.preventDefault()"
    (keydown.ArrowDown)="decreaseMinutesOnKeyDown(); $event.preventDefault()"
  >
  <div class="spanner" (click)="setHoursSelectionRange()"></div>
  <div *ngIf="showIncreaseByPeriodButtons" class="increase-minutes-container">
    <button [disabled]="disabled" type="button" (click)="increaseMinutesByPeriod()">
      <eff-svg-chevron-up class="icon"></eff-svg-chevron-up>
    </button>
    <button [disabled]="disabled" type="button" (click)="decreaseMinutesByPeriod()">
      <eff-svg-chevron-down class="icon"></eff-svg-chevron-down>
    </button>
  </div>
</div>
