import { Component } from "@angular/core";

@Component({
  selector: "eff-svg-arrow-sub",
  templateUrl: "../../../../../node_modules/@effectory/branding-styles/dist/svg/arrow-sub.svg"
})
export class ArrowSubSvgComponent {

  constructor() { }
}
