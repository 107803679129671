<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Footer</h2>
  </div>
  <div class="card-content-container">
    <p>You can add the "<code>no-animation</code>" class to remove the animation</p>
    <details>
      <summary>Code</summary>
      <pre>
      <code ngNonBindable>
&lt;div class="eff-footer">
  &lt;div class="mobile-buttons-container">
    &lt;button class="secondary">First button&lt;/button>
    &lt;button class="primary">Second button&lt;/button>
  &lt;/div>
  &lt;button class="secondary desktop-button">First button&lt;/button>
  &lt;button class="primary desktop-button">Second button&lt;/button>
&lt;/div>
      </code>
    </pre>
    </details>
  </div>
</div>

<div class="eff-footer">
  <div class="mobile-buttons-container">
    <button class="secondary">First button</button>
    <button class="primary">Second button</button>
  </div>
  <button class="secondary desktop-button">First button</button>
  <button class="primary desktop-button">Second button</button>
</div>

<br><br>
