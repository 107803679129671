<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Toggles</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-slide-toggle disableRipple [checked]="toggleOn" (change)="toggleOn = !toggleOn">
  Toggle is &#123;&#123;toggleOn ? "on" : "off"&#125;&#125;
&lt;/mat-slide-toggle>
</code>
</pre>
    </details>
    <mat-slide-toggle disableRipple [checked]="toggleOn" (change)="toggleOn = !toggleOn">
      Toggle is {{toggleOn ? "on" : "off"}}
    </mat-slide-toggle><br>
    <mat-slide-toggle disableRipple [checked]="true" disabled>
      Toggle is on
    </mat-slide-toggle><br>
    <mat-slide-toggle disableRipple [checked]="false" disabled>
      Toggle is off
    </mat-slide-toggle>
  </div>
</div>

<div class="card">
  <div class="card-title-container">
    <h2 class="card-title text-l5">Button toggles</h2>
  </div>
  <div class="card-content-container">
    <details>
      <summary>Code</summary>
      <pre>
<code>
&lt;mat-button-toggle-group&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Red&#39;&quot;&gt;Red&lt;/mat-button-toggle&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Green&#39;&quot;&gt;Green&lt;/mat-button-toggle&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Blue&#39;&quot; checked&gt;Blue&lt;/mat-button-toggle&gt;
&lt;/mat-button-toggle-group&gt;

&lt;mat-button-toggle-group multiple&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Bold&#39;&quot; checked&gt;Bold&lt;/mat-button-toggle&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Italic&#39;&quot;&gt;Italic&lt;/mat-button-toggle&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Underline&#39;&quot; checked&gt;Underline&lt;/mat-button-toggle&gt;
&lt;/mat-button-toggle-group&gt;

&lt;mat-button-toggle-group&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Desktop&#39;&quot; checked&gt;
    &lt;eff-svg-desktop class="icon"&gt;&lt;/eff-svg-desktop&gt;
    &lt;span&gt;Desktop&lt;/span&gt;
  &lt;/mat-button-toggle&gt;
  &lt;mat-button-toggle [value]=&quot;&#39;Mobile&#39;&quot;&gt;
    &lt;eff-svg-mobile class="icon"&gt;&lt;/eff-svg-mobile&gt;
    &lt;span&gt;Mobile&lt;/span&gt;
  &lt;/mat-button-toggle&gt;
&lt;/mat-button-toggle-group&gt;
</code>
</pre>
    </details>
    <p>
      <mat-button-toggle-group>
        <mat-button-toggle [value]="'Red'">Red</mat-button-toggle>
        <mat-button-toggle [value]="'Green'">Green</mat-button-toggle>
        <mat-button-toggle [value]="'Blue'" checked>Blue</mat-button-toggle>
      </mat-button-toggle-group>
    </p>
    <p>
      <mat-button-toggle-group multiple>
        <mat-button-toggle [value]="'Bold'" checked>Bold</mat-button-toggle>
        <mat-button-toggle [value]="'Italic'">Italic</mat-button-toggle>
        <mat-button-toggle [value]="'Underline'" checked>Underline</mat-button-toggle>
      </mat-button-toggle-group>
    </p>
    <p>
      <mat-button-toggle-group>
        <mat-button-toggle [value]="'Desktop'" checked>
          <eff-svg-desktop class="icon"></eff-svg-desktop>
          <span>Desktop</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="'Mobile'">
          <eff-svg-mobile class="icon"></eff-svg-mobile>
          <span>Mobile</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </p>
  </div>
</div>
